@import "../../../theme/font";
@import "../../../theme/text";
@import "../../../theme/colorVars";

// This file is loaded into every required SASS module.
// Do not include anything in this file that will be actually rendered in CSS, because it will be added to every imported SASS file.
// Add page-level styling to /src/index.scss

// Theme Colors
$primary-color: $veritone-blue;
$secondary-color: $veritone-blue-faded;

// Text
$text-primary: $black;

// Fonts
$font-stack-primary: roboto, sans-serif; // Set globally

// Z-index levels
$dialog-z: 200;
$drawer-clickoff-z: 149;
$drawer-z: 150;
$nav-clickoff-z: 199;
$nav-z: 200;
$snack-z: 9999999999999;

// Shared layout dimensions
$header-height: 55px;
$drawer-width: 321px;
$drawer-width-minimal: 80px;
$min-content-box-width: 500px;
$default-combo-box-search-width: 300px;
$people-form-height: 280px;
$default-left-cell-width: 220px;

// Box Shadows
$shadow-level-0: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
$shadow-level-2: 0 3px 4px 0 rgba(0, 0, 0, 0.24);
$shadow-level-3: 0 3px 8px 0 rgba(0, 0, 0, 0.03);
$shadow-level-3-5: 0 3px 8px 0 rgba(0, 0, 0, 0.3);
$shadow-level-4: 0 4px 24px 0 rgba(0, 0, 0, 0.1);

// Transitions
$drawer-transition: 300ms;

// App vars
$person-tab-width: 147;
$snackHeight: 96;
$snackMargin: 8;

// Export to JS
:export {
  /* stylelint-disable */

  primaryColor: $primary-color;
  secondaryColor: $secondary-color;
  inputBorder: $input-border;
  grey4: $grey-4;
  blueDisabled: $blue-disabled;
  personTabWidth: $person-tab-width;
  snackHeight: $snackHeight;
  snackMargin: $snackMargin;
  headerHeight: $header-height;
  popperBackground: $popper-background;
  fontColor: $font-color;

  /* stylelint-enable */
}

.organization-row {
  display: block;
  border-bottom: solid 1px $table-border;

  .organization-row__row {
    position: relative;
    display: flex;
    justify-content: space-between;

    .organization-row__cell {
      display: flex;
      justify-content: left;

      .organization-row__cell-name,
      .organization-row__cell-state,
      .organization-row__cell-subdomain,
      .organization-row__cell-id,
      .organization-row__cell-created,
      .organization-row__cell-production {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 23px 16px 23px 0;
        line-height: 24px;

        @include size-1;
      }
      .organization-row__cell-actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;

        .organization-row__table-cell-actions-upload-users {
          color: $primary-color;
          cursor: pointer;
          user-select: none;
          margin-right: 12px;
        }
        .organization-row__table-cell-actions-goto-org {
          color: $primary-color;
          cursor: pointer;
          user-select: none;
          margin-right: 12px;
        }
        .organization-row__table-cell-actions-edit {
          color: $primary-color;
          cursor: pointer;
          user-select: none;
          margin-right: 12px;
        }
        .organization-row__table-cell-actions-delete {
          color: $number-red;
          cursor: pointer;
          user-select: none;
          margin-right: 20px;
        }
      }
    }
  }
}

.dark-mode {
  .organizations__search-container {
    .organizations__actions {
      .organizations__add-org {
        .organizations__add-org-text {
          color: $black;
        }
        .material-icons {
          color: $black;
        }
      }
    }
  }
  .organization-row__row {
    .organization-row__cell {
      .organization-row__cell-actions {
        .organization-row__table-cell-actions-delete {
          background-color: $black;
          height: 15px;
          width: 15px;
          border-radius: 50%;
          display: inline-flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
