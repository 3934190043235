@import "../../theme/font";
@import "../../theme/text";
@import "../../theme/colorVars";

// This file is loaded into every required SASS module.
// Do not include anything in this file that will be actually rendered in CSS, because it will be added to every imported SASS file.
// Add page-level styling to /src/index.scss

// Theme Colors
$primary-color: $veritone-blue;
$secondary-color: $veritone-blue-faded;

// Text
$text-primary: $black;

// Fonts
$font-stack-primary: roboto, sans-serif; // Set globally

// Z-index levels
$dialog-z: 200;
$drawer-clickoff-z: 149;
$drawer-z: 150;
$nav-clickoff-z: 199;
$nav-z: 200;
$snack-z: 9999999999999;

// Shared layout dimensions
$header-height: 55px;
$drawer-width: 321px;
$drawer-width-minimal: 80px;
$min-content-box-width: 500px;
$default-combo-box-search-width: 300px;
$people-form-height: 280px;
$default-left-cell-width: 220px;

// Box Shadows
$shadow-level-0: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
$shadow-level-2: 0 3px 4px 0 rgba(0, 0, 0, 0.24);
$shadow-level-3: 0 3px 8px 0 rgba(0, 0, 0, 0.03);
$shadow-level-3-5: 0 3px 8px 0 rgba(0, 0, 0, 0.3);
$shadow-level-4: 0 4px 24px 0 rgba(0, 0, 0, 0.1);

// Transitions
$drawer-transition: 300ms;

// App vars
$person-tab-width: 147;
$snackHeight: 96;
$snackMargin: 8;

// Export to JS
:export {
  /* stylelint-disable */

  primaryColor: $primary-color;
  secondaryColor: $secondary-color;
  inputBorder: $input-border;
  grey4: $grey-4;
  blueDisabled: $blue-disabled;
  personTabWidth: $person-tab-width;
  snackHeight: $snackHeight;
  snackMargin: $snackMargin;
  headerHeight: $header-height;
  popperBackground: $popper-background;
  fontColor: $font-color;

  /* stylelint-enable */
}

.custom-questions {
  padding: 30px;
  height: calc(100% - #{$header-height});
  overflow-y: auto;

  .custom-questions__editor {
    display: block;
  }

  .custom-questions__cc-list {
    position: relative;
    margin-top: 30px;
    width: 530px;
    margin-right: 30px;
    display: inline-block;
    float: left;
  }
  .custom-questions__json-warning {
    margin-top: 10px;
    color: $number-red;
    text-align: center;

    @include size-2;
  }
  .custom-questions__cc-list-section-title-input {
    .MuiInputBase-root {
      background: $white;
    }
    .MuiInput-underline::after,
    .MuiInput-underline::before {
      border: none;
    }
    .MuiInputBase-input {
      color: $black;

      @include size-3;
    }
  }
  .custom-questions__cc-list-live-switch {
    margin-top: 40px;
    width: 79px;

    .switch__thumb {
      transform: translateX(330%);
    }
    .switch__text-off {
      margin-left: 5px;
      line-height: 20px;
    }
    .switch__text-on {
      margin-right: 18px;
      line-height: 20px;
    }

    &.is-on {
      .switch__thumb {
        transform: translateX(0%);
      }
    }
  }
  .custom-questions__cc-list-section {
    padding: 10px 10px 10px 40px;
    width: 530px;
    position: absolute;
    border-radius: 4px;
    border: solid 1px lightgrey;
    // cursor: move;
    transition: top 300ms;
    float: left;

    .material-icons {
      width: 40px;
      position: absolute;
      left: 0;
      top: 10px;
      text-align: center;
      color: $primary-color;
      user-select: none;
      opacity: 0.6;
      font-size: 35px;
    }
    .custom-questions__cc-list-section-title {
      padding: 10px 8px 10px;
    }
    .custom-questions__cc-list-section-question {
      padding: 10px 10px 10px 30px;
      border: solid 1px lightgrey;
      height: 70px;
      position: absolute;
      width: 450px;
      display: flex;
      align-items: center;
      transition: top 300ms;
      z-index: 10;
      cursor: move;

      &.active {
        border: solid 1px $primary-color;
      }
      &:hover {
        .custom-questions__cc-list-section-question-delete {
          display: block;
          cursor: pointer;
        }
      }
      &.disabled {
        .custom-questions__cc-list-section-question-type,
        .custom-questions__cc-list-section-question-title {
          text-decoration: line-through;
        }
      }
      .custom-questions__cc-list-section-question-container {
        width: 100%;
      }
      .custom-questions__cc-list-section-question-title {
        width: calc(100% - 20px);
        pointer-events: none;
        position: relative;
        z-index: 0;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .custom-questions__cc-list-section-question-type {
        color: $grey-ae;
        width: 100%;
        pointer-events: none;
        position: relative;
        z-index: 0;

        @include size-2;
      }
      .material-icons {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        font-size: 25px;
        width: 30px;
        z-index: 0;
      }
      .custom-questions__cc-list-section-question-delete {
        right: 5px;
        left: initial;
        display: none;
      }

      @include size-3;
    }
  }
  .custom-questions__cc-add-question {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 7px;
    cursor: pointer;

    &:active {
      .custom-questions__cc-add-question-text,
      .material-icons {
        opacity: 1;
      }
    }
    .material-icons {
      display: inline-flex;
      position: initial;
      font-size: 22px;
      width: 22px;
      line-height: 23px;
    }
    .custom-questions__cc-add-question-text {
      display: inline-flex;
      color: $primary-color;
      opacity: 0.6;
    }
  }

  .custom-questions__add-cc {
    width: 300px;
    margin-top: 30px;

    .MuiSelect-root {
      padding: 11.5px;
    }
  }
  .custom-questions__org-button-container {
    display: inline-flex;
    align-items: center;
    position: relative;
  }
  .custom-questions__org-id {
    width: 200px;
    margin: 0 30px 0 0;

    .MuiInputBase-root {
      height: 55px;
    }
  }
  .custom-questions__json-block {
    display: flex;
    margin-top: 30px;
  }
  .custom-questions__json {
    width: 100%;
  }
  .custom-questions__get {
    display: none;
  }
  .custom-questions-json-container {
    margin: 24px 0 0 30px;

    .json-formatter-row .json-formatter-string {
      white-space: initial;
    }
  }
  .custom-questions_json-error {
    color: $number-red;
    display: none;

    &.error {
      display: block;
    }
  }
  .custom-questions__unsaved-changes {
    display: none;
    position: absolute;
    bottom: -30px;
    right: 0;
    color: $primary-color;
    opacity: 0.6;
    &.error {
      display: block;
    }
  }
  .custom-questions__cc-edit-string-container {
    position: relative;

    .material-icons {
      position: absolute;
      top: -3px;
      right: 0;
      color: $primary-color;
      font-size: 22px;
      user-select: none;
    }
  }
  .custom-questions__get-json,
  .custom-questions__set {
    margin-top: 25px;
    margin-left: 25px;
  }
  .custom-questions__cc-path {
    width: 300px;
    margin: 30px 30px 0 0;
  }
  .custom-questions__cc-render-container {
    display: flex;
  }
  .custom-questions__cc-render-title {
    margin-top: 30px;
    color: $primary-color;

    @include size-2-bold;
  }
  .custom-questions__cc-render {
    width: 500px;
    margin-top: 15px;
    box-shadow: $shadow-level-2;
    max-height: 1000px;
    overflow-y: auto;
  }
  .custom-questions__cc-render-title-container {
    display: inline-block;
    float: left;
  }
  .custom-questions__cc-edit {
    float: left;
    padding-bottom: 30px;

    > :first-child {
      margin-top: 30px;
    }

    display: inline-block;
    margin-right: 30px;
    width: 350px;
  }
  .custom-questions__cc-edit-bool {
    margin-top: 10px;
  }
  .custom-questions__cc-edit-bool-title {
    margin-top: 20px;
    color: $primary-color;
    text-transform: uppercase;

    @include size-2-bold;
  }
  .custom-questions__cc-edit-array-container,
  .custom-questions__cc-edit-array-option,
  .custom-questions__cc-edit-string,
  .custom-questions__cc-edit-number {
    margin-top: 20px;
  }
  .custom-questions__cc-edit-array-controls {
    width: 112px;
    text-align: right;
  }
  .custom-questions__cc-edit-array {
    max-height: 500px;
    overflow-y: auto;
    padding: 0 20px 20px 20px;
    border-left: solid 3px $primary-color;
  }
  .custom-questions__cc-edit-array-title {
    margin-top: 20px;
    color: $primary-color;
    display: flex;
    text-transform: uppercase;
    justify-content: space-between;
    align-items: flex-start;
    height: 52px;

    @include size-2-bold;
  }
  .custom-questions__cc-edit-array-option {
    &:first-child {
      margin-top: 0;
    }
  }
  .custom-questions__cc-edit-array-option-container {
    position: relative;
    padding: 0 30px 0 0;

    .material-icons {
      position: absolute;
      right: 0;
      top: 50%;
      height: 20px;
      width: 20px;
      font-size: 20px;
      border-radius: 50%;
      color: white;
      background-color: $primary-color;
      cursor: pointer;
      opacity: 0.6;

      &:active {
        opacity: 1;
      }
    }
  }
  .custom-questions__cc-edit-array-import,
  .custom-questions__cc-edit-array-add {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    opacity: 0.6;
    text-transform: capitalize;
    cursor: pointer;

    @include size-3;

    &:active {
      opacity: 1;

      .material-icons {
        opacity: 1;
      }
    }
    .material-icons {
      display: inline-flex;
      position: initial;
      font-size: 22px;
      width: 22px;
      line-height: 23px;
    }
  }
}

.custom-questions-result-path-tooltip {
  @include size-2;
}
