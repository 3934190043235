@import "../../../theme/font";
@import "../../../theme/text";
@import "../../../theme/colorVars";

// This file is loaded into every required SASS module.
// Do not include anything in this file that will be actually rendered in CSS, because it will be added to every imported SASS file.
// Add page-level styling to /src/index.scss

// Theme Colors
$primary-color: $veritone-blue;
$secondary-color: $veritone-blue-faded;

// Text
$text-primary: $black;

// Fonts
$font-stack-primary: roboto, sans-serif; // Set globally

// Z-index levels
$dialog-z: 200;
$drawer-clickoff-z: 149;
$drawer-z: 150;
$nav-clickoff-z: 199;
$nav-z: 200;
$snack-z: 9999999999999;

// Shared layout dimensions
$header-height: 55px;
$drawer-width: 321px;
$drawer-width-minimal: 80px;
$min-content-box-width: 500px;
$default-combo-box-search-width: 300px;
$people-form-height: 280px;
$default-left-cell-width: 220px;

// Box Shadows
$shadow-level-0: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
$shadow-level-2: 0 3px 4px 0 rgba(0, 0, 0, 0.24);
$shadow-level-3: 0 3px 8px 0 rgba(0, 0, 0, 0.03);
$shadow-level-3-5: 0 3px 8px 0 rgba(0, 0, 0, 0.3);
$shadow-level-4: 0 4px 24px 0 rgba(0, 0, 0, 0.1);

// Transitions
$drawer-transition: 300ms;

// App vars
$person-tab-width: 147;
$snackHeight: 96;
$snackMargin: 8;

// Export to JS
:export {
  /* stylelint-disable */

  primaryColor: $primary-color;
  secondaryColor: $secondary-color;
  inputBorder: $input-border;
  grey4: $grey-4;
  blueDisabled: $blue-disabled;
  personTabWidth: $person-tab-width;
  snackHeight: $snackHeight;
  snackMargin: $snackMargin;
  headerHeight: $header-height;
  popperBackground: $popper-background;
  fontColor: $font-color;

  /* stylelint-enable */
}

$tooltip-background-color: #eee;
$tooltip-foreground-color: #333;
$tooltip-arrow-size: 8px;
$tooltip-vertical-shift: 12px;
$tooltip-multiline-width: 240px;
$tooltip-tooltip-padding: 8px 12px;
$tooltip-roundness: 3px;
$tooltip-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.65);
$tooltip-load-styles: true !default; // Extra styles needed?

.pii-text-field {
  padding: 9.9px 15px 40px;
  border: solid 1px grey;
  border-radius: 3px;
  height: 140px;
  overflow-wrap: break-word;
  overflow-y: auto;

  &.error {
    border: solid 1px red;
    color: red;

    &:focus-within {
      border: solid 1px red;
    }
  }
  &.disabled {
    border: solid 1px grey;
    color: grey;

    &:focus-within {
      border: solid 1px grey;
    }
  }
  &__field-container {
    height: 100%;
    overflow-y: auto;
    position: relative;
  }
  &:focus-within {
    border: solid 1px $primary-color;
  }

  &__colors {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    user-select: none;
  }
  &__editable {
    position: relative;
    color: transparent; /* Make text invisible */
    caret-color: black; /* But keep caret visible */
  }

  .pii-text-field__pii {
    display: inline;
    background-color: #faff14;
  }

  [placeholder]:empty::before {
    content: attr(placeholder);
    color: #555;

    @include size-2;

    font-style: italic;
  }

  [placeholder]:empty:focus::before {
    content: "";
  }

  *[tooltip] {
    &::before,
    &::after {
      position: fixed;
      top: var(--tool-tip-location-top);
      left: var(--tool-tip-location-left);
      transform: translate(-45px, -40px);
      z-index: 1000;
      pointer-events: none;
      user-select: none;
      opacity: 0;
      transition: opacity 0.35s ease 0.25s;
    }
    // Tooltip Body
    &::before {
      content: attr(tooltip);
      background: $tooltip-background-color;
      height: 32px;
      color: $tooltip-foreground-color;
      padding: $tooltip-tooltip-padding;
      white-space: nowrap;
      bottom: 100%;

      @if $tooltip-roundness != 0 {
        border-radius: $tooltip-roundness;
      }

      @if $tooltip-shadow != 0 {
        box-shadow: $tooltip-shadow;
      }
    }

    // Tooltip Arrow
    &::after {
      content: "";
      background: transparent;
      border: $tooltip-arrow-size solid transparent;
      border-top-color: $tooltip-background-color;
      transform: translate(6px, -9px);
    }

    // Active state
    .show-tool-tip,
    &:hover,
    &:focus,
    &:active {
      &::before,
      &::after {
        opacity: 1;
      }
    }

    @if $tooltip-load-styles == true {
      &.tooltip-multiline {
        &::before {
          width: 100vw;
          max-width: $tooltip-multiline-width;
          white-space: normal;
        }
      }
      &[class*="tooltip-bottom"] {
        &::before,
        &::after {
          transform: translate(-50%, $tooltip-vertical-shift);
        }
        &::before {
          bottom: auto;
          top: 100%;
        }
        &::after {
          bottom: 0;
          border: $tooltip-arrow-size solid transparent;
          border-bottom-color: $tooltip-background-color;
        }
      }
      &.tooltip-bottom-left::before {
        transform: translate(-($tooltip-arrow-size * 3), $tooltip-vertical-shift);
      }
      &.tooltip-bottom-right::before {
        left: auto;
        right: 50%;
        transform: translate($tooltip-arrow-size * 3, $tooltip-vertical-shift);
      }
      &.tooltip-top-left::before {
        transform: translate(-($tooltip-arrow-size * 3), -$tooltip-vertical-shift);
      }
      &.tooltip-top-right::before {
        left: auto;
        right: 50%;
        transform: translate($tooltip-arrow-size * 3, -$tooltip-vertical-shift);
      }
    }
  }
}

.dark-mode {
  .pii-text-field {
    .pii-text-field__pii {
      background-color: #6c1b1b;
    }
    &__editable {
      caret-color: white;
    }
  }
}

.pii-text-field__tooltip {
  &.MuiTooltip-tooltip {
    background-color: $tooltip-background-color;
    height: 100%;
    color: $tooltip-foreground-color;
    white-space: nowrap;
    bottom: 100%;
    font-size: 16px;

    @if $tooltip-roundness != 0 {
      border-radius: $tooltip-roundness;
    }

    @if $tooltip-shadow != 0 {
      box-shadow: $tooltip-shadow;
    }
  }
  &-arrow {
    &.MuiTooltip-arrow {
      color: $tooltip-background-color;
      &::before {
        border: 1px solid transparent;
        background-color: $tooltip-background-color;
      }
    }
  }
}
