@import "../../../theme/font";
@import "../../../theme/text";
@import "../../../theme/colorVars";

// This file is loaded into every required SASS module.
// Do not include anything in this file that will be actually rendered in CSS, because it will be added to every imported SASS file.
// Add page-level styling to /src/index.scss

// Theme Colors
$primary-color: $veritone-blue;
$secondary-color: $veritone-blue-faded;

// Text
$text-primary: $black;

// Fonts
$font-stack-primary: roboto, sans-serif; // Set globally

// Z-index levels
$dialog-z: 200;
$drawer-clickoff-z: 149;
$drawer-z: 150;
$nav-clickoff-z: 199;
$nav-z: 200;
$snack-z: 9999999999999;

// Shared layout dimensions
$header-height: 55px;
$drawer-width: 321px;
$drawer-width-minimal: 80px;
$min-content-box-width: 500px;
$default-combo-box-search-width: 300px;
$people-form-height: 280px;
$default-left-cell-width: 220px;

// Box Shadows
$shadow-level-0: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
$shadow-level-2: 0 3px 4px 0 rgba(0, 0, 0, 0.24);
$shadow-level-3: 0 3px 8px 0 rgba(0, 0, 0, 0.03);
$shadow-level-3-5: 0 3px 8px 0 rgba(0, 0, 0, 0.3);
$shadow-level-4: 0 4px 24px 0 rgba(0, 0, 0, 0.1);

// Transitions
$drawer-transition: 300ms;

// App vars
$person-tab-width: 147;
$snackHeight: 96;
$snackMargin: 8;

// Export to JS
:export {
  /* stylelint-disable */

  primaryColor: $primary-color;
  secondaryColor: $secondary-color;
  inputBorder: $input-border;
  grey4: $grey-4;
  blueDisabled: $blue-disabled;
  personTabWidth: $person-tab-width;
  snackHeight: $snackHeight;
  snackMargin: $snackMargin;
  headerHeight: $header-height;
  popperBackground: $popper-background;
  fontColor: $font-color;

  /* stylelint-enable */
}

.global-config {
  background-color: $grey-b;
  height: calc(100vh - 55px);
  overflow-y: scroll;

  .global-config__dialog {
    display: flex;
    flex-direction: column;
    margin: 60px 80px;
    width: auto;
    min-width: 760px;
    max-width: 1800px;
    background: $white;
    border-radius: 5px;

    .global-config__title-area {
      height: 80px;
      background: $veritone-blue-faded;

      .global-config__title-text {
        padding-left: 40px;
        padding-top: 24px;
        padding-bottom: 24px;
        color: $primary-color;

        @include size-7-bold;
      }
    }

    .global-config__doj-submission-settings {
      display: flex;
      flex-direction: column;
      height: auto;
      width: auto;
      margin: 45px 40px;
      position: relative;

      .global-config__doj-submission-settings-title {
        color: $primary-color;

        @include size-5-bold;
      }

      .global-config__doj-submission-settings-submit-button {
        position: absolute;
        right: 0;
        top: 0;
        width: 130px;
        height: 36px;
        padding-top: 9px;
        padding-bottom: 9px;
        line-height: 20px;
        font-size: 14px;
      }

      .global-config__doj-submission-settings-form {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 34px;
        background: $white;

        .global-config__doj-submission-settings-left-half {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          width: 50%;
          min-width: 471px;

          .global-config__date-picker-full-width {
            width: 100%;
          }

          .global-config__doj-submission-settings-date-picker {
            display: flex;
            flex-direction: column;
            width: 50%;
            min-width: 271px;

            .global-config__doj-submission-settings-date-picker-label {
              color: $primary-color;

              @include size-2-bold;
            }
          }

          .global-config__doj-submission-settings-frequency-selection {
            display: flex;
            flex-direction: column;
            width: 50%;
            min-width: 200px;

            .global-config__doj-submission-settings-frequency-selection-label {
              color: $primary-color;

              @include size-2-bold;
            }
          }

          .MuiInputBase-input {
            font-size: 14px;
          }
        }

        .global-config__doj-submission-settings-radio-group {
          width: 50%;
          min-width: 230px;

          .global-config__doj-submission-settings-radio-group-title {
            color: $primary-color;

            @include size-2-bold;
          }
          .global-config__doj-submission-settings-radio-group-group {
            margin: 20px auto;

            .MuiTypography-root {
              font-size: 14px;
            }
          }
        }
      }
    }

    .global-config__doj-submission-info-area {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 40px;
      background: $veritone-blue-faded;

      .material-icons {
        font-size: 20px;
        color: $primary-color;
      }
      .global-config__doj-submission-info {
        margin-left: 6px;
        color: $primary-color;
        line-height: 28px;

        @include size-1;
      }
    }

    .global-config__org-flags {
      border-top: solid 1px $blue-disabled;
      margin: 10px 40px;
      padding: 30px 0;
      .global-config__org-flags-clipboard-group {
        @include size-2-bold;

        text-transform: uppercase;
        margin-bottom: 8px;
        color: $veritone-blue;

        .MuiTypography-body1 {
          color: $black;
        }
      }
    }

    .global-config__sftp-settings {
      display: flex;
      flex-direction: column;
      height: auto;
      width: auto;
      margin: 45px 40px;

      &-input {
        margin-top: 30px;
      }

      &-input:first-child {
        margin-top: 0;
      }
    }

    .global-config__doj-submission-settings-save-button-area {
      height: 60px;
      background: $grey-4;

      .global-config__doj-submission-settings-save-button {
        margin: 5px auto;
      }
    }
    .global-config__sftp-settings-save-button-area {
      height: 110px;
      background: $grey-4;

      .global-config__sftp-settings-save-button {
        margin: 10px auto 30px auto;
      }
    }
  }
}

.dark-mode {
  .global-config {
    input:-webkit-autofill,
    input:-webkit-autofill:focus {
      transition: background-color 600000s 0s, color 600000s 0s;
    }
  }
}
