@import "../../../theme/font";
@import "../../../theme/text";
@import "../../../theme/colorVars";

// This file is loaded into every required SASS module.
// Do not include anything in this file that will be actually rendered in CSS, because it will be added to every imported SASS file.
// Add page-level styling to /src/index.scss

// Theme Colors
$primary-color: $veritone-blue;
$secondary-color: $veritone-blue-faded;

// Text
$text-primary: $black;

// Fonts
$font-stack-primary: roboto, sans-serif; // Set globally

// Z-index levels
$dialog-z: 200;
$drawer-clickoff-z: 149;
$drawer-z: 150;
$nav-clickoff-z: 199;
$nav-z: 200;
$snack-z: 9999999999999;

// Shared layout dimensions
$header-height: 55px;
$drawer-width: 321px;
$drawer-width-minimal: 80px;
$min-content-box-width: 500px;
$default-combo-box-search-width: 300px;
$people-form-height: 280px;
$default-left-cell-width: 220px;

// Box Shadows
$shadow-level-0: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
$shadow-level-2: 0 3px 4px 0 rgba(0, 0, 0, 0.24);
$shadow-level-3: 0 3px 8px 0 rgba(0, 0, 0, 0.03);
$shadow-level-3-5: 0 3px 8px 0 rgba(0, 0, 0, 0.3);
$shadow-level-4: 0 4px 24px 0 rgba(0, 0, 0, 0.1);

// Transitions
$drawer-transition: 300ms;

// App vars
$person-tab-width: 147;
$snackHeight: 96;
$snackMargin: 8;

// Export to JS
:export {
  /* stylelint-disable */

  primaryColor: $primary-color;
  secondaryColor: $secondary-color;
  inputBorder: $input-border;
  grey4: $grey-4;
  blueDisabled: $blue-disabled;
  personTabWidth: $person-tab-width;
  snackHeight: $snackHeight;
  snackMargin: $snackMargin;
  headerHeight: $header-height;
  popperBackground: $popper-background;
  fontColor: $font-color;

  /* stylelint-enable */
}

.ripa-review-form {
  .ripa-review-form__review-and-submit {
    background-color: $secondary-color;
    padding: 30px 29px;

    .ripa-review-form__review-and-submit-title {
      padding: 10px;
      text-align: center;
      color: $primary-color;

      @include size-7-bold;
    }
    .ripa-review-form__review-and-submit-nav {
      display: flex;
      margin-top: 33px;

      .ripa-review-form__review-and-submit-nav-back {
        width: calc(50% - 15px);
        margin-right: 30px;
      }
      .ripa-review-form__review-and-submit-nav-submit {
        width: calc(50% - 15px);
      }
    }
  }

  .ripa-review-form__content {
    padding: 30px 0 0;
    margin-bottom: 30px;
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .ripa-review-form__stop-id {
      display: inline-flex;
      width: 180px;
      margin: 0 0 0 30px;

      .material-icons {
        height: 40px;
        font-size: 48px;
        margin-top: -2px;
        margin-right: 6px;
        margin-left: -5px;
        color: $primary-color;
      }

      .ripa-review-form__stop-id-text {
        color: $primary-color;
        line-height: 19px;
        margin-bottom: 4px;

        @include size-2-bold;
      }

      .ripa-review-form__stop-id-value {
        line-height: 24px;

        @include size-4;
      }
    }

    .ripa-review-form__people {
      display: inline-flex;
      width: 180px;

      .material-icons {
        height: 40px;
        font-size: 53px;
        margin-top: -4px;
        margin-right: 4px;
        color: $primary-color;
      }

      .ripa-review-form__people-text {
        color: $primary-color;
        line-height: 19px;
        margin-bottom: 4px;

        @include size-2-bold;
      }

      .ripa-review-form__people-value {
        line-height: 24px;

        @include size-4;
      }
    }

    .ripa-review-form__location {
      display: inline-flex;
      width: 100%;
      padding: 23px 30px 0 30px;

      > div {
        width: calc(100% - 60px);
      }
      .material-icons {
        height: 40px;
        font-size: 45px;
        margin-top: -2px;
        margin-left: -5px;
        width: 52px;
        color: $primary-color;
      }

      .ripa-review-form__location-text {
        color: $primary-color;
        line-height: 19px;
        margin-bottom: 4px;

        @include size-2-bold;
      }

      .ripa-review-form__location-value {
        line-height: 20px;

        @include size-4;

        white-space: nowrap;
        width: calc(100% - 5px);
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .ripa-review-form__date-time-duration {
      display: inline-flex;
      justify-content: space-between;
      align-content: center;
      width: 100%;
      margin: 29px 30px 0;

      .ripa-review-form__date {
        .ripa-review-form__date-text {
          color: $primary-color;

          @include size-2;
        }

        .ripa-review-form__date-value {
          margin-top: 3px;

          @include size-2-bold;
        }
      }

      .ripa-review-form__time {
        .ripa-review-form__time-text {
          color: $primary-color;

          @include size-2;
        }

        .ripa-review-form__time-value {
          margin-top: 3px;

          @include size-2-bold;
        }
      }

      .ripa-review-form__duration {
        .ripa-review-form__duration-text {
          color: $primary-color;

          @include size-2;
        }

        .ripa-review-form__duration-value {
          margin-top: 3px;

          @include size-2-bold;
        }
      }
    }
    .ripa-review-form__type-of-assignment {
      display: inline-flex;
      justify-content: space-between;
      align-content: center;
      width: 100%;
      margin: 29px 30px 0;

      .ripa-review-form__container {
        .ripa-review-form__type-of-assignment-text {
          color: $primary-color;

          @include size-2;
        }

        .ripa-review-form__type-of-assignment-value {
          margin-top: 3px;

          @include size-2-bold;
        }
      }
    }
    .ripa-review-form__stop-description {
      display: inline-flex;
      flex-direction: column;
      width: 100%;
      padding: 23px 30px 0 30px;

      .ripa-review-form__stop-description-label {
        color: $primary-color;

        @include size-2;
      }
      .ripa-review-form__stop-description-value {
        margin-top: 3px;

        @include size-2-bold;
      }
    }

    .ripa-review-form__custom-question {
      display: inline-flex;
      flex-direction: column;
      width: 100%;
      padding: 23px 30px 0 30px;

      .ripa-review-form__custom-question-label {
        color: $primary-color;
        text-transform: uppercase;

        @include size-2;
      }
      .ripa-review-form__custom-question-value {
        margin-top: 3px;

        @include size-2-bold;
      }
    }

    .ripa-review-form__people_accordion {
      width: 100%;
    }
    .ripa-review-form__accordion {
      width: 100%;
      padding: 30px 30px 0;

      .ripa-review-form__accordion-header {
        display: flex;
        justify-content: space-between;
        padding: 30px 0 0;
        border-top: solid 1px $blue-disabled;

        .ripa-review-form__accordion-header-label {
          display: inline-flex;
          justify-content: center;
          align-items: center;

          .material-icons {
            font-size: 40px;
            color: $primary-color;
          }

          .ripa-review-form__accordion-header-label-text {
            margin-left: 10px;

            @include size-2-bold;
          }
        }

        .ripa-review-form__accordion-header-controls {
          display: inline-flex;
          justify-content: center;
          align-items: center;

          .ripa-review-form__accordion-header-controls-edit {
            font-size: 18px;
            margin-right: 5px;
            color: $primary-color;
            cursor: pointer;
            user-select: none;
          }
          .ripa-review-form__accordion-header-controls-expand {
            font-size: 35px;
            color: $primary-color;
            transition: transform 200ms;
            cursor: pointer;
            user-select: none;

            &.open {
              transform: rotate(180deg);
            }
          }
        }
      }

      .ripa-review-form__accordion-content {
        width: 100%;
        display: none;

        &.open {
          display: initial;
        }

        .ripa-review-form__accordion-content-meta {
          display: inline-flex;
          justify-content: space-between;
          align-content: center;
          width: 100%;
          margin-top: 29px;

          .ripa-review-form__accordion-content-gender {
            .ripa-review-form__accordion-content-gender-text {
              color: $primary-color;

              @include size-2;
            }

            .ripa-review-form__accordion-content-gender-value {
              margin-top: 3px;

              @include size-2-bold;
            }
          }

          .ripa-review-form__accordion-content-age {
            .ripa-review-form__accordion-content-age-text {
              white-space: nowrap;
              color: $primary-color;

              @include size-2;
            }

            .ripa-review-form__accordion-content-age-value {
              margin-top: 3px;

              @include size-2-bold;
            }
          }

          .ripa-review-form__accordion-content-ethnicity {
            max-width: 33%;

            .ripa-review-form__accordion-content-ethnicity-text {
              color: $primary-color;

              @include size-2;
            }

            .ripa-review-form__accordion-content-ethnicity-value {
              margin-top: 3px;

              @include size-2-bold;
            }
          }

          .ripa-review-form__accordion-content-lgbt {
            .ripa-review-form__accordion-content-lgbt-text {
              color: $primary-color;

              @include size-2;
            }

            .ripa-review-form__accordion-content-lgbt-value {
              margin-top: 3px;

              @include size-2-bold;
            }
          }

          .ripa-review-form__accordion-content-english {
            white-space: nowrap;

            .ripa-review-form__accordion-content-english-text {
              color: $primary-color;

              @include size-2;
            }

            .ripa-review-form__accordion-content-english-value {
              margin-top: 3px;

              @include size-2-bold;
            }
          }
        }

        .ripa-review-form__accordion-content-disability-label {
          margin-top: 30px;
          color: $primary-color;

          @include size-2;
        }

        .ripa-review-form__accordion-content-unhoused-label {
          margin-top: 30px;
          color: $primary-color;

          @include size-2;
        }

        .ripa-review-form__accordion-content-officer-non-primary-value {
          margin-top: 3px;

          @include size-2-bold;
        }

        .ripa-review-form__accordion-content-officer-non-primary-label {
          margin-top: 30px;
          color: $primary-color;

          @include size-2;
        }

        .ripa-review-form__accordion-content-stop-during-wellness-check-value {
          margin-top: 3px;

          @include size-2-bold;
        }

        .ripa-review-form__accordion-content-stop-during-wellness-check-label {
          margin-top: 30px;
          color: $primary-color;

          @include size-2;
        }

        .ripa-review-form__accordion-content-disability-value {
          margin-top: 3px;

          @include size-2-bold;
        }

        .ripa-review-form__accordion-content-unhoused-value {
          margin-top: 3px;

          @include size-2-bold;
        }

        .ripa-review-form__accordion-content-school-label {
          margin-top: 30px;
          color: $primary-color;

          @include size-2;
        }

        .ripa-review-form__accordion-content-school-value {
          margin-top: 3px;

          @include size-2-bold;
        }

        .ripa-review-form__accordion-content-primary-reason-label {
          margin-top: 30px;
          color: $primary-color;

          @include size-2;
        }

        .ripa-review-form__accordion-content-primary-reason-value {
          margin-top: 3px;

          @include size-2-bold;
        }

        .ripa-review-form__accordion-content-actions-label {
          margin-top: 30px;
          color: $primary-color;

          @include size-2;
        }

        .ripa-review-form__accordion-content-actions-value {
          margin-top: 3px;

          @include size-2-bold;
        }

        .ripa-review-form__accordion-content-search-basis-label {
          margin-top: 30px;
          color: $primary-color;

          @include size-2;
        }

        .ripa-review-form__accordion-content-search-basis-value {
          margin-top: 3px;

          @include size-2-bold;
        }

        .ripa-review-form__accordion-content-probable-cause-label {
          margin-top: 30px;
          color: $primary-color;

          @include size-2;
        }

        .ripa-review-form__accordion-content-probable-cause-value {
          margin-top: 3px;

          @include size-2-bold;
        }

        .ripa-review-form__accordion-content-search-basis-consent-type-label {
          margin-top: 30px;
          color: $primary-color;

          @include size-2;
        }

        .ripa-review-form__accordion-content-search-basis-consent-type-value {
          margin-top: 3px;

          @include size-2-bold;
        }

        .ripa-review-form__accordion-content-property-seizure-label {
          margin-top: 30px;
          color: $primary-color;

          @include size-2;
        }

        .ripa-review-form__accordion-content-property-seizure-value {
          margin-top: 3px;

          @include size-2-bold;
        }

        .ripa-review-form__accordion-content-type-seizure-label {
          margin-top: 30px;
          color: $primary-color;

          @include size-2;
        }

        .ripa-review-form__accordion-content-type-seizure-value {
          margin-top: 3px;

          @include size-2-bold;
        }

        .ripa-review-form__accordion-content-contraband-label {
          margin-top: 30px;
          color: $primary-color;

          @include size-2;
        }

        .ripa-review-form__accordion-content-contraband-value {
          margin-top: 3px;

          @include size-2-bold;
        }

        .ripa-review-form__accordion-content-type-of-stop-label {
          margin-top: 30px;
          color: $primary-color;

          @include size-2;
        }

        .ripa-review-form__accordion-content-type-of-stop-value {
          margin-top: 3px;

          @include size-2-bold;
        }

        .ripa-review-form__accordion-content-result-of-stop-label {
          margin-top: 30px;
          color: $primary-color;

          @include size-2;
        }

        .ripa-review-form__accordion-content-result-of-stop-value {
          margin-top: 3px;

          @include size-2-bold;
        }

        .ripa-review-form__accordion-content-stopped-passenger-label,
        .ripa-review-form__accordion-content-stopped-inside-residence-label,
        .ripa-review-form__accordion-content-stop-description-label,
        .ripa-review-form__accordion-content-custom-question-label,
        .ripa-review-form__accordion-content-search-description-label {
          margin-top: 30px;
          color: $primary-color;
          text-transform: uppercase;

          @include size-2;
        }
        .ripa-review-form__accordion-content-stopped-passenger-value,
        .ripa-review-form__accordion-content-stopped-inside-residence-value,
        .ripa-review-form__accordion-content-stop-description-value,
        .ripa-review-form__accordion-content-custom-question-value,
        .ripa-review-form__accordion-content-search-description-value {
          margin-top: 3px;
          white-space: pre-wrap;

          @include size-2-bold;
        }
      }
    }
  }
  &__accordion-content-custom-question-value {
    overflow: auto;
  }
}

.xs {
  .ripa-review-form {
    min-width: initial;

    .ripa-review-form__review-and-submit {
      display: none;
    }

    .ripa-review-form__content {
      background-color: $secondary-color;

      .ripa-review-form__people {
        margin-right: 25px;
        width: initial;
      }
      .ripa-review-form__location {
        .ripa-review-form__location-value {
          white-space: initial;
          text-overflow: initial;
          overflow: initial;
        }
      }
      .ripa-review-form__date-time-duration {
        margin: 0;
        padding: 30px 30px 0;
      }
      .ripa-review-form__type-of-assignment {
        margin: 0;
        padding: 30px 30px 0;
      }
      .ripa-review-form__stop-description {
        padding: 0 30px 30px 30px;
        margin: 0;
      }
    }
    .ripa-review-form__people_accordion {
      background-color: $white;
      margin-top: 30px;
      padding-bottom: 30px;
    }

    .ripa-review-form__accordion {
      padding: 0 30px;

      .ripa-review-form__accordion-header {
        border-top: solid 1px $blue-disabled;
        padding: 20px 0 0;
        margin-top: 25px;
      }
      &:first-child {
        .ripa-review-form__accordion-header {
          border-top: none;
          margin-top: 0;
        }
      }
      &:last-child {
        .ripa-review-form__accordion-header {
          border-bottom: none;
        }
      }
    }
  }
}

// .dark-mode {
//   .xs {
//     .ripa-review-form {
//     }
//   }
// }
