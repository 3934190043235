@import "../../../theme/font";
@import "../../../theme/text";
@import "../../../theme/colorVars";

// This file is loaded into every required SASS module.
// Do not include anything in this file that will be actually rendered in CSS, because it will be added to every imported SASS file.
// Add page-level styling to /src/index.scss

// Theme Colors
$primary-color: $veritone-blue;
$secondary-color: $veritone-blue-faded;

// Text
$text-primary: $black;

// Fonts
$font-stack-primary: roboto, sans-serif; // Set globally

// Z-index levels
$dialog-z: 200;
$drawer-clickoff-z: 149;
$drawer-z: 150;
$nav-clickoff-z: 199;
$nav-z: 200;
$snack-z: 9999999999999;

// Shared layout dimensions
$header-height: 55px;
$drawer-width: 321px;
$drawer-width-minimal: 80px;
$min-content-box-width: 500px;
$default-combo-box-search-width: 300px;
$people-form-height: 280px;
$default-left-cell-width: 220px;

// Box Shadows
$shadow-level-0: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
$shadow-level-2: 0 3px 4px 0 rgba(0, 0, 0, 0.24);
$shadow-level-3: 0 3px 8px 0 rgba(0, 0, 0, 0.03);
$shadow-level-3-5: 0 3px 8px 0 rgba(0, 0, 0, 0.3);
$shadow-level-4: 0 4px 24px 0 rgba(0, 0, 0, 0.1);

// Transitions
$drawer-transition: 300ms;

// App vars
$person-tab-width: 147;
$snackHeight: 96;
$snackMargin: 8;

// Export to JS
:export {
  /* stylelint-disable */

  primaryColor: $primary-color;
  secondaryColor: $secondary-color;
  inputBorder: $input-border;
  grey4: $grey-4;
  blueDisabled: $blue-disabled;
  personTabWidth: $person-tab-width;
  snackHeight: $snackHeight;
  snackMargin: $snackMargin;
  headerHeight: $header-height;
  popperBackground: $popper-background;
  fontColor: $font-color;

  /* stylelint-enable */
}

.users-row {
  display: block;
  border-bottom: solid 1px $table-border;

  &.checked {
    background-color: $veritone-blue-faded-6;
  }
  .users-row__row {
    position: relative;
    display: flex;
    justify-content: space-between;

    .users-row__cell {
      display: inline-flex;
      align-items: center;
      min-height: 60px;
      padding-right: 20px;

      .users-row__cell-select {
        display: flex;
        justify-content: center;
        align-items: center;

        .users-row__cell-select-checkbox {
          display: flex;
          margin-left: 20px;
          border: solid 1px $veritone-blue;
          cursor: pointer;
          user-select: none;

          .material-icons {
            width: 20px;
            line-height: 20px;
            font-size: 18px;
            font-weight: 900;
            text-align: center;
            color: transparent;
          }

          &.checked {
            .material-icons {
              color: $primary-color;
            }
          }
        }
        .users-row__cell-select-expand {
          fill: $expand-disabled;
          margin-left: 20px;
          cursor: pointer;
          user-select: none;

          &.expanded {
            fill: $expand-enabled;
          }
        }
      }
      .users-row__cell-name,
      .users-row__cell-roles,
      .users-row__cell-reviewer {
        text-transform: capitalize;
      }
      .users-row__cell-name,
      .users-row__cell-username,
      .users-row__cell-org-id,
      .users-row__cell-org-name,
      .users-row__cell-officer-id,
      .users-row__cell-reviewer,
      .users-row__cell-roles,
      .users-row__cell-status,
      .users-row__cell-created {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        line-height: 14px;

        @include size-1;

        &.pending {
          font-style: italic;
        }
        .users-row__cell-roles-role {
          @include size-1-bold;

          &.officer-role {
            color: $officer-role;
          }
          &.reviewer-role {
            color: $reviewer-role;
          }
          &.admin-role {
            color: $admin-role;
          }
          &.org-role {
            color: $org-role;
          }
          &.analyst-role {
            color: $analyst-role;
          }
        }
      }
      .users-row__cell-actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;

        .users-row__table-cell-actions-cancel {
          color: $number-red;
          cursor: pointer;
          user-select: none;

          &.disabled {
            pointer-events: none;
            color: lightgrey;
          }
        }
        .users-row__table-cell-actions-edit {
          cursor: pointer;
          user-select: none;
          color: $primary-color;
          margin-right: 20px;

          .material-icons {
            font-weight: 900;
            font-size: 26px;
          }
        }
      }
    }
  }
}
