@import "../../theme/font";
@import "../../theme/text";
@import "../../theme/colorVars";

// This file is loaded into every required SASS module.
// Do not include anything in this file that will be actually rendered in CSS, because it will be added to every imported SASS file.
// Add page-level styling to /src/index.scss

// Theme Colors
$primary-color: $veritone-blue;
$secondary-color: $veritone-blue-faded;

// Text
$text-primary: $black;

// Fonts
$font-stack-primary: roboto, sans-serif; // Set globally

// Z-index levels
$dialog-z: 200;
$drawer-clickoff-z: 149;
$drawer-z: 150;
$nav-clickoff-z: 199;
$nav-z: 200;
$snack-z: 9999999999999;

// Shared layout dimensions
$header-height: 55px;
$drawer-width: 321px;
$drawer-width-minimal: 80px;
$min-content-box-width: 500px;
$default-combo-box-search-width: 300px;
$people-form-height: 280px;
$default-left-cell-width: 220px;

// Box Shadows
$shadow-level-0: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
$shadow-level-2: 0 3px 4px 0 rgba(0, 0, 0, 0.24);
$shadow-level-3: 0 3px 8px 0 rgba(0, 0, 0, 0.03);
$shadow-level-3-5: 0 3px 8px 0 rgba(0, 0, 0, 0.3);
$shadow-level-4: 0 4px 24px 0 rgba(0, 0, 0, 0.1);

// Transitions
$drawer-transition: 300ms;

// App vars
$person-tab-width: 147;
$snackHeight: 96;
$snackMargin: 8;

// Export to JS
:export {
  /* stylelint-disable */

  primaryColor: $primary-color;
  secondaryColor: $secondary-color;
  inputBorder: $input-border;
  grey4: $grey-4;
  blueDisabled: $blue-disabled;
  personTabWidth: $person-tab-width;
  snackHeight: $snackHeight;
  snackMargin: $snackMargin;
  headerHeight: $header-height;
  popperBackground: $popper-background;
  fontColor: $font-color;

  /* stylelint-enable */
}

.organizations {
  position: relative;
  height: calc(100% - #{$header-height});
  padding: 38px 40px;

  .organizations__snackbar {
    position: absolute;
    right: 16px;
    bottom: 0;
  }
  .organizations__search-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .organizations__search-selects {
      display: flex;
      align-items: center;
    }
    .organizations__search {
      margin: 40px 30px 40px 0;
      width: 60%;

      input {
        height: 19px;
      }
      .material-icons {
        margin-right: 10px;
        font-size: 20px;
        font-weight: 900;
        color: $blue-icon;
      }
    }
    .organizations__actions {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 20px;

      .organizations__add-org {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
        background-color: $number-green;
        text-align: center;
        width: 225px;
        cursor: pointer;
        user-select: none;
        height: 54px;

        .organizations__add-org-text {
          display: inline-flex;
          color: $white;

          @include size-3-bold;
        }
        .material-icons {
          display: inline-flex;
          margin-right: 2px;
          color: $white;
          font-weight: 900;
          margin-top: -3px;
        }
      }
    }
  }
  .organizations__table {
    min-width: 630px;
    height: calc(100% - 240px);
  }
}

.organizations__upload-csv-dialog {
  width: 840px;
}
