@import "../../../theme/font";
@import "../../../theme/text";
@import "../../../theme/colorVars";

// This file is loaded into every required SASS module.
// Do not include anything in this file that will be actually rendered in CSS, because it will be added to every imported SASS file.
// Add page-level styling to /src/index.scss

// Theme Colors
$primary-color: $veritone-blue;
$secondary-color: $veritone-blue-faded;

// Text
$text-primary: $black;

// Fonts
$font-stack-primary: roboto, sans-serif; // Set globally

// Z-index levels
$dialog-z: 200;
$drawer-clickoff-z: 149;
$drawer-z: 150;
$nav-clickoff-z: 199;
$nav-z: 200;
$snack-z: 9999999999999;

// Shared layout dimensions
$header-height: 55px;
$drawer-width: 321px;
$drawer-width-minimal: 80px;
$min-content-box-width: 500px;
$default-combo-box-search-width: 300px;
$people-form-height: 280px;
$default-left-cell-width: 220px;

// Box Shadows
$shadow-level-0: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
$shadow-level-2: 0 3px 4px 0 rgba(0, 0, 0, 0.24);
$shadow-level-3: 0 3px 8px 0 rgba(0, 0, 0, 0.03);
$shadow-level-3-5: 0 3px 8px 0 rgba(0, 0, 0, 0.3);
$shadow-level-4: 0 4px 24px 0 rgba(0, 0, 0, 0.1);

// Transitions
$drawer-transition: 300ms;

// App vars
$person-tab-width: 147;
$snackHeight: 96;
$snackMargin: 8;

// Export to JS
:export {
  /* stylelint-disable */

  primaryColor: $primary-color;
  secondaryColor: $secondary-color;
  inputBorder: $input-border;
  grey4: $grey-4;
  blueDisabled: $blue-disabled;
  personTabWidth: $person-tab-width;
  snackHeight: $snackHeight;
  snackMargin: $snackMargin;
  headerHeight: $header-height;
  popperBackground: $popper-background;
  fontColor: $font-color;

  /* stylelint-enable */
}

.report-row {
  display: block;
  border-bottom: solid 1px $table-border;

  &.error {
    background-color: $row-error;

    .report-row__cell-location {
      border-left: solid 5px $number-red;
    }
  }
  &.testcase2024 {
    background-color: $number-orange !important;

    .report-row__cell-progress,
    .report-row__cell-status {
      color: $black !important;
    }
  }
  &.syncing {
    background-color: $row-syncing;
  }
  .report-row__row {
    position: relative;
    display: flex;
    justify-content: space-between;

    .report-row__cell {
      display: inline-flex;
      align-items: center;
      min-height: 70px;

      .report-row__cell-location,
      .report-row__cell-assignment,
      .report-row__cell-district,
      .report-row__cell-beat,
      .report-row__cell-time,
      .report-row__cell-people,
      .report-row__cell-progress,
      .report-row__cell-status {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 23px 16px 23px 0;
        line-height: 24px;
        text-transform: capitalize;

        @include size-1;

        &.pending {
          font-style: italic;
        }
      }
      .report-row__cell-location {
        padding-left: 20px;
      }
      .report-row__cell-status {
        font-weight: 600;

        &.offline {
          color: $blue-disabled;
        }
        &.syncfailed,
        &.rejected {
          color: $number-red;
        }
        &.draft {
          color: $veritone-blue-faded-4;
        }
        &.syncing,
        &.under_review {
          color: $primary-color;
        }
        &.synced,
        &.submitted,
        &.approved {
          color: $number-green;
        }
        &.denied {
          color: $number-orange;
        }
      }
      .report-row__cell-progress {
        font-weight: 600;

        &.red {
          color: $number-red;
        }
        &.orange {
          color: $number-orange;
        }
        &.green {
          color: $number-green;
        }
      }
      .report-row__cell-actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        padding-right: 20px;

        .dashboard__table-cell-actions-cancel {
          color: $number-red;
          cursor: pointer;
          user-select: none;

          &.disabled {
            color: $blue-disabled;
            pointer-events: none;
            cursor: initial;
          }
        }
        .dashboard__table-cell-actions-rejected-dialog {
          color: $row-error;
          background-color: $number-red;
          cursor: pointer;
          user-select: none;
          border-radius: 50%;
          font-size: 16px;
          padding: 3px;
          font-weight: 500;
        }
        .dashboard__table-cell-actions-continue {
          width: 78px;
          height: 24px;
          background-color: $primary-color;
          color: $white;
          border-radius: 4px;
          line-height: 24px;
          text-align: center;
          margin-left: 15px;
          padding: 0 7px;
          cursor: pointer;
          user-select: none;

          @include size-1;

          &.disabled {
            background-color: $blue-disabled;
            pointer-events: none;
            cursor: initial;
          }
        }

        .dashboard__table-cell-actions-upload {
          width: 78px;
          height: 24px;
          background-color: $number-green;
          color: $white;
          border-radius: 4px;
          line-height: 24px;
          text-align: center;
          margin-left: 15px;
          padding: 0 7px;
          cursor: pointer;
          user-select: none;

          @include size-1;
        }
        .dashboard__table-cell-actions-continue-small {
          display: none;
          cursor: pointer;
          user-select: none;
          color: $white;
          background-color: $primary-color;

          &.disabled {
            color: $blue-disabled;
            pointer-events: none;
            cursor: initial;
          }
        }
      }
    }
  }
}

.dark-mode {
  .report-row__row {
    .report-row__cell {
      .report-row__cell-actions {
        .dashboard__table-cell-actions-continue {
          color: $black;
        }
        .dashboard__table-cell-actions-rejected-dialog {
          color: $black;
        }
        .dashboard__table-cell-actions-cancel {
          background-color: white;
          height: 15px;
          width: 15px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
