@import "../../../theme/font";
@import "../../../theme/text";
@import "../../../theme/colorVars";

// This file is loaded into every required SASS module.
// Do not include anything in this file that will be actually rendered in CSS, because it will be added to every imported SASS file.
// Add page-level styling to /src/index.scss

// Theme Colors
$primary-color: $veritone-blue;
$secondary-color: $veritone-blue-faded;

// Text
$text-primary: $black;

// Fonts
$font-stack-primary: roboto, sans-serif; // Set globally

// Z-index levels
$dialog-z: 200;
$drawer-clickoff-z: 149;
$drawer-z: 150;
$nav-clickoff-z: 199;
$nav-z: 200;
$snack-z: 9999999999999;

// Shared layout dimensions
$header-height: 55px;
$drawer-width: 321px;
$drawer-width-minimal: 80px;
$min-content-box-width: 500px;
$default-combo-box-search-width: 300px;
$people-form-height: 280px;
$default-left-cell-width: 220px;

// Box Shadows
$shadow-level-0: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
$shadow-level-2: 0 3px 4px 0 rgba(0, 0, 0, 0.24);
$shadow-level-3: 0 3px 8px 0 rgba(0, 0, 0, 0.03);
$shadow-level-3-5: 0 3px 8px 0 rgba(0, 0, 0, 0.3);
$shadow-level-4: 0 4px 24px 0 rgba(0, 0, 0, 0.1);

// Transitions
$drawer-transition: 300ms;

// App vars
$person-tab-width: 147;
$snackHeight: 96;
$snackMargin: 8;

// Export to JS
:export {
  /* stylelint-disable */

  primaryColor: $primary-color;
  secondaryColor: $secondary-color;
  inputBorder: $input-border;
  grey4: $grey-4;
  blueDisabled: $blue-disabled;
  personTabWidth: $person-tab-width;
  snackHeight: $snackHeight;
  snackMargin: $snackMargin;
  headerHeight: $header-height;
  popperBackground: $popper-background;
  fontColor: $font-color;

  /* stylelint-enable */
}

.edit-user {
  .label-dropdown__dropdown {
    .Mui-disabled {
      border-color: $input-border;
    }
  }
  .edit-user__name-container {
    display: flex;
    justify-content: center;
    align-self: center;
    margin-bottom: 30px;

    .edit-user__name-first-name {
      width: calc(50% - 15px);
      margin-right: 30px;
    }
    .edit-user__name-last-name {
      width: calc(50% - 15px);
    }
  }
  .edit-user__username-and-officer-id-container {
    display: flex;
    justify-content: center;
    align-self: center;
    margin-bottom: 30px;

    .edit-user__username {
      width: calc(50% - 15px);
      margin-right: 30px;
    }
    .edit-user__officer-id {
      width: calc(50% - 15px);
    }
  }

  .edit-user__years {
    width: calc(50% - 15px);
  }

  .edit-user__email,
  .edit-user__username-and-officer-id-container,
  .edit-user__years {
    margin-bottom: 30px;
  }
  .edit-user__hr {
    background-color: $blue-border;
    width: 100%;
    height: 2px;
    margin-bottom: 30px;
  }
  .edit-user__roles-heading {
    display: flex;
    justify-content: space-between;
    justify-content: flex-start;
    margin-bottom: 8px;

    .edit-user__roles-text {
      display: inline-block;

      @include size-2-bold;

      text-transform: uppercase;
      margin-right: 5px;
      color: $primary-color;
    }

    .material-icons {
      color: $primary-color;
      font-size: 20px;
      transform: translateY(-2px);
    }
  }
  .add-user__checkbox-officer,
  .edit-user__checkbox-reviewer,
  .edit-user__checkbox-analyst,
  .edit-user__checkbox-admin {
    margin-bottom: 30px;
    margin-top: 16px;
  }
  .edit-user__checkbox-reviewer,
  .edit-user__checkbox-admin,
  .edit-user__checkbox-analyst {
    margin-left: 30px;
  }
  .edit-user__change-password-toggle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 26px;
    cursor: pointer;

    .edit-user__change-password-toggle-title {
      color: $primary-color;

      @include size-6-bold;
    }
    .material-icons {
      color: $primary-color;
      font-weight: bold;
      font-size: 28px;
    }
  }
  .edit-user__password {
    margin-bottom: 20px;
  }
  .edit-user__confirm-password {
    margin-bottom: 20px;
  }
  .edit-user__password-validation-text {
    @include size-2-bold;

    margin-bottom: 10px;
  }
  .reset-password-confirm-error {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;

    .material-icons {
      font-size: 13px;
      color: $number-red;
    }
    .reset-password-confirm-error-text {
      margin-left: 5px;
      width: 225px;
      font-size: 11px;
      color: $number-red;
    }
  }
  .no-error {
    display: none;
  }
  .edit-user__dropdown-reviewer {
    margin-bottom: 30px;
  }

  .edit-user__password-validation-checks {
    margin-bottom: 20px;

    .edit-user__password-validation-check {
      width: 50%;
      display: inline-flex;
      align-items: center;

      &:nth-child(1),
      &:nth-child(2) {
        margin: 0 0 5px 0;
      }
      .edit-user__box {
        &.checked {
          .material-icons {
            color: $number-green;
          }
        }
        .material-icons {
          color: $reviewer-role;
          font-size: 18px;
          line-height: 25px;
          margin-right: 8px;
          font-weight: 900;
        }
      }
      .edit-user__password-validation-check-label {
        @include size-2-bold;

        color: $primary-color;
      }
    }
  }

  .edit-user__delete-button-area {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;

    .edit-user__delete-button {
      width: 205px;
      line-height: 38px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border: solid 2px $number-red;
      color: $number-red;
      user-select: none;
      cursor: pointer;

      @include size-2-bold;

      .material-icons {
        color: $number-red;
        padding: 2px;
        margin-right: 8px;
      }
    }
  }
}

.xs {
  .edit-user {
    &__name-container,
    &__username-and-officer-id-container {
      flex-direction: column;

      .edit-user {
        &__name-first-name,
        &__username {
          width: 100%;
          margin: 0 0 30px 0;
        }
        &__name-last-name,
        &__officer-id {
          width: 100%;
        }
      }
    }
    &__years {
      width: 100%;
    }
    &__checkbox-officer,
    &__checkbox-reviewer,
    &__checkbox-analyst,
    &__checkbox-admin {
      width: 100%;
      margin: 0 0 30px 0;

      &.checkbox {
        justify-content: flex-start;
      }
    }
    &__password-validation-checks {
      .edit-user__password-validation-check {
        width: 100%;

        & > div {
          margin: 0 0 5px 0;
        }
        &:last-child {
          margin: 0;
        }
      }
    }
  }
}
