@import "../../../theme/font";
@import "../../../theme/text";
@import "../../../theme/colorVars";

// This file is loaded into every required SASS module.
// Do not include anything in this file that will be actually rendered in CSS, because it will be added to every imported SASS file.
// Add page-level styling to /src/index.scss

// Theme Colors
$primary-color: $veritone-blue;
$secondary-color: $veritone-blue-faded;

// Text
$text-primary: $black;

// Fonts
$font-stack-primary: roboto, sans-serif; // Set globally

// Z-index levels
$dialog-z: 200;
$drawer-clickoff-z: 149;
$drawer-z: 150;
$nav-clickoff-z: 199;
$nav-z: 200;
$snack-z: 9999999999999;

// Shared layout dimensions
$header-height: 55px;
$drawer-width: 321px;
$drawer-width-minimal: 80px;
$min-content-box-width: 500px;
$default-combo-box-search-width: 300px;
$people-form-height: 280px;
$default-left-cell-width: 220px;

// Box Shadows
$shadow-level-0: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
$shadow-level-2: 0 3px 4px 0 rgba(0, 0, 0, 0.24);
$shadow-level-3: 0 3px 8px 0 rgba(0, 0, 0, 0.03);
$shadow-level-3-5: 0 3px 8px 0 rgba(0, 0, 0, 0.3);
$shadow-level-4: 0 4px 24px 0 rgba(0, 0, 0, 0.1);

// Transitions
$drawer-transition: 300ms;

// App vars
$person-tab-width: 147;
$snackHeight: 96;
$snackMargin: 8;

// Export to JS
:export {
  /* stylelint-disable */

  primaryColor: $primary-color;
  secondaryColor: $secondary-color;
  inputBorder: $input-border;
  grey4: $grey-4;
  blueDisabled: $blue-disabled;
  personTabWidth: $person-tab-width;
  snackHeight: $snackHeight;
  snackMargin: $snackMargin;
  headerHeight: $header-height;
  popperBackground: $popper-background;
  fontColor: $font-color;

  /* stylelint-enable */
}

.ripa-gender-form {
  padding-bottom: 40px;
  .ripa-gender-form__title {
    padding: 30px 30px 0;
    width: 100%;
    text-align: center;

    @include size-7-bold;
  }
  .ripa-gender-form__use-same {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 33px 0 29px;
    min-width: 370px;
  }
  .ripa-gender-form__use-same-switch {
    display: inline-flex;
  }
  .ripa-gender-form__use-same-line {
    display: inline-flex;
    width: 90px;
    height: 3px;
    margin: 0 7px;
    background-color: $secondary-color;
  }
  .ripa-gender-form__use-same-text {
    display: inline-flex;
    width: 105px;
    margin: 0 7px 0 0;
    text-align: right;
    color: $primary-color;

    @include size-0;

    &.disabled {
      color: $blue-disabled;
      width: 122px;
    }
  }
  .ripa-gender-form__labels-container {
    display: flex;
    justify-content: center;
    padding: 0 30px;
  }

  .ripa-gender-form__labels {
    width: 100%;
    max-width: 500px;

    .ripa-gender-form__selection {
      display: flex;
      justify-content: center;
      border-bottom: solid 1px $blue-disabled;
      padding: 30px 0;

      &:first-child {
        padding: 0 0 30px;
      }
      &:last-child {
        border-bottom: none;
      }
      .ripa-gender-form__label-container {
        display: inline-flex;
        width: 239px;
        position: relative;
        align-items: flex-start;

        .ripa-gender-form__label {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          overflow-wrap: anywhere;

          .material-icons {
            display: inline-flex;
            color: $primary-color;
            font-size: 40px;
            align-items: center;
            justify-content: center;
            margin-left: 3px;
          }
          .ripa-gender-form__label-text {
            display: inline-flex;
            color: $primary-color;
            align-items: center;
            justify-content: center;
            margin: 0 10px;

            @include size-2-bold;
          }
        }
        .ripa-gender-form__gender-non-conforming-switch-container {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          bottom: 16px;

          .ripa-gender-form__gender-non-conforming-switch-text {
            width: 103px;

            @include size-1;

            line-height: 16px;
          }
          .ripa-gender-form__gender-non-conforming-switch {
            margin-right: 5px;
          }
        }
      }

      .ripa-gender-form__select-boxes {
        display: inline-flex;
        flex-wrap: wrap;
        width: 262px;
      }
      .ripa-gender-form__select-box {
        &:nth-child(n) {
          width: 100px;
        }
        &:nth-child(2n) {
          width: 100px;
          margin-right: 0;
        }

        display: inline-flex;
        height: 50px;
        width: 100px;
        margin: 0 10px 10px 0;
        padding: 5px;
        justify-content: center;
        line-height: 18px;
        border-radius: 3px;
        background-color: $veritone-blue-faded;
        border: solid 2px $veritone-blue;
        color: $primary-color;
        cursor: pointer;
        user-select: none;
        text-align: center;
        align-items: center;

        @include size-2-bold;

        fieldset {
          border-color: transparent;
        }
        &.active {
          background-color: $veritone-blue-faded-3;
          color: $white;
        }
        &.disable {
          color: black;
          opacity: 0.4;
          pointer-events: none;
        }
      }
    }
  }
}

.xs {
  .ripa-gender-form__use-same {
    min-width: initial;

    .ripa-gender-form__use-same-line {
      width: 60px;
    }
  }
  .ripa-gender-form__labels {
    .ripa-gender-form__selection {
      display: flex;
      flex-wrap: wrap;

      .ripa-gender-form__select-boxes {
        width: 100%;
      }
      .ripa-gender-form__select-box {
        &:nth-child(n) {
          width: calc(50% - 5px);
        }
      }
      .ripa-gender-form__label-container {
        width: 100%;
        align-items: flex-start;
        height: 77px;
        margin-bottom: 20px;

        .ripa-gender-form__gender-non-conforming-switch-container {
          position: absolute;
          bottom: 0;

          .ripa-gender-form__gender-non-conforming-switch {
            margin-right: 10px;
          }
          .ripa-gender-form__gender-non-conforming-switch-text {
            width: 170px;
          }
        }
      }
    }
  }
}

.dark-mode {
  .ripa-gender-form {
    .ripa-gender-form__labels {
      .ripa-gender-form__selection {
        .ripa-gender-form__select-box {
          &.active {
            background-color: $primary-color;
            color: $black;
          }
        }
      }
    }
  }
}
