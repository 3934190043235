@import "../../../theme/font";
@import "../../../theme/text";
@import "../../../theme/colorVars";

// This file is loaded into every required SASS module.
// Do not include anything in this file that will be actually rendered in CSS, because it will be added to every imported SASS file.
// Add page-level styling to /src/index.scss

// Theme Colors
$primary-color: $veritone-blue;
$secondary-color: $veritone-blue-faded;

// Text
$text-primary: $black;

// Fonts
$font-stack-primary: roboto, sans-serif; // Set globally

// Z-index levels
$dialog-z: 200;
$drawer-clickoff-z: 149;
$drawer-z: 150;
$nav-clickoff-z: 199;
$nav-z: 200;
$snack-z: 9999999999999;

// Shared layout dimensions
$header-height: 55px;
$drawer-width: 321px;
$drawer-width-minimal: 80px;
$min-content-box-width: 500px;
$default-combo-box-search-width: 300px;
$people-form-height: 280px;
$default-left-cell-width: 220px;

// Box Shadows
$shadow-level-0: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
$shadow-level-2: 0 3px 4px 0 rgba(0, 0, 0, 0.24);
$shadow-level-3: 0 3px 8px 0 rgba(0, 0, 0, 0.03);
$shadow-level-3-5: 0 3px 8px 0 rgba(0, 0, 0, 0.3);
$shadow-level-4: 0 4px 24px 0 rgba(0, 0, 0, 0.1);

// Transitions
$drawer-transition: 300ms;

// App vars
$person-tab-width: 147;
$snackHeight: 96;
$snackMargin: 8;

// Export to JS
:export {
  /* stylelint-disable */

  primaryColor: $primary-color;
  secondaryColor: $secondary-color;
  inputBorder: $input-border;
  grey4: $grey-4;
  blueDisabled: $blue-disabled;
  personTabWidth: $person-tab-width;
  snackHeight: $snackHeight;
  snackMargin: $snackMargin;
  headerHeight: $header-height;
  popperBackground: $popper-background;
  fontColor: $font-color;

  /* stylelint-enable */
}

.users {
  position: relative;
  height: calc(100% - #{$header-height});
  padding: 38px 40px;

  .users__snackbar {
    position: absolute;
    right: 16px;
    bottom: 0;
  }
  .users__change-assignment {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-radius: 4px;
    background-color: $white;
    box-shadow: $shadow-level-3;
    border: solid 2px $filter-border;
    min-width: 895px;
    position: relative;

    .users__change-assignment-text {
      width: 43%;
      text-align: center;
      color: $primary-color;
      padding: 0 20px;
    }
    .users__change-assignment-button {
      height: 55px;
      min-width: 230px;
      border-radius: 4px;
      background-color: $primary-color;
      color: $white;
      margin-left: 20px;
      line-height: 55px;
      text-align: center;
      user-select: none;
      cursor: pointer;

      @include size-3;
    }
    .users__change-assignment-dropdown {
      width: calc(100% - 250px);
      min-width: 200px;
    }
    .users__change-assignment-controls {
      width: 57%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .users__search-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .users__search-selects {
      display: flex;
      align-items: center;
    }
    .users__search {
      margin: 40px 30px 40px 20px;
      width: 40%;

      input {
        height: 19px;
      }
      .material-icons {
        margin-right: 10px;
        font-size: 20px;
        font-weight: 900;
        color: $blue-icon;
      }
    }
    .users__role-select,
    .users__change-assignment-dropdown {
      width: 260px;
      margin-right: 30px;
    }
    .users__actions {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 0 0 30px;

      .users__add-user {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
        background-color: $number-green;
        text-align: center;
        width: 175px;
        cursor: pointer;
        user-select: none;
        height: 54px;

        .users__add-user-text {
          display: inline-flex;
          color: $white;

          @include size-3-bold;
        }
        .material-icons {
          display: inline-flex;
          margin-right: 2px;
          color: $white;
          font-weight: 900;
          margin-top: -3px;
        }
      }
      .users__export-csv {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px;
        border-style: solid;
        border-radius: 3px;
        border-color: $primary-color;
        text-align: center;
        width: 175px;
        cursor: pointer;
        user-select: none;
        height: 54px;
        margin-left: 30px;

        .users__export-csv-text {
          display: inline-flex;
          color: $primary-color;

          @include size-3-bold;
        }
      }
      .users__export-csv:hover {
        background-color: $primary-color;
        .users__export-csv-text {
          display: inline-flex;
          color: $number-white;

          @include size-3-bold;
        }
      }
    }
  }
  .users__table {
    min-width: 630px;
    height: calc(100% - 240px);
  }
}

.sm {
  .users {
    &__change-assignment {
      flex-direction: column;
      min-width: auto;

      &-text {
        width: 100% !important;
        margin-bottom: 20px;
      }

      &-controls {
        width: 100% !important;

        .users__change-assignment-dropdown {
          flex: 1;
        }
      }
    }

    &__search-container {
      flex-wrap: wrap;

      .users {
        &__actions {
          margin: auto;
        }

        &__search {
          width: 100%;
          margin: 0;
        }

        &__role-select,
        &__reviewer-select {
          width: calc(50% - 10px);
          margin: 0;
        }
      }

      & > div {
        margin-top: 20px !important;
      }

      & > div:last-of-type {
        margin-bottom: 20px;
      }
    }

    &__table {
      min-width: auto;
      height: calc(100% - 384px);
    }
  }
}

.xs {
  .users-mobile {
    position: relative;
    height: 100%;
    min-width: 300px;

    &__snackbar {
      position: absolute;
      right: 16px;
      bottom: 0;
    }
    &__search-container {
      background-color: $primary-color;

      .users-mobile__search-add-user-container {
        display: flex;
        flex-direction: row;
        width: calc(100% - 30px);
        margin: 0 15px 15px;

        .users__search {
          flex: 3;
          margin-right: 5px;
          border-radius: 4px;
        }

        .users-mobile__add-user {
          flex: 1;
          height: auto;
          background: $number-green;
          margin-left: 5px;
          line-height: 20px;

          .material-icons {
            line-height: 52px;
            font-weight: 900;
          }

          .button__text {
            margin-top: 8px;
          }
        }
      }
    }
    .users {
      &__role-select {
        width: calc(50% - 22.5px);
        margin: 0 7.5px 15px 15px;
        border-radius: 4px;
      }
      &__reviewer-select {
        width: calc(50% - 22.5px);
        margin: 0 15px 15px 7.5px;
        border-radius: 4px;
      }
    }
    &__cards {
      position: relative;
      padding: 10px 30px 30px;
      background-color: $grey-b;
      height: calc(100% - 197px);
      overflow-y: auto;

      .lds-ellipsis {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .users-mobile__card {
        position: relative;
        margin-top: 20px;
        padding: 20px;
        border: solid 1px $card-border;
        box-shadow: $shadow-level-2;
        border-radius: 3px;
        background-color: $white;

        &-name {
          width: 250px;
          color: $primary-color;
          margin-right: 5px;

          @include size-2-bold;
        }
        &-username {
          margin-top: 13px;

          @include size-1;
        }
        &-details {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-end;
        }
        &-roles {
          margin-top: 13px;
          text-transform: capitalize;

          @include size-1-bold;

          .admin {
            color: $org-role;
          }
          .officer {
            color: $officer-role;
          }
          .reviewer {
            color: $reviewer-role;
          }
          .analyst {
            color: $analyst-role;
          }
        }
        &-status {
          @include size-1-bold;
        }
      }
    }
  }
}

.dark-mode {
  .users {
    .users__change-assignment {
      .users__change-assignment-button {
        color: $black;
      }
    }
  }
  .users__search-container {
    .users__actions {
      .users__add-user {
        .users__add-user-text {
          color: $black;
        }
        .material-icons {
          color: $black;
        }
      }
      .users__export-csv {
        border-color: $number-white;
        .users__export-csv-text {
          color: $number-white;
        }
      }
      .users__export-csv:hover {
        background-color: $number-white;
        .users__export-csv-text {
          color: $grey-b;
        }
      }
    }
  }
  .users-row__row {
    .users-row__cell {
      .users-row__cell-actions {
        .users-row__table-cell-actions-cancel {
          background-color: $black;
          height: 15px;
          width: 15px;
          border-radius: 50%;
          display: inline-flex;
          justify-content: center;
          align-items: center;

          &.disabled {
            background-color: $white;
          }
        }
      }
    }
  }
  .xs {
    .users {
      .MuiInputBase-root {
        background-color: $black;
      }
    }
    .users__change-assignment-dropdown,
    .users__role-select,
    .users__reviewer-select,
    .users-mobile__search-container .users__search {
      background-color: $black;
    }
  }
}
