@mixin size-0 {
  font-family: Roboto, sans-serif;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
}

@mixin size-0-bold {
  font-family: Roboto, sans-serif;
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
}

@mixin size-1 {
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
}

@mixin size-1-bold {
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
}

@mixin size-1-5-thin {
  font-family: Roboto, sans-serif;
  font-size: 13px;
  font-weight: 100;
  font-stretch: normal;
  font-style: normal;
}

@mixin size-2 {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
}

@mixin size-2-bold {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
}

@mixin size-3 {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
}

@mixin size-3-bold {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
}

@mixin size-4 {
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
}

@mixin size-4-bold {
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
}

@mixin size-5-bold {
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
}

@mixin size-6-bold {
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
}

@mixin size-7 {
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
}

@mixin size-7-bold {
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
}

@mixin size-10 {
  font-family: Roboto, sans-serif;
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
}

@mixin size-10-bold {
  font-family: Roboto, sans-serif;
  font-size: 30px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
}

@mixin size-11-bold {
  font-family: Roboto, sans-serif;
  font-size: 32px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
}
