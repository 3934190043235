@import "../../../theme/font";
@import "../../../theme/text";
@import "../../../theme/colorVars";

// This file is loaded into every required SASS module.
// Do not include anything in this file that will be actually rendered in CSS, because it will be added to every imported SASS file.
// Add page-level styling to /src/index.scss

// Theme Colors
$primary-color: $veritone-blue;
$secondary-color: $veritone-blue-faded;

// Text
$text-primary: $black;

// Fonts
$font-stack-primary: roboto, sans-serif; // Set globally

// Z-index levels
$dialog-z: 200;
$drawer-clickoff-z: 149;
$drawer-z: 150;
$nav-clickoff-z: 199;
$nav-z: 200;
$snack-z: 9999999999999;

// Shared layout dimensions
$header-height: 55px;
$drawer-width: 321px;
$drawer-width-minimal: 80px;
$min-content-box-width: 500px;
$default-combo-box-search-width: 300px;
$people-form-height: 280px;
$default-left-cell-width: 220px;

// Box Shadows
$shadow-level-0: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
$shadow-level-2: 0 3px 4px 0 rgba(0, 0, 0, 0.24);
$shadow-level-3: 0 3px 8px 0 rgba(0, 0, 0, 0.03);
$shadow-level-3-5: 0 3px 8px 0 rgba(0, 0, 0, 0.3);
$shadow-level-4: 0 4px 24px 0 rgba(0, 0, 0, 0.1);

// Transitions
$drawer-transition: 300ms;

// App vars
$person-tab-width: 147;
$snackHeight: 96;
$snackMargin: 8;

// Export to JS
:export {
  /* stylelint-disable */

  primaryColor: $primary-color;
  secondaryColor: $secondary-color;
  inputBorder: $input-border;
  grey4: $grey-4;
  blueDisabled: $blue-disabled;
  personTabWidth: $person-tab-width;
  snackHeight: $snackHeight;
  snackMargin: $snackMargin;
  headerHeight: $header-height;
  popperBackground: $popper-background;
  fontColor: $font-color;

  /* stylelint-enable */
}

.header {
  height: 100%;
  position: relative;
  overflow: hidden;

  header {
    display: flex;
    height: $header-height;
    width: 100%;
    background-color: $primary-color;
    overflow: hidden;

    .header__drawer-button {
      margin-left: 29px;
      line-height: $header-height;
      color: $white;
      cursor: pointer;
    }
    .header__title {
      line-height: $header-height;
      text-transform: uppercase;
      color: $white;
      cursor: pointer;

      @include size-4-bold;
    }
    .header__grow {
      flex-grow: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    .header__time {
      color: white;
    }
    .header__veritone-logo {
      height: 100%;
      padding: 12px;
      width: $header-height;
      // padding: 16px 14px 16px 0;
      margin-left: 29px;
    }
    .header__veritone-logo-nav {
      .header__veritone-logo-button {
        height: $header-height;
        margin-right: 16px;

        &.active {
          background-color: $veritone-blue-dark;
        }
      }
      .header__department-logo {
        height: 100%;
        padding: 12px;
        cursor: pointer;
        user-select: none;
      }
      .header__veritone-nav-clickoff {
        display: none;
        position: fixed;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: $nav-clickoff-z;

        &.open {
          display: initial;
        }
      }
      .header__veritone-nav {
        background-color: $white;
        position: fixed;
        width: 160px;
        right: 16px;
        top: 55px;
        z-index: $nav-z;
        box-shadow: $shadow-level-3-5;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        overflow: hidden;
        display: none;

        &.open {
          display: initial;
        }
        .header__veritone-nav-hr {
          width: 120px;
          left: 20px;
          position: absolute;
          height: 0.5px;
          background-color: $nav-divider;
          opacity: 0.3;
          display: none;

          &.show {
            display: block;
          }
        }
        .header__veritone-nav-settings,
        .header__veritone-nav-signout,
        .header__veritone-nav-chat,
        .header__veritone-nav-user-man,
        .header__veritone-nav-reviewer,
        .header__veritone-nav-visualization,
        .header__veritone-nav-my-reports,
        .header__veritone-nav-global-config {
          padding: 20px 0 20px 20px;
          margin-left: 1px;
          cursor: pointer;
          user-select: none;
          display: none;

          @include size-1;

          &.active {
            border-left: solid 5px $primary-color;

            @include size-1-bold;
          }
          &.show {
            display: block;
          }
        }
        .header__veritone-nav-settings,
        .header__veritone-nav-signout,
        .header__veritone-nav-chat {
          @include size-1-bold;

          display: block;
          color: $primary-color;
        }
      }
    }
  }
  .header__content {
    height: calc(100% - #{$header-height});
    background-color: $grey-b;
  }
  .header__snackbar {
    position: absolute;
    right: 15px;
    bottom: 5px;
  }
}

.sm {
  .header {
    .header__snackbar {
      display: block;
    }
  }
}

.xs {
  .header {
    header .header__veritone-logo-nav .header__veritone-nav {
      right: 0;
    }
    .header__snackbar {
      position: fixed;
      bottom: 3px;
      left: 4%;
      width: 92%;
      z-index: $snack-z;

      .snackbar,
      .snackbar__box {
        width: 100%;
      }
    }
  }
  header {
    position: relative;
    justify-content: center;
    align-items: center;

    .header__title {
      margin: 0;
    }
    .header__grow {
      margin-left: 10px;
      flex-grow: 1;
    }
    .header__veritone-logo {
      margin-left: 0;
    }
    .header__veritone-logo-nav {
      height: 100%;

      .header__veritone-logo-button {
        margin: 0;
      }
    }
  }
}

.dark-mode {
  .header {
    header {
      .header__title {
        color: $black;
      }
      .header__drawer-button {
        color: $black;
      }
    }
  }
}
