@import "../../../theme/font";
@import "../../../theme/text";
@import "../../../theme/colorVars";

// This file is loaded into every required SASS module.
// Do not include anything in this file that will be actually rendered in CSS, because it will be added to every imported SASS file.
// Add page-level styling to /src/index.scss

// Theme Colors
$primary-color: $veritone-blue;
$secondary-color: $veritone-blue-faded;

// Text
$text-primary: $black;

// Fonts
$font-stack-primary: roboto, sans-serif; // Set globally

// Z-index levels
$dialog-z: 200;
$drawer-clickoff-z: 149;
$drawer-z: 150;
$nav-clickoff-z: 199;
$nav-z: 200;
$snack-z: 9999999999999;

// Shared layout dimensions
$header-height: 55px;
$drawer-width: 321px;
$drawer-width-minimal: 80px;
$min-content-box-width: 500px;
$default-combo-box-search-width: 300px;
$people-form-height: 280px;
$default-left-cell-width: 220px;

// Box Shadows
$shadow-level-0: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
$shadow-level-2: 0 3px 4px 0 rgba(0, 0, 0, 0.24);
$shadow-level-3: 0 3px 8px 0 rgba(0, 0, 0, 0.03);
$shadow-level-3-5: 0 3px 8px 0 rgba(0, 0, 0, 0.3);
$shadow-level-4: 0 4px 24px 0 rgba(0, 0, 0, 0.1);

// Transitions
$drawer-transition: 300ms;

// App vars
$person-tab-width: 147;
$snackHeight: 96;
$snackMargin: 8;

// Export to JS
:export {
  /* stylelint-disable */

  primaryColor: $primary-color;
  secondaryColor: $secondary-color;
  inputBorder: $input-border;
  grey4: $grey-4;
  blueDisabled: $blue-disabled;
  personTabWidth: $person-tab-width;
  snackHeight: $snackHeight;
  snackMargin: $snackMargin;
  headerHeight: $header-height;
  popperBackground: $popper-background;
  fontColor: $font-color;

  /* stylelint-enable */
}

.ripa-disability-form {
  padding-bottom: 50px;
  .ripa-disability-form__title {
    padding: 30px 30px 0;
    width: 100%;
    text-align: center;

    @include size-7-bold;
  }
  .ripa-disability-form__student-switch {
    display: inline-flex;
  }
  .ripa-disability-form__disability-control {
    display: inline-flex;

    > div {
      height: 54px;
      width: 90px;
      background-color: $grey-4;
    }
  }

  .ripa-disability-form__use-same {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 0 30px;
    min-width: 370px;
  }
  .ripa-disability-form__use-same-switch {
    display: inline-flex;
  }
  .ripa-disability-form__use-same-line {
    display: inline-flex;
    width: 90px;
    height: 3px;
    margin: 0 7px;
    background-color: $secondary-color;
  }
  .ripa-disability-form__use-same-text {
    display: inline-flex;
    width: 97px;
    margin: 0 7px 0 0;
    text-align: right;
    color: $primary-color;

    @include size-0;

    &.disabled {
      color: $blue-disabled;
      width: 122px;
    }
  }

  .ripa-disability-form__student-text {
    margin: 0 15px 0 30px;
    text-align: center;
    color: $black;

    @include size-1-bold;
  }
  .ripa-disability-form__labels-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0 30px;
    min-width: 500px;
    margin-top: 30px;

    .ripa-disability-form__labels-header {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      @include size-1-bold;

      font-size: 11px;

      .ripa-disability-form__labels-header-person {
        width: 205px;
        text-align: left;
      }
      .ripa-disability-form__labels-header-lgbt {
        width: 46px;
        text-align: left;
      }
      .ripa-disability-form__labels-header-english {
        width: 98px;
        margin: 0 13px 0 21px;
      }
      .ripa-disability-form__labels-header-disabled {
        width: 56px;
        text-align: right;
      }
    }
  }

  .ripa-disability-form__select-box-content {
    display: none;
    flex-wrap: wrap;

    .ripa-disability-form__select-box {
      &:nth-child(3n) {
        margin: 20px 0 0 0;
      }

      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 50px;
      width: 133px;
      margin: 20px 21px 0 0;
      padding: 0 10px;
      border-radius: 3px;
      background-color: $veritone-blue-faded-2;
      border: solid 2px $veritone-blue;
      color: $primary-color;
      cursor: pointer;
      user-select: none;

      @include size-2-bold;

      fieldset {
        border-color: transparent;
      }
      &.active {
        background-color: $veritone-blue-faded-3;
        color: $white;
      }
    }
  }

  .ripa-disability-form__labels {
    width: 100%;
    max-width: 500px;

    .ripa-disability-form__selection {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 30px 0 0 0;
      padding-bottom: 30px;

      &:not(:last-child) {
        border-bottom: solid 1px $blue-disabled;
      }

      .ripa-disability-form__label {
        display: inline-flex;
        width: 184px;
        margin-right: 20px;
        align-items: center;
        justify-content: flex-start;

        .material-icons {
          color: $primary-color;
          font-size: 40px;
          margin-left: -4px;
        }
        .ripa-disability-form__label-text {
          color: $primary-color;
          margin: 0 5px 0 10px;

          @include size-2-bold;
        }
      }
      .ripa-disability-form__english-switch {
        margin: 0 46px;
      }
    }
  }
}

.xs,
.sm {
  .ripa-disability-form {
    .ripa-disability-form__use-same {
      min-width: 300px;
    }
  }
}

.xs {
  .ripa-disability-form {
    padding-bottom: 50px;
    .ripa-disability-form__use-same {
      margin: 33px 0 0;
    }
    .ripa-disability-form__use-same-line {
      width: 70px;
      margin: 0 6px;
    }
    .ripa-disability-form__labels-header {
      display: none;
    }
    .ripa-disability-form__lgbt-switch,
    .ripa-disability-form__english-switch,
    .ripa-disability-form__disability-switch {
      display: none;
    }

    .ripa-disability-form__selection {
      flex-wrap: wrap;
      justify-content: initial;

      .ripa-disability-form__label {
        display: flex;
        align-items: center;
        margin-left: -6px;
        width: initial;
      }
    }
    .ripa-disability-form__labels-container {
      min-width: 300px;
    }
    .ripa-disability-form__select-box-content {
      display: flex;
      flex-wrap: wrap;
      .ripa-disability-form__select-box {
        display: flex;
        width: 100%;
        margin: 20px 0 0 0;

        &:nth-child(3n) {
          margin: 20px 0 0 0;
        }
      }
    }
  }
}

.dark-mode {
  .ripa-disability-form {
    .ripa-disability-form__select-box-content {
      .ripa-disability-form__select-box {
        &.active {
          background-color: $primary-color;
          color: $black;
        }
      }
    }
  }
  .xs {
    .ripa-disability-form {
      .ripa-form-container__helper-box center {
        color: $black;
      }
    }
  }
}
