@import "../../theme/font";
@import "../../theme/text";
@import "../../theme/colorVars";

// This file is loaded into every required SASS module.
// Do not include anything in this file that will be actually rendered in CSS, because it will be added to every imported SASS file.
// Add page-level styling to /src/index.scss

// Theme Colors
$primary-color: $veritone-blue;
$secondary-color: $veritone-blue-faded;

// Text
$text-primary: $black;

// Fonts
$font-stack-primary: roboto, sans-serif; // Set globally

// Z-index levels
$dialog-z: 200;
$drawer-clickoff-z: 149;
$drawer-z: 150;
$nav-clickoff-z: 199;
$nav-z: 200;
$snack-z: 9999999999999;

// Shared layout dimensions
$header-height: 55px;
$drawer-width: 321px;
$drawer-width-minimal: 80px;
$min-content-box-width: 500px;
$default-combo-box-search-width: 300px;
$people-form-height: 280px;
$default-left-cell-width: 220px;

// Box Shadows
$shadow-level-0: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
$shadow-level-2: 0 3px 4px 0 rgba(0, 0, 0, 0.24);
$shadow-level-3: 0 3px 8px 0 rgba(0, 0, 0, 0.03);
$shadow-level-3-5: 0 3px 8px 0 rgba(0, 0, 0, 0.3);
$shadow-level-4: 0 4px 24px 0 rgba(0, 0, 0, 0.1);

// Transitions
$drawer-transition: 300ms;

// App vars
$person-tab-width: 147;
$snackHeight: 96;
$snackMargin: 8;

// Export to JS
:export {
  /* stylelint-disable */

  primaryColor: $primary-color;
  secondaryColor: $secondary-color;
  inputBorder: $input-border;
  grey4: $grey-4;
  blueDisabled: $blue-disabled;
  personTabWidth: $person-tab-width;
  snackHeight: $snackHeight;
  snackMargin: $snackMargin;
  headerHeight: $header-height;
  popperBackground: $popper-background;
  fontColor: $font-color;

  /* stylelint-enable */
}

.dashboard-header {
  .header__department-logo {
    left: 0;
  }
  &.has-training-mode-banner {
    .training-mode-banner {
      top: $header-height;
      display: flex;
    }
  }
}

.dashboard {
  position: relative;
  height: calc(100% - #{$header-height});
  padding: 38px 41px;

  &.has-training-mode-banner {
    margin-top: 43px;
  }
  .dashboard__snackbar {
    position: absolute;
    right: 16px;
    bottom: 0;
  }
  .dashboard__filters {
    display: flex;
    position: relative;
    justify-content: space-around;
    align-items: center;
    padding: 0 270px 0 20px;
    border-radius: 4px;
    background-color: $white;
    box-shadow: $shadow-level-3;
    border: solid 2px $filter-border;
    min-width: 950px;

    &.offline {
      justify-content: start;
      min-width: initial;
      margin-bottom: 30px;
    }

    .dashboard__filters-offline-forms,
    .dashboard__filters-created-today,
    .dashboard__filters-draft-reports,
    .dashboard__filters-under-review,
    .dashboard__filters-rejected-reports,
    .dashboard__filters-approved-reports {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 25px 15px 23px;
      border-bottom: solid 2px transparent;
      cursor: pointer;
      width: 20%;

      &.hide {
        display: none;
      }
      &.active {
        border-bottom: solid 2px $primary-color;
      }

      .dashboard__filters-offline-forms-value,
      .dashboard__filters-created-today-value,
      .dashboard__filters-draft-reports-value,
      .dashboard__filters-under-review-value,
      .dashboard__filters-rejected-reports-value,
      .dashboard__filters-approved-reports-value {
        margin-right: 10px;
        font-size: 40px;
        font-weight: 300;
        color: $primary-color;
        user-select: none;
      }
      .dashboard__filters-offline-forms-label,
      .dashboard__filters-created-today-label,
      .dashboard__filters-draft-reports-label,
      .dashboard__filters-under-review-label,
      .dashboard__filters-rejected-reports-label,
      .dashboard__filters-approved-reports-label {
        margin-top: 2px;
        width: 75px;
        line-height: 18px;
        color: $blue-disabled;
        user-select: none;

        @include size-1-bold;
      }
    }

    .dashboard__filters-new-report {
      display: inline-flex;
      width: 232px;
      min-width: 175px;
      margin: 20px 0;
      position: absolute;
      right: 20px;
      top: 0;

      .material-icons {
        margin: 0 5px;
        font-weight: 900;
        font-size: 25px;
        line-height: 52px;
      }
      .button__text {
        font-weight: 400;
      }
    }
  }

  .dashboard__search-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.offline {
      justify-content: flex-end;
      margin: 40px 0;
    }
    .dashboard__search {
      width: 40%;
      margin: 40px 0;

      &.offline {
        display: none;
      }
      input {
        font-size: 1rem;
      }

      .material-icons {
        margin-right: 10px;
        font-size: 20px;
        font-weight: 900;
        color: $blue-icon;
      }
    }
    .dashboard__search-new-report {
      display: none;
    }
  }

  .dashboard__show-offline {
    width: 270px;
    display: inline-flex;
    justify-content: space-around;
    align-items: center;

    &.hide {
      display: none;
    }

    .dashboard__show-offline-text {
      width: 50px;
      color: $primary-color;
      text-align: right;

      @include size-1-bold;
    }
    .dashboard__show-offline-switch {
      width: 200px;
      height: 50px;
      border-radius: 60px;
      position: relative;
      background-color: $primary-color;
      cursor: pointer;
      margin: 0 10px;

      .dashboard__show-offline-switch-online {
        position: absolute;
        color: $white;
        z-index: 5;
        left: 19px;
        line-height: 50px;
        transition: color 200ms;
        user-select: none;

        &.active {
          color: $primary-color;
        }
      }
      .dashboard__show-offline-thumb {
        height: 40px;
        width: 90px;
        top: 5px;
        border-radius: 60px;
        z-index: 2;
        position: absolute;
        background-color: $white;
        left: 105px;
        transition: left 200ms;

        &.online {
          left: 5px;
        }
      }
      .dashboard__show-offline-switch-offline {
        position: absolute;
        color: $white;
        z-index: 5;
        right: 15px;
        line-height: 50px;
        transition: color 200ms;
        user-select: none;

        &.active {
          color: $primary-color;
        }
      }
    }
  }

  .dashboard__table {
    min-width: 630px;
    height: calc(100% - 240px);

    .table {
      .table__column-header {
        .column {
          &:first-child {
            .column__title {
              padding-left: 20px;
            }
          }
        }
      }
    }
  }
}

.dashboard__review-feedback-dialog-heading {
  text-align: left;
  color: $primary-color;
  text-transform: uppercase;
  margin: 0 0 15px 10px;

  @include size-2-bold;
}
.dashboard__review-feedback-dialog {
  max-height: 700px;
}
.dashboard__review-feedback-dialog-content {
  @include size-2-bold;

  .dashboard__review-feedback-dialog-content-reviewer {
    margin-top: 20px;
    color: $number-red;
  }
  .dashboard__history-content-heading {
    display: flex;
    justify-content: space-between;
  }
  .dashboard__history-content-label {
    color: $number-green;
    font-weight: 900;

    &.previous {
      color: $number-orange;
    }
  }
  .dashboard__history-content-show-button {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--veritone-blue);
    cursor: pointer;
    border-top: solid 1px grey;
    padding-top: 20px;
    margin-top: 10px;
    user-select: none;
    outline: none;

    .material-icons {
      font-size: 20px;
    }
  }
  .dashboard__history-content {
    padding: 10px 0;
    font-weight: 500;
    word-break: break-word;

    &:first-child {
      padding-bottom: 20px;
    }
    &:nth-child(2) {
      padding: 20px 0 10px;
      border-top: solid 1px $input-border;
    }
    .dashboard__history-content-reviewer {
      color: $primary-color;
      margin-top: 15px;
      text-align: right;
    }
    .dashboard__history-content-date {
      margin-bottom: 20px;
      font-weight: 900;
    }
  }
}

.sm {
  .dashboard {
    .dashboard__filters {
      padding: 0;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      min-width: 665px;

      &.offline {
        min-width: initial;
      }
      .dashboard__filters-new-report {
        display: none;
      }
      .dashboard__filters-created-today,
      .dashboard__filters-draft-reports,
      .dashboard__filters-under-review,
      .dashboard__filters-rejected-reports,
      .dashboard__filters-approved-reports {
        display: block;
        padding-left: 0;
        padding-right: 0;

        &.hide {
          display: none;
        }

        .dashboard__filters-created-today-value,
        .dashboard__filters-draft-reports-value,
        .dashboard__filters-under-review-value,
        .dashboard__filters-rejected-reports-value,
        .dashboard__filters-approved-reports-value {
          display: block;
          text-align: center;
          margin: auto;
          font-weight: 300;
        }
        .dashboard__filters-created-today-label,
        .dashboard__filters-draft-reports-label,
        .dashboard__filters-under-review-label,
        .dashboard__filters-rejected-reports-label,
        .dashboard__filters-approved-reports-label {
          margin: auto;
          text-align: center;
          width: 135px;
        }
      }
    }

    .dashboard__search-container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .dashboard__search {
        width: 250px;
        margin-right: 20px;
        min-width: 140px;

        input {
          font-size: 1rem;
        }
      }
      .dashboard__search-new-report {
        display: inline-flex;
        width: 190px;
        min-width: 170px;

        .material-icons {
          line-height: 52px;
          margin: 0 3px;
          font-weight: 600;
        }
        .button__text {
          font-weight: 500;
        }
      }
    }
  }
}

.xs {
  .dashboard-mobile {
    position: relative;
    height: 100%;
    min-width: 300px;

    &.testingBanner {
      .dashboard-mobile__assignment-container {
        padding-top: 10px;
      }
    }
    .dashboard-mobile__snackbar {
      position: absolute;
      right: 16px;
      bottom: 0;
    }
    .dashboard-mobile__assignment-container {
      background-color: $primary-color;
      .dashboard-mobile__assignment-dropdown-container {
        display: flex;
        flex-direction: row;
        width: calc(100% - 30px);
        margin: 0 15px;
        background-color: $primary-color;
        .dashboard-mobile__other-assignment,
        .dashboard-mobile__assignment-dropdown {
          background-color: $primary-color;
          width: 100%;
          // transition: transform $drawer-transition, width $drawer-transition;
          transform: translateY(0);
          .dashboard-mobile-assignment {
            background-color: $grey-4;
          }
        }
        .dashboard-mobile__other-assignment {
          .MuiInputBase-root {
            background-color: $grey-4;
          }
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          input[type="number"] {
            -moz-appearance: textfield;
          }
          ::placeholder {
            @include size-2;

            font-style: italic;
            color: $input-border;
          }
          .MuiInputBase-input {
            text-overflow: ellipsis;
          }
          .dashboard-mobile__other-assignment-controls {
            margin-right: 10px;
            .dashboard-mobile__other-assignment-back {
              background-color: $grey-4;
              color: $white;
              width: 20px;
              height: 20px;
              margin: 0 0 0 43px;
              font-weight: 900;
              border-radius: 50%;
              text-align: center;
              line-height: 20px;
              font-size: 16px;
              cursor: pointer;
              user-select: none;
            }
          }
        }
      }
    }
    .dashboard-mobile__search-container {
      padding-top: 8px;
      background-color: $primary-color;

      .dashboard-mobile__search-new-report-container {
        display: flex;
        flex-direction: row;
        width: calc(100% - 30px);
        margin: 0 15px;

        .dashboard-mobile__search {
          flex: 3;
          border-radius: 4px;
          margin-right: 5px;

          input {
            font-size: 1rem;
          }
        }

        .dashboard-mobile__new-report {
          flex: 1;
          height: auto;
          background: $number-green;
          margin-left: 5px;
          line-height: 20px;

          .material-icons {
            line-height: 52px;
            font-weight: 900;
          }

          .button__text {
            margin-top: 8px;
            margin-left: 12px;
          }
        }
      }
      .login-offline {
        .dashboard-mobile__new-report {
          align-items: center;
          .button__text {
            margin-top: 0;
            margin-left: 0;
          }
        }
      }

      .dashboard-mobile__filters {
        display: flex;
        width: 100%;

        &.offline {
          justify-content: center;

          .dashboard-mobile__filters-offline-forms {
            width: 50%;
          }
        }

        .dashboard-mobile__filters-offline-forms,
        .dashboard-mobile__filters-created-today,
        .dashboard-mobile__filters-draft-reports,
        .dashboard-mobile__filters-under-review,
        .dashboard-mobile__filters-rejected-reports {
          display: inline-block;
          width: 25%;
          padding: 16px 5px 10px;
          border-bottom: solid 5px transparent;
          cursor: pointer;

          &.hide {
            display: none;
          }

          &.active {
            border-bottom: solid 5px $veritone-blue-faded-4;
          }
          .dashboard-mobile__filters-offline-forms-value,
          .dashboard-mobile__filters-created-today-value,
          .dashboard-mobile__filters-draft-reports-value,
          .dashboard-mobile__filters-under-review-value,
          .dashboard-mobile__filters-rejected-reports-value {
            margin: auto;
            color: $white;
            user-select: none;
            text-align: center;

            @include size-7;
          }
          .dashboard-mobile__filters-offline-forms-label,
          .dashboard-mobile__filters-created-today-label,
          .dashboard-mobile__filters-draft-reports-label,
          .dashboard-mobile__filters-under-review-label,
          .dashboard-mobile__filters-rejected-reports-label {
            margin-top: 2px;
            line-height: 16px;
            color: $veritone-blue-faded-4;
            user-select: none;
            text-align: center;

            @include size-1;
          }
        }
      }
    }
    .dashboard-mobile__cards {
      padding: 10px 15px 30px;
      background-color: $grey-b;
      height: calc(100% - 188px);
      overflow-y: auto;

      .dashboard-mobile__card {
        position: relative;
        margin-top: 20px;
        padding: 20px;
        border: solid 1px $card-border;
        box-shadow: $shadow-level-2;
        border-radius: 3px;
        background-color: $white;

        .dashboard-mobile__card-details {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 13px;
        }
        .dashboard-mobile__card-location {
          width: 250px;
          color: $primary-color;
          margin-right: 5px;

          @include size-2-bold;
        }
        .dashboard__table-cell-action-rejected-dialog {
          color: $row-error;
          background-color: $number-red;
          cursor: pointer;
          user-select: none;
          border-radius: 50%;
          font-size: 16px;
          padding: 3px;
          font-weight: 500;
          margin-top: 13px;
        }
        .dashboard-mobile__card-assignment {
          margin-top: 13px;
          text-transform: capitalize;

          @include size-1;
        }
        .dashboard-mobile__card-time-status-container {
          display: flex;
          justify-content: space-between;

          .dashboard-mobile__card-time {
            margin-top: 13px;

            @include size-1-bold;
          }
          .dashboard-mobile__card-status {
            margin-top: 13px;
            color: $blue-disabled;

            @include size-1-bold;

            &.offline {
              color: $blue-disabled;
            }
            &.syncfailed,
            &.rejected {
              color: $number-red;
            }
            &.draft {
              color: $veritone-blue-faded-4;
            }
            &.synced,
            &.submitted,
            &.approved {
              color: $number-green;
            }
            &.denied {
              color: $number-orange;
            }
            &.syncing,
            &.under_review {
              color: $primary-color;
            }
          }
        }
        .dashboard-mobile__card-people {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          margin-left: -4px;

          .dashboard-mobile__card-people-icon {
            color: $primary-color;
            font-size: 24px;
          }
          .dashboard-mobile__card-people-value {
            color: $primary-color;

            @include size-2-bold;
          }
        }
        .dashboard-mobile__card-k12 {
          display: inline-flex;
          justify-content: center;
          align-items: center;

          .dashboard-mobile__card-k12-icon {
            color: $number-red;
            font-size: 18px;
            margin-right: 3px;
            font-weight: 700;

            &.k12 {
              color: $number-green;
            }
          }
          .dashboard-mobile__card-k12-label {
            color: $primary-color;

            @include size-2-bold;
          }
        }
        .dashboard-mobile__card-progress {
          display: inline-flex;
          justify-content: center;
          align-items: center;

          .dashboard-mobile__card-progress-value {
            margin-right: 5px;

            @include size-2-bold;

            &.red {
              color: $number-red;
            }
            &.orange {
              color: $number-orange;
            }
            &.green {
              color: $number-green;
            }
          }
          .dashboard-mobile__card-progress-label {
            color: $blue-disabled;
            margin-top: 2px;

            @include size-1;
          }
        }
      }

      .dashboard-mobile__cards-empty {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $blue-disabled;
      }
    }
  }
}

.dark-mode {
  .xs {
    .dashboard-mobile {
      .dashboard-mobile__assignment-container .dashboard-mobile__assignment-dropdown-container {
        .dashboard-mobile__other-assignment,
        .dashboard-mobile__assignment-dropdown {
          .dashboard-mobile-assignment {
            background-color: $black;
          }
          .MuiInputBase-root {
            background-color: $black;
          }
        }
      }
      .dashboard-mobile-assignment {
        background-color: $black;
      }
      .dashboard-mobile__search {
        background-color: $black;
      }
      .dashboard-mobile__filters {
        .dashboard-mobile__filters-rejected-reports .dashboard-mobile__filters-rejected-reports-value,
        .dashboard-mobile__filters-under-review .dashboard-mobile__filters-under-review-value,
        .dashboard-mobile__filters-created-today .dashboard-mobile__filters-created-today-value,
        .dashboard-mobile__filters-draft-reports .dashboard-mobile__filters-draft-reports-value {
          color: $black;
        }
      }
      .dashboard-mobile__search-container .dashboard-mobile__filters .dashboard-mobile__filters-offline-forms .dashboard-mobile__filters-offline-forms-value,
      .dashboard-mobile__cards .dashboard-mobile__card .dashboard__table-cell-action-rejected-dialog {
        color: $black;
      }
      .dashboard-mobile__search-container .dashboard-mobile__filters .dashboard-mobile__filters-offline-forms.active,
      .dashboard-mobile__search-container .dashboard-mobile__filters .dashboard-mobile__filters-created-today.active,
      .dashboard-mobile__search-container .dashboard-mobile__filters .dashboard-mobile__filters-draft-reports.active,
      .dashboard-mobile__search-container .dashboard-mobile__filters .dashboard-mobile__filters-under-review.active,
      .dashboard-mobile__search-container .dashboard-mobile__filters.dashboard-mobile__filters-rejected-reports.active {
        border-bottom: solid 5px $black;
      }
    }
  }
}
