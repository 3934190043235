@import "../../../theme/font";
@import "../../../theme/text";
@import "../../../theme/colorVars";

// This file is loaded into every required SASS module.
// Do not include anything in this file that will be actually rendered in CSS, because it will be added to every imported SASS file.
// Add page-level styling to /src/index.scss

// Theme Colors
$primary-color: $veritone-blue;
$secondary-color: $veritone-blue-faded;

// Text
$text-primary: $black;

// Fonts
$font-stack-primary: roboto, sans-serif; // Set globally

// Z-index levels
$dialog-z: 200;
$drawer-clickoff-z: 149;
$drawer-z: 150;
$nav-clickoff-z: 199;
$nav-z: 200;
$snack-z: 9999999999999;

// Shared layout dimensions
$header-height: 55px;
$drawer-width: 321px;
$drawer-width-minimal: 80px;
$min-content-box-width: 500px;
$default-combo-box-search-width: 300px;
$people-form-height: 280px;
$default-left-cell-width: 220px;

// Box Shadows
$shadow-level-0: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
$shadow-level-2: 0 3px 4px 0 rgba(0, 0, 0, 0.24);
$shadow-level-3: 0 3px 8px 0 rgba(0, 0, 0, 0.03);
$shadow-level-3-5: 0 3px 8px 0 rgba(0, 0, 0, 0.3);
$shadow-level-4: 0 4px 24px 0 rgba(0, 0, 0, 0.1);

// Transitions
$drawer-transition: 300ms;

// App vars
$person-tab-width: 147;
$snackHeight: 96;
$snackMargin: 8;

// Export to JS
:export {
  /* stylelint-disable */

  primaryColor: $primary-color;
  secondaryColor: $secondary-color;
  inputBorder: $input-border;
  grey4: $grey-4;
  blueDisabled: $blue-disabled;
  personTabWidth: $person-tab-width;
  snackHeight: $snackHeight;
  snackMargin: $snackMargin;
  headerHeight: $header-height;
  popperBackground: $popper-background;
  fontColor: $font-color;

  /* stylelint-enable */
}

.confirm-dialog {
  width: 498px;
  position: relative;
  padding-bottom: 110px;

  &.no-actions {
    padding-bottom: 0;
  }

  .confirm-dialog__header {
    background-color: $secondary-color;
    position: relative;
    padding: 0 30px;

    h3 {
      margin: 0;
      line-height: 80px;
      text-transform: uppercase;
      color: $primary-color;

      @include size-7-bold;
    }
    i {
      line-height: 80px;
      top: 0;
      right: 26px;
      position: absolute;
      color: $primary-color;
      font-size: 30px;
      font-weight: 900;
      user-select: none;
      cursor: pointer;
    }
  }
  .confirm-dialog__content {
    padding: 30px;
    height: calc(100% - 80px);
    overflow-y: auto;
  }
  p {
    margin: 32px 8px 0 8px;
    font-size: 14px;
  }
  .confirm-dialog__button-row {
    padding: 30px;
    background-color: $grey-4;
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .confirm-dialog__no {
      margin-right: 30px;
    }
    .confirm-dialog__yes,
    .confirm-dialog__no {
      text-transform: uppercase;

      @include size-2-bold;
    }
  }
}

.xs {
  .confirm-dialog {
    width: 100%;
    padding-bottom: 90px;

    &__header {
      padding: 0 20px;

      h3 {
        line-height: 64px;

        @include size-7-bold;
      }
      i {
        line-height: 64px;
      }
    }
  }
  &__content {
    height: calc(100% - 65px);
    padding: 20px;
  }
  &__button-row {
    padding: 20px;

    .button__text {
      text-align: center;
      line-height: 16px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.dark-mode {
  .confirm-dialog {
    .confirm-dialog__button-row {
      .confirm-dialog__no {
        color: $black;
      }
      .confirm-dialog__yes {
        color: $black;
      }
    }
  }
}
