/* These scss vars are made available to all Scss files! */

// Brand
$veritone-blue: var(--veritone-blue);
$veritone-blue-dark: var(--veritone-blue-dark);
$veritone-blue-faded: var(--veritone-blue-faded);
$veritone-blue-faded-2: var(--veritone-blue-faded-2);
$veritone-blue-faded-3: var(--veritone-blue-faded-3);
$veritone-blue-faded-4: var(--veritone-blue-faded-4);
$veritone-blue-faded-5: var(--veritone-blue-faded-5);
$veritone-blue-faded-6: var(--veritone-blue-faded-6);
$veritone-blue-select-box-background: var(--veritone-blue-select-box-background);
$popper-background: var(--popper-background);

// Grey
$grey-a: var(--grey-a);
$grey-b: var(--grey-b);
$grey-c: var(--grey-c);
$grey-4: var(--grey-4);
$grey-9: var(--grey-9);
$grey-ae: var(--grey-ae);
$grey-f9: var(--grey-f9);
$grey-text: var(--grey-text);
$dark-grey: var(--dark-grey);
$blueish-grey: var(--blueish-grey);

// Fonts
$font-color: var(--font-color);
$review-grey: var(--review-grey);

// Other
$white: var(--white);
$black: var(--black);
$blue-disabled: var(--blue-disabled);
$blue-border: var(--blue-border);
$blue-icon: var(--blue-icon);
$input-border: var(--input-border);
$filter-border: var(--filter-border);
$text-disabled: var(--text-disabled);
$text-terms: var(--text-terms);
$step-graph-disabled: var(--step-graph-disabled);
$close-button: var(--close-button);
$login-bg: var(--login-bg);
$error: var(--error);
$table-border: var(--table-border);
$number-white: var(--number-white);
$number-green: var(--number-green);
$number-orange: var(--number-orange);
$number-orange-faded: var(--number-orange-faded);
$number-red: var(--number-red);
$star: var(--star);
$scrollbar-thumb: var(--scrollbar-thumb);
$scrollbar-thumb-hover: var(--scrollbar-thumb-hover);
$scrollbar-thumb-track: var(--scrollbar-thumb-track);
$card-border: var(--card-border);
$bg-disabled: var(--bg-disabled);
$bg-disabled-inv: var(--bg-disabled-inv);
$expand-disabled: var(--expand-disabled);
$expand-enabled: var(--expand-enabled);
$expand-all-enabled: var(--expand-all-enabled);
$officer-role: var(--officer-role);
$reviewer-role: var(--reviewer-role);
$admin-role: var(--admin-role);
$org-role: var(--org-role);
$analyst-role: var(--analyst-role);
$nav-divider: var(--nav-divider);
$row-error: var(--row-error);
$row-warning: var(--row-warning);
$row-syncing: var(--row-syncing);
$alert-bg: var(--alert-bg);
$radio-hover: var(--radio-hover);

// Snackbar
$red-60: var(--red-60);
$red-50: var(--red-50);
$teal-50: var(--teal-50);
$teal-60: var(--teal-60);
$green-60: var(--green-60);
$green-20: var(--green-20);
$green-30: var(--green-30);
$yellow-40: var(--yellow-40);
$yellow-80: var(--yellow-80);
$grey-60: var(--grey-60);
