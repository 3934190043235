@import "../../../theme/font";
@import "../../../theme/text";
@import "../../../theme/colorVars";

// This file is loaded into every required SASS module.
// Do not include anything in this file that will be actually rendered in CSS, because it will be added to every imported SASS file.
// Add page-level styling to /src/index.scss

// Theme Colors
$primary-color: $veritone-blue;
$secondary-color: $veritone-blue-faded;

// Text
$text-primary: $black;

// Fonts
$font-stack-primary: roboto, sans-serif; // Set globally

// Z-index levels
$dialog-z: 200;
$drawer-clickoff-z: 149;
$drawer-z: 150;
$nav-clickoff-z: 199;
$nav-z: 200;
$snack-z: 9999999999999;

// Shared layout dimensions
$header-height: 55px;
$drawer-width: 321px;
$drawer-width-minimal: 80px;
$min-content-box-width: 500px;
$default-combo-box-search-width: 300px;
$people-form-height: 280px;
$default-left-cell-width: 220px;

// Box Shadows
$shadow-level-0: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
$shadow-level-2: 0 3px 4px 0 rgba(0, 0, 0, 0.24);
$shadow-level-3: 0 3px 8px 0 rgba(0, 0, 0, 0.03);
$shadow-level-3-5: 0 3px 8px 0 rgba(0, 0, 0, 0.3);
$shadow-level-4: 0 4px 24px 0 rgba(0, 0, 0, 0.1);

// Transitions
$drawer-transition: 300ms;

// App vars
$person-tab-width: 147;
$snackHeight: 96;
$snackMargin: 8;

// Export to JS
:export {
  /* stylelint-disable */

  primaryColor: $primary-color;
  secondaryColor: $secondary-color;
  inputBorder: $input-border;
  grey4: $grey-4;
  blueDisabled: $blue-disabled;
  personTabWidth: $person-tab-width;
  snackHeight: $snackHeight;
  snackMargin: $snackMargin;
  headerHeight: $header-height;
  popperBackground: $popper-background;
  fontColor: $font-color;

  /* stylelint-enable */
}

.ripa-action-taken-form {
  padding-bottom: 40px;

  .ripa-action-taken-form__person {
    position: relative;
    height: 70px;
    background-color: $secondary-color;
    padding: 0 100px 0 30px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    align-items: center;

    &.same-for-all {
      .material-icons {
        color: $bg-disabled;
      }
      .ripa-action-taken-form__person-label {
        display: none;
      }
      .ripa-action-taken-form__person-same-for-all {
        display: initial;
      }
      .ripa-action-taken-form__person-progression {
        display: none;
      }
      .ripa-action-taken-form__person-progression-all {
        display: initial;
      }
    }
    .ripa-action-taken-form__person-progression-all {
      display: none;
      position: absolute;
      right: 0;
      top: 0;
      width: 70px;
      height: 100%;
      color: $white;
      background-color: $bg-disabled;
      line-height: 70px;
      text-align: center;

      @include size-3-bold;
    }
    .ripa-action-taken-form__person-same-for-all {
      display: none;
      margin-left: 5px;
      color: $bg-disabled;

      @include size-3-bold;
    }
    .material-icons {
      font-size: 35px;
      color: $primary-color;
      margin-top: -3px;
    }
    .ripa-action-taken-form__person-label {
      color: $primary-color;
      margin-left: 5px;

      @include size-3-bold;
    }
    .ripa-action-taken-form__person-progression {
      position: absolute;
      right: 0;
      top: 0;
      width: 70px;
      height: 100%;
      color: $white;
      background-color: $veritone-blue-faded-5;
      line-height: 70px;
      text-align: center;

      @include size-1;

      b {
        @include size-3-bold;
      }
    }
  }

  .ripa-action-taken-form__title {
    padding: 30px 20px 0;
    width: 100%;
    text-align: center;

    @include size-7-bold;
  }

  .ripa-action-taken-form__switch-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    margin: 25px 0 5px;

    @include size-1;

    .ripa-action-taken-form__action-taken-switch-label {
      margin: 0 10px;
    }
    .ripa-action-taken-form__use-same-switch-label {
      margin: 0 10px;
      text-align: right;
    }
    .ripa-action-taken-form__action-taken-switch-container,
    .ripa-action-taken-form__use-same-switch-container {
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }
  }
  .ripa-action-taken-form__action-dropdown {
    display: none;
  }

  .ripa-action-taken-form__tab-container {
    display: flex;
    justify-content: center;
    align-items: center;

    .ripa-action-taken-form__action-tab {
      padding: 0 5.5px;
      color: $blue-disabled;
      border-bottom: solid 4px $blue-disabled;
      line-height: 46px;
      cursor: pointer;
      user-select: none;

      &.active {
        color: $primary-color;
        border-bottom: solid 4px $primary-color;

        @include size-2-bold;
      }

      @include size-2;
    }
  }
  .ripa-action-taken-form__select-box-content {
    display: flex;
    flex-wrap: wrap;
    padding: 30px 30px 10px;
    justify-content: space-between;

    .ripa-action-taken-form__select-box {
      display: inline-flex;
      position: relative;
      min-height: 55px;
      width: 100%;
      margin: 0 0 20px 0;
      padding: 5px 20px;
      justify-content: center;
      align-items: center;
      text-align: center;
      border-radius: 3px;
      background-color: $veritone-blue-faded;
      border: solid 2px $veritone-blue;
      color: $primary-color;
      cursor: pointer;
      user-select: none;

      @include size-2-bold;

      p {
        margin: 0;
        font-weight: 100;
      }
      fieldset {
        border-color: transparent;
      }
      &.active {
        background-color: $veritone-blue-faded-3;
        color: $white;
      }
      &.disable {
        color: $black;
        opacity: 0.7;
        cursor: default;
      }
      &.has-consent {
        width: 50%;
      }
      &.highlight-2024 {
        border: solid 2px $number-orange;
        color: $number-orange;
        padding-right: 70px;
        background: $row-warning;

        &.active {
          color: $white;
          background: $number-orange-faded;

          .ripa-action-taken-form__select-box-2024 {
            color: $white;

            @include size-2;
          }
        }
      }
      .ripa-action-taken-form__select-box-2024 {
        position: absolute;
        display: flex;
        right: 0;
        top: 0;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 50px;
        padding-left: 2px;
        background-color: $number-orange;
        color: $white;
        text-align: center;

        @include size-2;
      }
    }

    .ripa-action-taken-form__consent-container {
      display: none;
      justify-content: right;

      &.has-consent {
        display: inline-flex;
        align-items: center;
        height: 50px;

        .ripa-action-taken-form__consent-label {
          margin-right: 15px;
          line-height: 24px;

          @include size-1;
        }
      }
      &.disabled {
        color: $blue-disabled;
      }
    }
  }

  .ripa-action-taken-form__person-tab-container {
    position: relative;
    height: 90px;
    background-color: $secondary-color;
    overflow: hidden;

    .ripa-action-taken-form__person-tab-next,
    .ripa-action-taken-form__person-tab-back {
      display: none;
      position: absolute;
      left: 0;
      height: 100%;
      width: 29px;
      user-select: none;
      line-height: 90px;
      cursor: pointer;
      z-index: 1;
      color: $white;
      text-align: center;
      font-size: 30px;

      &.disabled {
        display: initial;
        background-color: $blue-disabled;
        opacity: 0.7;
        pointer-events: none;
      }
      &.available {
        display: initial;
        background-color: $primary-color;
        opacity: 0.7;
      }
    }
    &.no-carousel {
      display: flex;
      justify-content: center;
      align-items: center;

      .ripa-action-taken-form__person-tab-next,
      .ripa-action-taken-form__person-tab-back {
        &.available {
          display: none;
        }
      }
    }
    .ripa-action-taken-form__person-tab-next {
      right: 0;
      left: initial;
    }

    .ripa-action-taken-form__person-tab {
      position: absolute;
      height: 100%;
      width: #{$person-tab-width}px;
      text-align: center;
      transform: translateX(29px);
      z-index: 0;
      transition: left 100ms;
      user-select: none;
      cursor: pointer;

      &.no-carousel {
        position: initial;
        transform: initial;
      }

      &:not(.active) {
        .ripa-action-taken-form__person-tab-index {
          color: $blue-disabled;

          .ripa-action-taken-form__person-tab-number {
            @include size-2;
          }
        }
        .ripa-action-taken-form__person-tab-label {
          @include size-2;

          color: $blue-disabled;
        }
      }

      .ripa-action-taken-form__person-tab-index {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        color: $primary-color;
        padding: 15px 30px 0;

        .material-icons {
          font-size: 25px;
        }
        .ripa-action-taken-form__person-tab-number {
          @include size-2-bold;

          .ripa-action-taken-form__person-tab-number-total {
            display: none;
          }
        }
      }
      .ripa-action-taken-form__person-tab-label {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 0 8px;
        height: 50px;
        margin-top: -10px;
        color: $primary-color;

        @include size-2-bold;
      }
    }
  }

  .ripa-action-taken-form__no-action-text {
    @include size-7;

    text-align: center;
    margin: 43px 0;
    color: $primary-color;
  }
}

.xs {
  .ripa-action-taken-form {
    min-width: initial;

    .ripa-action-taken-form__action-dropdown {
      display: block;
      width: calc(100% - 60px);
      margin: 30px 30px 0;

      > div {
        width: 100%;
      }
    }
    .ripa-action-taken-form__action-tab {
      display: none;
    }
    .ripa-action-taken-form__person-tab-container {
      .ripa-action-taken-form__person-tab {
        transform: translateX(40px);

        .ripa-action-taken-form__person-tab-index {
          align-items: flex-end;

          .material-icons {
            margin-bottom: -2px;
          }
          .ripa-action-taken-form__person-tab-number {
            display: none;
            flex-wrap: wrap;
            justify-content: center;
            align-items: flex-end;

            @include size-1;

            font-weight: 600;

            &.active {
              display: flex;
            }

            .ripa-action-taken-form__person-tab-number-total {
              display: flex;
              font-weight: 100;
            }
          }
        }

        &.no-carousel {
          transform: initial;
        }
      }
      .ripa-action-taken-form__person-tab-next,
      .ripa-action-taken-form__person-tab-back {
        width: 40px;
      }
    }

    .ripa-action-taken-form__consent-container.has-consent {
      height: 100%;
      width: 50%;
      justify-content: flex-end;

      .ripa-action-taken-form__consent-label {
        line-height: initial;
        text-align: right;
      }
    }
  }
}

.dark-mode {
  .ripa-action-taken-form {
    .ripa-action-taken-form__person {
      .ripa-action-taken-form__person-progression-all {
        color: $black;
      }
      .ripa-action-taken-form__person-progression {
        color: $black;
      }
    }
    .ripa-action-taken-form__select-box-content {
      .ripa-action-taken-form__select-box {
        &.active {
          background-color: $veritone-blue-select-box-background;
          color: $black;
        }
        &.disable {
          color: $grey-text;
          opacity: 0.7;
          cursor: default;
        }
        &.highlight-2024 {
          &.active {
            background-color: $number-orange-faded;
            color: $black;

            .ripa-action-taken-form__select-box-2024 {
              color: $black;
            }
          }
        }
      }
    }
  }
}
