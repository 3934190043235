@import "../../../theme/font";
@import "../../../theme/text";
@import "../../../theme/colorVars";

// This file is loaded into every required SASS module.
// Do not include anything in this file that will be actually rendered in CSS, because it will be added to every imported SASS file.
// Add page-level styling to /src/index.scss

// Theme Colors
$primary-color: $veritone-blue;
$secondary-color: $veritone-blue-faded;

// Text
$text-primary: $black;

// Fonts
$font-stack-primary: roboto, sans-serif; // Set globally

// Z-index levels
$dialog-z: 200;
$drawer-clickoff-z: 149;
$drawer-z: 150;
$nav-clickoff-z: 199;
$nav-z: 200;
$snack-z: 9999999999999;

// Shared layout dimensions
$header-height: 55px;
$drawer-width: 321px;
$drawer-width-minimal: 80px;
$min-content-box-width: 500px;
$default-combo-box-search-width: 300px;
$people-form-height: 280px;
$default-left-cell-width: 220px;

// Box Shadows
$shadow-level-0: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
$shadow-level-2: 0 3px 4px 0 rgba(0, 0, 0, 0.24);
$shadow-level-3: 0 3px 8px 0 rgba(0, 0, 0, 0.03);
$shadow-level-3-5: 0 3px 8px 0 rgba(0, 0, 0, 0.3);
$shadow-level-4: 0 4px 24px 0 rgba(0, 0, 0, 0.1);

// Transitions
$drawer-transition: 300ms;

// App vars
$person-tab-width: 147;
$snackHeight: 96;
$snackMargin: 8;

// Export to JS
:export {
  /* stylelint-disable */

  primaryColor: $primary-color;
  secondaryColor: $secondary-color;
  inputBorder: $input-border;
  grey4: $grey-4;
  blueDisabled: $blue-disabled;
  personTabWidth: $person-tab-width;
  snackHeight: $snackHeight;
  snackMargin: $snackMargin;
  headerHeight: $header-height;
  popperBackground: $popper-background;
  fontColor: $font-color;

  /* stylelint-enable */
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

.ripa-location-form {
  height: 100%;
  padding-bottom: 80px;

  input {
    font-size: 1rem;
  }

  .ripa-location-form__switch-mobile {
    width: 0;
  }

  .ripa-location-form__title {
    padding: 30px 30px 0;
    width: 100%;
    text-align: center;

    @include size-7-bold;
  }
  .ripa-location-form__switch-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 30px;
  }
  .ripa-location-form__switch {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &.school {
      &.no-school-switch {
        .ripa-location-form__switch-mobile,
        .ripa-location-form__school-picker-toggle-switch,
        .ripa-location-form__school-switch-text {
          display: none;
        }
      }
    }
    &.unavailable {
      .ripa-location-form__current-loc-switch-text {
        width: 84px;
      }
    }
    &.disabled {
      color: $blue-disabled;

      .ripa-location-form__current-loc-switch-text {
        color: $blue-disabled;
      }
    }
    &.waiting {
      .ripa-location-form__current-loc-switch-text {
        width: 110px;
      }
    }
    &.searching {
      .ripa-location-form__current-loc-switch-text {
        margin: 0 2px 0 0;
        text-align: left;
        width: 148px;

        &::after {
          overflow: hidden;
          display: inline-block;
          vertical-align: bottom;
          -webkit-animation: ellipsis steps(4, end) 1200ms infinite;
          animation: ellipsis steps(4, end) 1200ms infinite;
          content: "\2026";
          width: 0;
        }
      }
    }
  }
  .ripa-location-form__school-picker-toggle-switch,
  .ripa-location-form__location-picker-toggle-switch {
    display: inline-flex;
  }
  .ripa-location-form__current-loc-line {
    display: inline-flex;
    width: 90px;
    height: 3px;
    margin: 0 7px;
    background-color: $secondary-color;
  }
  .ripa-location-form__current-loc-switch-text {
    margin: 0 5px 0 0;
    text-align: right;
    width: 125px;
    color: $primary-color;

    @include size-1;
  }
  .ripa-location-form__school-switch-text {
    display: inline-flex;
    margin: 0 0 0 7px;
    text-align: left;
    width: 130px;
    color: $primary-color;

    @include size-1;
  }
  .ripa-location-form__address-input {
    width: calc(100% - 60px);
    margin: 0 30px 30px;

    .material-icons {
      margin: 0 12px 0 6px;
      font-size: 24px;
      color: $primary-color;

      &.disabled {
        color: $blue-disabled;
      }
    }
  }

  .ripa-location-form__city-autocomplete,
  .ripa-location-form__school-autocomplete {
    width: calc(100% - 60px);
    margin: 0 30px 25px;

    .material-icons {
      margin: 0 12px 0 6px;
      font-size: 24px;
      color: $primary-color;
    }
  }

  .ripa-location-form__address-input-current-loc,
  .ripa-location-form__address-input-school,
  .ripa-location-form__address-input-city {
    width: 55px;
    white-space: normal;
    text-align: center;
    line-height: 12px;
    color: $blue-disabled;
    user-select: none;
    padding-left: 35px;

    @include size-0-bold;

    &.disabled {
      color: $blue-disabled;
    }
    &.active {
      color: $primary-color;

      @include size-0-bold;
    }
  }

  .ripa-location-form__address-input-city {
    padding-left: 45px;
  }

  .ripa-location-form__address-input-city-end-adornment-default,
  .ripa-location-form__address-input-school-end-adornment-default {
    position: absolute;
    right: 44px;

    .MuiAutocomplete-endAdornment {
      display: flex;
      flex-direction: row;
    }
  }

  .ripa-location-form__address-input-city-end-adornment-city,
  .ripa-location-form__address-input-school-end-adornment-school {
    transform: translateX(34px);
  }

  .ripa-location-form__address-input-current-loc:hover {
    color: $primary-color;
    cursor: pointer;
  }
}

.ripa-location-form__city-autocomplete-option-inactive {
  text-decoration: line-through;
  color: $blue-disabled;
}

.xs {
  .ripa-location-form {
    padding-bottom: 100px;
    overflow-y: auto;

    input {
      font-size: 1rem;
    }

    .ripa-location-form__switch-container {
      flex-direction: column-reverse;

      .ripa-location-form__switch {
        display: flex;
        width: 100%;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          z-index: 1;
          left: 0;
          right: 0;
          top: 47%;
          border-top: 3px solid #e5ebf2;
        }
        &.school {
          flex-direction: row-reverse;
          margin-top: 20px;
        }

        .ripa-location-form__current-loc-switch-text {
          width: 140px;
          display: flex;
          justify-content: center;
          padding: 0 5px;
          z-index: 2;
          background-color: #fbfbfb;
          margin: 0;
        }
        .ripa-location-form__school-switch-text {
          display: flex;
          justify-content: center;
          text-align: center;
          width: 140px;
          padding: 0 5px;
          z-index: 2;
          background-color: #fbfbfb;
          margin: 0;
        }
        .ripa-location-form__location-picker-toggle-switch {
          z-index: 2;
        }
        .ripa-location-form__school-picker-toggle-switch {
          z-index: 2;
        }
        .ripa-location-form__switch-mobile {
          width: 10px;
          background-color: #fbfbfb;
          height: 5px;
          z-index: 2;
        }
      }
    }
  }

  .ripa-location-form__current-loc {
    min-width: initial;

    .ripa-location-form__current-loc-line {
      width: 60px;
    }
  }
  .ripa-location-form__address-input {
    margin-bottom: 30px;
  }
}

.dark-mode {
  .ripa-location-form {
    ::placeholder {
      color: $primary-color;
      opacity: 0.7;
    }
  }
  .xs {
    .ripa-location-form {
      .ripa-location-form__switch-container
      .ripa-location-form__switch
      .ripa-location-form__school-picker-toggle-switch,
      .ripa-location-form__switch-container .ripa-location-form__switch .ripa-location-form__school-switch-text,
      .ripa-location-form__switch-container
      .ripa-location-form__switch
      .ripa-location-form__location-picker-toggle-switch,
      .ripa-location-form__switch-container .ripa-location-form__switch .ripa-location-form__current-loc-switch-text,
      .ripa-location-form__switch-container .ripa-location-form__switch .ripa-location-form__switch-mobile {
        background-color: $grey-b;
      }
      .ripa-location-form__switch-container .ripa-location-form__switch::after {
        border-top: 3px solid $veritone-blue-faded;
      }
    }
  }
}

$school-arrow-size: 10px;
.MuiAutocomplete-option.school {
  position: relative;

  [data-status]::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: 50%;
    transform: translate(0, -50%);
    border-top: $school-arrow-size solid transparent;
    border-bottom: $school-arrow-size solid transparent;
  }
  [data-status="Closed"]::after {
    border-left: $school-arrow-size solid red;
    left: 0;
  }
}

.MuiAutocomplete-listbox li[aria-disabled="true"].school {
  pointer-events: inherit;
}
.MuiAutocomplete-listbox li[aria-disabled="true"].school:active {
  background: white;
  pointer-events: none;
}
