@import "../../../theme/font";
@import "../../../theme/text";
@import "../../../theme/colorVars";

// This file is loaded into every required SASS module.
// Do not include anything in this file that will be actually rendered in CSS, because it will be added to every imported SASS file.
// Add page-level styling to /src/index.scss

// Theme Colors
$primary-color: $veritone-blue;
$secondary-color: $veritone-blue-faded;

// Text
$text-primary: $black;

// Fonts
$font-stack-primary: roboto, sans-serif; // Set globally

// Z-index levels
$dialog-z: 200;
$drawer-clickoff-z: 149;
$drawer-z: 150;
$nav-clickoff-z: 199;
$nav-z: 200;
$snack-z: 9999999999999;

// Shared layout dimensions
$header-height: 55px;
$drawer-width: 321px;
$drawer-width-minimal: 80px;
$min-content-box-width: 500px;
$default-combo-box-search-width: 300px;
$people-form-height: 280px;
$default-left-cell-width: 220px;

// Box Shadows
$shadow-level-0: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
$shadow-level-2: 0 3px 4px 0 rgba(0, 0, 0, 0.24);
$shadow-level-3: 0 3px 8px 0 rgba(0, 0, 0, 0.03);
$shadow-level-3-5: 0 3px 8px 0 rgba(0, 0, 0, 0.3);
$shadow-level-4: 0 4px 24px 0 rgba(0, 0, 0, 0.1);

// Transitions
$drawer-transition: 300ms;

// App vars
$person-tab-width: 147;
$snackHeight: 96;
$snackMargin: 8;

// Export to JS
:export {
  /* stylelint-disable */

  primaryColor: $primary-color;
  secondaryColor: $secondary-color;
  inputBorder: $input-border;
  grey4: $grey-4;
  blueDisabled: $blue-disabled;
  personTabWidth: $person-tab-width;
  snackHeight: $snackHeight;
  snackMargin: $snackMargin;
  headerHeight: $header-height;
  popperBackground: $popper-background;
  fontColor: $font-color;

  /* stylelint-enable */
}

.user-settings-dialog {
  .user-settings-dialog__row {
    display: flex;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .user-settings-dialog__years-of-experience-container {
    width: 50%;

    .user-settings-dialog__years-of-experience-label {
      @include size-2-bold;

      text-transform: uppercase;
      margin-bottom: 8px;
      color: $primary-color;
    }
  }

  .user-settings-dialog__years-of-experience {
    width: 100%;
    transition: transform $drawer-transition, width $drawer-transition;
    transform: translateY(0);

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type="number"] {
      -moz-appearance: textfield;
    }

    .user-settings-dialog__years-of-experience-controls {
      margin-right: 2px;

      .user-settings-dialog__years-of-experience-controls-up {
        background-color: $primary-color;
        color: $white;
        width: 30px;
        font-weight: 900;
        border-top-right-radius: 4px;
        height: 24.5px;
        text-align: center;
        line-height: 19px;
        font-size: 18px;
        cursor: pointer;
        user-select: none;

        &:hover {
          background-color: $veritone-blue-faded-5;
        }
        &:active {
          background-color: $veritone-blue-dark;
        }
      }
      .user-settings-dialog__years-of-experience-controls-down {
        background-color: $primary-color;
        width: 30px;
        color: $white;
        font-weight: 900;
        border-bottom-right-radius: 4px;
        height: 24.5px;
        text-align: center;
        line-height: 20px;
        font-size: 23px;
        margin-top: 1px;
        cursor: pointer;
        user-select: none;

        &:hover {
          background-color: $veritone-blue-faded-5;
        }
        &:active {
          background-color: $veritone-blue-dark;
        }
      }
    }
  }

  .user-settings-dialog__training-mode {
    margin-left: 25px;

    .user-settings-dialog__training-mode-label {
      @include size-2-bold;

      text-transform: uppercase;
      margin-bottom: 8px;
      color: $primary-color;
    }
    .switch {
      margin-top: 22px;
    }
    .user-settings-dialog__training-mode-disabled-text {
      margin-top: 5px;
      color: $input-border;
      text-align: left;
      display: none;

      @include size-2;

      font-style: italic;
    }
    &.is-disabled {
      .switch {
        margin-top: 12px;
      }
      .user-settings-dialog__training-mode-disabled-text {
        display: block;
      }
    }
  }
  .user-settings-dialog__hr {
    background-color: $blue-border;
    width: 100%;
    height: 2px;
    margin-bottom: 30px;
  }
  .user-settings-dialog__theme {
    width: 50%;
    padding-right: 10px;
  }
  .user-settings-dialog__gender-of-officer {
    width: 50%;
    margin-left: 12px;
  }
  .user-settings-dialog__race-of-officer {
    width: 50%;
    padding-right: 10px;
    margin-left: 25px;
  }
}

.dark-mode {
  .user-settings-dialog__years-of-experience-controls {
    .user-settings-dialog__years-of-experience-controls-down,
    .user-settings-dialog__years-of-experience-controls-up {
      &:active {
        color: $black;
        border: solid 1px $primary-color;
      }
      &:hover {
        color: $black;
        border: solid 1px $primary-color;
      }
    }
  }
}

.xs {
  .user-settings-dialog {
    .user-settings-dialog__years-of-experience-container {
      .user-settings-dialog__years-of-experience-label {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        min-height: 32px;
      }
    }
  }
}
