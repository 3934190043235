@import "../../../theme/font";
@import "../../../theme/text";
@import "../../../theme/colorVars";

// This file is loaded into every required SASS module.
// Do not include anything in this file that will be actually rendered in CSS, because it will be added to every imported SASS file.
// Add page-level styling to /src/index.scss

// Theme Colors
$primary-color: $veritone-blue;
$secondary-color: $veritone-blue-faded;

// Text
$text-primary: $black;

// Fonts
$font-stack-primary: roboto, sans-serif; // Set globally

// Z-index levels
$dialog-z: 200;
$drawer-clickoff-z: 149;
$drawer-z: 150;
$nav-clickoff-z: 199;
$nav-z: 200;
$snack-z: 9999999999999;

// Shared layout dimensions
$header-height: 55px;
$drawer-width: 321px;
$drawer-width-minimal: 80px;
$min-content-box-width: 500px;
$default-combo-box-search-width: 300px;
$people-form-height: 280px;
$default-left-cell-width: 220px;

// Box Shadows
$shadow-level-0: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
$shadow-level-2: 0 3px 4px 0 rgba(0, 0, 0, 0.24);
$shadow-level-3: 0 3px 8px 0 rgba(0, 0, 0, 0.03);
$shadow-level-3-5: 0 3px 8px 0 rgba(0, 0, 0, 0.3);
$shadow-level-4: 0 4px 24px 0 rgba(0, 0, 0, 0.1);

// Transitions
$drawer-transition: 300ms;

// App vars
$person-tab-width: 147;
$snackHeight: 96;
$snackMargin: 8;

// Export to JS
:export {
  /* stylelint-disable */

  primaryColor: $primary-color;
  secondaryColor: $secondary-color;
  inputBorder: $input-border;
  grey4: $grey-4;
  blueDisabled: $blue-disabled;
  personTabWidth: $person-tab-width;
  snackHeight: $snackHeight;
  snackMargin: $snackMargin;
  headerHeight: $header-height;
  popperBackground: $popper-background;
  fontColor: $font-color;

  /* stylelint-enable */
}

.new-report-drawer {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: $white;
  box-shadow: $shadow-level-0;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .new-report-drawer__skeleton-user-set {
    margin: 15px 20px;
  }

  .new-report-drawer__step-skeleton-cont {
    display: flex;
    margin: 0 20px 20px;

    .new-report-drawer__step-skeleton-circ {
      margin-right: 20px;
    }
    .new-report-drawer__step-skeleton-cont-sm-circ {
      display: flex;

      .new-report-drawer__step-skeleton-sm-circ {
        margin: 3px 10px 0 0;
      }
    }
  }

  .new-report-drawer__user-settings {
    overflow: hidden;
    width: calc(100% - 40px);
    min-width: 0;
    margin: 15px 20px 20px;
    transition: transform $drawer-transition, height $drawer-transition, width $drawer-transition;
    min-height: 50px;
    height: 50px;
    line-height: 48px;

    .material-icons {
      font-size: 25px;
      transform: translate(0);
      transition: line-height $drawer-transition, transform $drawer-transition;
      line-height: 48px;
    }
    .button__text {
      transition: transform $drawer-transition;
      transform: translateX(0);
    }
  }
  .new-report-drawer__heading {
    background-color: $secondary-color;
    padding: 20px 20px 0;
    color: $primary-color;
    width: $drawer-width;

    @include size-7-bold;
    .material-icons {
      // font-size: 24px;
      font-size: 30px;
      line-height: 26px;
      color: $close-button;
      float: right;
      font-weight: 900;
      cursor: pointer;
    }
  }
  .new-report-drawer__info {
    width: $drawer-width;
    padding: 26px 0;
    background-color: $secondary-color;
    border-bottom: solid 1px $blue-border;
    transition: transform $drawer-transition, width $drawer-transition;

    .new-report-drawer__info-suspects {
      padding-top: 28px;
    }
    .new-report-drawer__info-location {
      padding-top: 31px;

      &.has-value {
        opacity: 1;
      }
    }
    .new-report-drawer__info-stop-id-title,
    .new-report-drawer__info-suspects-title,
    .new-report-drawer__info-location-title {
      display: inline-block;
      color: $primary-color;
      line-height: 19px;

      @include size-2-bold;
    }
    .new-report-drawer__info-stop-id-id,
    .new-report-drawer__info-suspects-num {
      line-height: 19px;
      float: right;
      padding: 0 20px;
      color: $primary-color;

      @include size-2;
    }
    .new-report-drawer__info-location-section {
      display: inline-block;
      width: calc(100% - 100px);

      .new-report-drawer__info-location-address-1 {
        margin-top: 8px;
      }
      .new-report-drawer__info-location-address-1,
      .new-report-drawer__info-location-address-2 {
        @include size-2;
      }
    }
    .material-icons {
      float: left;
      line-height: 19px;
      color: $primary-color;
      margin: 0 32px;
    }
  }
  .new-report-drawer__years-of-experience,
  .new-report-drawer__other-assignment,
  .new-report-drawer__assignment-dropdown {
    margin: 20px 20px 0;
    width: calc(#{$drawer-width} - 40px);
    transition: transform $drawer-transition, width $drawer-transition;
    transform: translateY(0);

    .MuiInputBase-input {
      padding: 15.5px 14px;
    }
    .MuiInputLabel-outlined {
      transform: translate(14px, 17px) scale(1);

      &.MuiInputLabel-shrink {
        transform: translate(14px, -6px) scale(0.75);
      }
    }
  }
  .new-report-drawer__years-of-experience {
    margin: 20px;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type="number"] {
      -moz-appearance: textfield;
    }

    .new-report-drawer__years-of-experience-controls {
      margin-right: 2px;

      .new-report-drawer__years-of-experience-controls-up {
        background-color: $primary-color;
        color: $white;
        width: 30px;
        font-weight: 900;
        border-top-right-radius: 4px;
        height: 24.5px;
        text-align: center;
        line-height: 19px;
        font-size: 18px;
        cursor: pointer;
        user-select: none;

        &:hover {
          background-color: $veritone-blue-faded-5;
        }
        &:active {
          background-color: $veritone-blue-dark;
        }
      }
      .new-report-drawer__years-of-experience-controls-down {
        background-color: $primary-color;
        width: 30px;
        color: $white;
        font-weight: 900;
        border-bottom-right-radius: 4px;
        height: 24.5px;
        text-align: center;
        line-height: 20px;
        font-size: 23px;
        margin-top: 1px;
        cursor: pointer;
        user-select: none;

        &:hover {
          background-color: $veritone-blue-faded-5;
        }
        &:active {
          background-color: $veritone-blue-dark;
        }
      }
    }
  }
  .new-report-drawer__other-assignment {
    margin: 20px 20px 0;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type="number"] {
      -moz-appearance: textfield;
    }

    ::placeholder {
      @include size-2;

      font-style: italic;
      color: $input-border;
    }

    .MuiInputBase-input {
      text-overflow: ellipsis;
    }

    .new-report-drawer__other-assignment-controls {
      margin-right: 10px;

      .new-report-drawer__other-assignment-back {
        background-color: $primary-color;
        color: $white;
        width: 20px;
        height: 20px;
        margin: 2.5px 0 0 43px;
        font-weight: 900;
        border-radius: 50%;
        text-align: center;
        line-height: 20px;
        font-size: 16px;
        cursor: pointer;
        user-select: none;

        &:hover {
          background-color: $veritone-blue-faded-5;
        }
        &:active {
          background-color: $veritone-blue-dark;
        }
      }
    }
  }

  .new-report-drawer__steps {
    transition: transform $drawer-transition, width $drawer-transition;
    transform: translateY(0);
    width: $drawer-width;
    flex-shrink: 1;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .new-report-drawer__step {
    display: flex;
    position: relative;

    .new-report-drawer__step-selected-border {
      position: absolute;
      right: 0;
      width: 4px;
      height: 40px;
      background-color: $primary-color;
    }
    .new-report-drawer__step-graph {
      user-select: none;

      .new-report-drawer__step-graph-circle {
        display: flex;
        height: 40px;
        width: 40px;
        margin: 0 20px;
        border: solid 2px $primary-color;
        border-radius: 50%;
        color: $primary-color;
        align-items: center;
        justify-content: center;

        .material-icons {
          display: none;
        }

        @include size-4-bold;
      }
      .new-report-drawer__step-graph-circle:hover {
        cursor: pointer;
      }
      .new-report-drawer__step-graph-line {
        width: 4px;
        height: 20px;
        margin: 0 23px 0 38px;
        background-color: $step-graph-disabled;
      }
    }
    .new-report-drawer__step-detail {
      height: 40px;
      width: calc(#{$drawer-width} - 80px);

      .new-report-drawer__step-detail-title {
        color: $primary-color;
        text-transform: uppercase;
        line-height: 19px;

        @include size-2-bold;
      }
      .new-report-drawer__step-detail-bubble {
        float: left;
        height: 10px;
        width: 10px;
        margin-top: 3px;
        margin-right: 10px;
        border-radius: 50%;
        background-color: transparent;
        border: solid 1px $primary-color;

        &.done {
          background-color: $primary-color;
        }
      }

      .new-report-drawer__step-detail-bubble:hover {
        cursor: pointer;
      }
    }

    &.done {
      .new-report-drawer__step-selected-border {
        display: none;
      }
      .new-report-drawer__step-graph-circle {
        background-color: $primary-color;

        .new-report-drawer__step-graph-circle-num {
          display: none;
        }
        .material-icons {
          display: initial;
          font-weight: bold;
          color: $white;
        }
      }
      .new-report-drawer__step-detail {
        border-right: none;

        .new-report-drawer__step-detail-title {
          line-height: 40px;
          color: $text-disabled;
        }
        .new-report-drawer__step-detail-bubble {
          display: none;
        }
      }

      .new-report-drawer__step-graph-line {
        background-color: $primary-color;
      }
    }

    &.future {
      .new-report-drawer__step-selected-border {
        display: none;
      }
      .new-report-drawer__step-graph-circle {
        border: solid 2px $step-graph-disabled;

        .new-report-drawer__step-graph-circle-num {
          color: $text-disabled;
        }
      }
      .new-report-drawer__step-detail {
        border-right: none;

        .new-report-drawer__step-detail-title {
          line-height: 40px;
          color: $text-disabled;
        }
        .new-report-drawer__step-detail-bubble {
          display: none;
        }
      }
    }

    &:last-child {
      .new-report-drawer__step-graph-line {
        display: none;
      }
    }
  }

  .new-report-drawer__bottom {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    padding: 20px;
    background-color: $white;
  }
  .new-report-drawer__start-over-btn {
    overflow: hidden;
    width: 100%;
    min-width: 0;
    transition: transform $drawer-transition, height $drawer-transition, width $drawer-transition;
    height: 50px;
    line-height: 48px;

    .material-icons {
      font-size: 25px;
      transform: translate(0);
      transition: line-height $drawer-transition, transform $drawer-transition;
      line-height: 48px;
    }
    .button__text {
      transition: transform $drawer-transition;
      transform: translateX(0);
    }
  }
  .new-report-drawer__save-btn {
    overflow: hidden;
    width: 100%;
    min-width: 0;
    margin-top: 15px;
    transition: transform $drawer-transition, height $drawer-transition, width $drawer-transition;
    height: 50px;
    line-height: 50px;

    .material-icons {
      font-size: 25px;
      transform: translateX(0);
      transition: line-height $drawer-transition, transform $drawer-transition;
      line-height: 48px;
    }
    .button__text {
      transform: translateX(0);
      transition: transform $drawer-transition;
    }
  }
}

.lg {
  .new-report-drawer__heading {
    display: none;
  }
  .drawer-closed {
    .new-report-drawer {
      .new-report-drawer__heading {
        display: none;
      }
      .new-report-drawer__info {
        transform: translateY(-100%);
      }
      .new-report-drawer__user-settings,
      .new-report-drawer__years-of-experience,
      .new-report-drawer__assignment-dropdown,
      .new-report-drawer__other-assignment,
      .new-report-drawer__steps {
        width: $drawer-width-minimal;
        transform: translateY(-345px);
        padding-top: 5px;
      }
      .new-report-drawer__start-over-btn {
        height: 40px;
        width: 40px;

        .material-icons {
          transform: translate(26px);
          line-height: 40px;
        }
        .button__text {
          transform: translate(50px);
        }
      }
      .new-report-drawer__save-btn {
        height: 40px;
        width: 40px;

        .material-icons {
          transform: translateX(28px);
          line-height: 40px;
        }
        .button__text {
          transform: translateX(50px);
        }
      }
    }
  }
}

.xs {
  .new-report-drawer__info {
    padding: 20px 0;

    .new-report-drawer__info-suspects,
    .new-report-drawer__info-location {
      padding-top: 20px;
    }
  }

  .new-report-drawer__years-of-experience {
    display: none;
  }

  .new-report-drawer__bottom {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;

    .new-report-drawer__save-btn {
      margin-top: 0;
      width: 45%;
    }

    .new-report-drawer__start-over-btn {
      width: 45%;
    }

    .button__text {
      display: none;
    }
  }
}

.dark-mode {
  .new-report-drawer {
    .new-report-drawer__years-of-experience {
      .new-report-drawer__years-of-experience-controls {
        margin-right: 2px;

        .new-report-drawer__years-of-experience-controls-up {
          background-color: $primary-color;
          color: $black;

          &:hover {
            background-color: transparent;
            border: solid 1px $primary-color;
            color: $black;
          }
          &:active {
            background-color: $veritone-blue-dark;
            border: solid 1px $primary-color;
            color: $black;
          }
        }
        .new-report-drawer__years-of-experience-controls-down {
          background-color: $primary-color;
          width: 30px;
          color: $black;

          &:hover {
            background-color: transparent;
            border: solid 1px $primary-color;
            color: $black;
          }
          &:active {
            background-color: $veritone-blue-dark;
            border: solid 1px $primary-color;
            color: $black;
          }
        }
      }
    }

    .new-report-drawer__other-assignment {
      .MuiInputLabel-root {
        color: $font-color;
      }
    }
  }
}
