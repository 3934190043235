@import "../../../theme/font";
@import "../../../theme/text";
@import "../../../theme/colorVars";

// This file is loaded into every required SASS module.
// Do not include anything in this file that will be actually rendered in CSS, because it will be added to every imported SASS file.
// Add page-level styling to /src/index.scss

// Theme Colors
$primary-color: $veritone-blue;
$secondary-color: $veritone-blue-faded;

// Text
$text-primary: $black;

// Fonts
$font-stack-primary: roboto, sans-serif; // Set globally

// Z-index levels
$dialog-z: 200;
$drawer-clickoff-z: 149;
$drawer-z: 150;
$nav-clickoff-z: 199;
$nav-z: 200;
$snack-z: 9999999999999;

// Shared layout dimensions
$header-height: 55px;
$drawer-width: 321px;
$drawer-width-minimal: 80px;
$min-content-box-width: 500px;
$default-combo-box-search-width: 300px;
$people-form-height: 280px;
$default-left-cell-width: 220px;

// Box Shadows
$shadow-level-0: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
$shadow-level-2: 0 3px 4px 0 rgba(0, 0, 0, 0.24);
$shadow-level-3: 0 3px 8px 0 rgba(0, 0, 0, 0.03);
$shadow-level-3-5: 0 3px 8px 0 rgba(0, 0, 0, 0.3);
$shadow-level-4: 0 4px 24px 0 rgba(0, 0, 0, 0.1);

// Transitions
$drawer-transition: 300ms;

// App vars
$person-tab-width: 147;
$snackHeight: 96;
$snackMargin: 8;

// Export to JS
:export {
  /* stylelint-disable */

  primaryColor: $primary-color;
  secondaryColor: $secondary-color;
  inputBorder: $input-border;
  grey4: $grey-4;
  blueDisabled: $blue-disabled;
  personTabWidth: $person-tab-width;
  snackHeight: $snackHeight;
  snackMargin: $snackMargin;
  headerHeight: $header-height;
  popperBackground: $popper-background;
  fontColor: $font-color;

  /* stylelint-enable */
}

$content-box-inner-margin: 16px;
$content-box-min-height: 100px;
$content-box-outer-top-margin: 56px;
$banner-height: 57px;

.drawer-manager {
  position: relative;
  height: 100%;
  overflow-y: auto;

  .drawer-manager__drawer {
    position: fixed;
    height: calc(100% - #{$header-height});
    width: $drawer-width;
    transform: translateX(-100%);
    transition: transform $drawer-transition, width $drawer-transition;

    &.small-close {
      transform: translateX(0%);
      width: $drawer-width-minimal;
    }
  }
  .drawer-manager__drawer-clickoff {
    display: initial;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: $drawer-clickoff-z;
    transition: backdrop-filter $drawer-transition, background-color $drawer-transition;
    pointer-events: none;
  }
  .drawer-manager__page-content {
    .drawer-manager__page-content-banner {
      position: absolute;
      top: 0;
      left: $drawer-width-minimal;
      width: calc(100% - $drawer-width-minimal);
      transition: all $drawer-transition;

      .training-mode-banner {
        display: none;
      }
    }
    &.box-content {
      display: flex;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      padding-top: $content-box-outer-top-margin;
      margin-bottom: 30px;
      width: 100%;
      transition: all $drawer-transition, margin-top 0ms;

      .drawer-manager__page-content-box {
        min-height: $content-box-min-height;
        width: $min-content-box-width;
        background-color: $white;
        box-shadow: $shadow-level-2;
        margin-bottom: 30px;
      }
    }
    &.full-content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      .drawer-manager__page-content-box {
        height: 100%;
        width: 100%;
      }
    }
    &.has-banner {
      padding-top: calc($content-box-outer-top-margin + $banner-height);

      .training-mode-banner {
        display: flex;
      }
    }
  }
  &.drawer-open {
    .drawer-manager__drawer {
      transform: translateX(0);

      &.small-close {
        width: $drawer-width;
      }
    }
    .drawer-manager__page-content {
      min-width: calc(#{$min-content-box-width} + #{$content-box-inner-margin});

      .drawer-manager__page-content-banner {
        left: 0;
        width: 100%;
      }
      &.box-content {
        left: $drawer-width;
        width: calc(100% - #{$drawer-width});

        .drawer-manager__page-content-box {
          margin-left: calc(#{$content-box-inner-margin} / 2);
          margin-right: calc(#{$content-box-inner-margin} / 2);
        }
      }
      &.full-content {
        left: $drawer-width;
        width: calc(100% - #{$drawer-width});
        height: 100%;
      }
    }
  }
}
.xs,
.sm {
  .drawer-manager {
    &.drawer-open {
      .drawer-manager__drawer {
        &.small-close {
          width: $drawer-width;
        }
      }
      .drawer-manager__drawer-clickoff {
        transition: backdrop-filter $drawer-transition, background-color $drawer-transition;
        backdrop-filter: blur(1px);
        background-color: rgba(0, 0, 0, 0.5);
        pointer-events: all;
      }
    }
    .drawer-manager__drawer {
      position: fixed;
      top: 0;
      left: 0;
      z-index: $drawer-z;
      height: 100%;

      &.small-close {
        width: 0;
      }
    }
    .drawer-manager__page-content {
      min-width: 100%;

      &.box-content,
      &.full-content {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        padding-top: 0;
        margin: 0;
        width: 100%;
        height: 100%;
        transition: none;
        max-height: initial;

        .drawer-manager__page-content-box {
          margin: 0;
          box-shadow: none;
          min-height: initial;
          background-color: initial;
          width: 100%;
          height: 100%;
          max-height: initial;
        }
        .drawer-manager__page-content-banner {
          left: 0;
          width: 100%;
        }
      }

      &.has-banner {
        padding-top: $banner-height;
      }
    }
  }
}
