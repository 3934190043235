@import "../../../theme/font";
@import "../../../theme/text";
@import "../../../theme/colorVars";

// This file is loaded into every required SASS module.
// Do not include anything in this file that will be actually rendered in CSS, because it will be added to every imported SASS file.
// Add page-level styling to /src/index.scss

// Theme Colors
$primary-color: $veritone-blue;
$secondary-color: $veritone-blue-faded;

// Text
$text-primary: $black;

// Fonts
$font-stack-primary: roboto, sans-serif; // Set globally

// Z-index levels
$dialog-z: 200;
$drawer-clickoff-z: 149;
$drawer-z: 150;
$nav-clickoff-z: 199;
$nav-z: 200;
$snack-z: 9999999999999;

// Shared layout dimensions
$header-height: 55px;
$drawer-width: 321px;
$drawer-width-minimal: 80px;
$min-content-box-width: 500px;
$default-combo-box-search-width: 300px;
$people-form-height: 280px;
$default-left-cell-width: 220px;

// Box Shadows
$shadow-level-0: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
$shadow-level-2: 0 3px 4px 0 rgba(0, 0, 0, 0.24);
$shadow-level-3: 0 3px 8px 0 rgba(0, 0, 0, 0.03);
$shadow-level-3-5: 0 3px 8px 0 rgba(0, 0, 0, 0.3);
$shadow-level-4: 0 4px 24px 0 rgba(0, 0, 0, 0.1);

// Transitions
$drawer-transition: 300ms;

// App vars
$person-tab-width: 147;
$snackHeight: 96;
$snackMargin: 8;

// Export to JS
:export {
  /* stylelint-disable */

  primaryColor: $primary-color;
  secondaryColor: $secondary-color;
  inputBorder: $input-border;
  grey4: $grey-4;
  blueDisabled: $blue-disabled;
  personTabWidth: $person-tab-width;
  snackHeight: $snackHeight;
  snackMargin: $snackMargin;
  headerHeight: $header-height;
  popperBackground: $popper-background;
  fontColor: $font-color;

  /* stylelint-enable */
}

.review-dialog {
  width: 1020px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;

  .review-dialog__close {
    font-size: 22px;
    font-weight: 900;
    padding: 20px;
    color: $primary-color;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    user-select: none;
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: $scrollbar-thumb-track;
  }

  &::-webkit-scrollbar-thumb {
    background: $scrollbar-thumb;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $scrollbar-thumb-hover;
  }

  .review-dialog__title {
    padding: 20px 30px;
    text-align: left;
    color: $primary-color;
    text-transform: uppercase;
    background-color: $grey-a;
    border: 0.5px solid $blueish-grey;

    @include size-4;
  }
  .review-dialog__content-primary-info {
    width: 100%;
    padding: 30px 18px;
    background-color: $grey-f9;
    border: 0.5px solid $blueish-grey;
  }
  .review-dialog__content-primary-info-tables {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .review-dialog__content-primary-info-bottom {
    display: block;
    width: 100%;
  }

  .review-dialog__buttons {
    display: flex;
    justify-content: space-between;
    border-top: 0.5px solid $blueish-grey;
    padding: 20px 0;

    .review-dialog__remove-buttons {
      .review-dialog__approve-deny-btn-activate,
      .review-dialog__remove-report-btn {
        background-color: transparent;
        color: $grey-text;
        display: flex;
        align-items: center;
        width: 155px;
        padding: 0;
        height: 35px;

        .material-icons {
          color: $grey-text;
          margin-right: 2px;
        }
      }
    }

    .review-dialog__approve-deny-buttons,
    .review-dialog__remove-buttons {
      display: flex;
      margin: 0 15px;
    }

    .review-dialog__approve-deny-btn-deny {
      border: none;
      color: $grey-text;
    }
    .review-dialog__approve-deny-btn-deny,
    .review-dialog__approve-deny-btn-approve {
      width: 155px;
      padding: 0;
      height: 35px;
      border-radius: 4px;
      line-height: 35px;

      .button__text {
        display: flex;
        align-items: center;
      }

      .review-dialog__cancel-icon {
        line-height: 15px;
        height: 17px;
        width: 17px;
        font-size: 11px;
        font-weight: 900;
        text-align: center;
        border: solid 1px $black;
        color: $grey-text;
        border-radius: 50%;
        margin-right: 5px;
      }
      .review-dialog__done-icon {
        line-height: 16px;
        height: 16px;
        width: 16px;
        font-size: 14px;
        font-weight: 900;
        text-align: center;
        background-color: $white;
        color: $primary-color;
        border-radius: 50%;
        margin-right: 5px;
      }
    }
  }

  .review-dialog__content {
    padding: 15px 15px 0;
    margin-bottom: 15px;
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .review-dialog__left,
    .review-dialog__right {
      display: table;
      width: 50%;
      padding-right: 20px;

      .review-dialog__left-row,
      .review-dialog__right-row {
        display: table-row;
        height: 50px;

        .review-dialog__left-cell,
        .review-dialog__right-cell {
          display: table-cell;
          color: $review-grey;
          vertical-align: middle;

          @include size-2;

          .material-icons {
            font-size: 20px;
          }

          &.icon {
            width: 30px;
          }
          &.key {
            width: $default-left-cell-width;
          }
          &.value {
            position: relative;
            color: $grey-text;

            span {
              position: absolute;
              top: 17px;
            }
          }
        }
      }
    }

    .review-dialog__stop-id-text {
      display: table-cell;
      color: $grey-text;
      line-height: 19px;
      margin-bottom: 4px;

      @include size-2;

      .material-icons {
        margin-right: 19px;
        font-size: 20px;
        color: $grey-text;
      }
    }

    .review-dialog__left-value,
    .review-dialog__right-value {
      display: table-cell;
      line-height: 24px;

      @include size-2;
    }

    .review-dialog__per-stop-custom-questions {
      display: table;
      margin-top: 10px;

      .review-dialog__per-stop-custom {
        display: table-row;
        height: 30px;

        .review-dialog__per-stop-custom-icon {
          display: table-cell;
          vertical-align: middle;
          color: $review-grey;
          width: 30px;
          font-size: 20px;
        }
        .review-dialog__per-stop-custom-text {
          width: $default-left-cell-width;
          display: table-cell;
          vertical-align: middle;
          padding-right: 30px;
          color: $review-grey;

          @include size-2;
        }

        .review-dialog__per-stop-custom-value {
          display: table-cell;
          vertical-align: middle;
          margin-top: 3px;
          color: $grey-text;

          @include size-2;
        }
      }
    }

    .review-dialog__stop-description {
      display: inline-flex;
      flex-direction: column;
      width: 100%;
      padding: 23px 30px 0 30px;

      .review-dialog__stop-description-label {
        color: $primary-color;

        @include size-2;
      }

      .review-dialog__stop-description-value {
        margin-top: 3px;

        @include size-2-bold;
      }
    }

    .review-dialog__accordion {
      width: 100%;
      margin-top: 15px;
      border: 0.5px solid $blueish-grey;
      position: relative;

      .review-dialog__accordion-header {
        display: flex;
        justify-content: space-between;
        padding: 30px 50px 25px;
        cursor: pointer;

        .review-dialog__accordion-header-label {
          display: inline-flex;
          justify-content: center;
          align-items: center;

          .material-icons {
            font-size: 20px;
            color: $primary-color;
          }

          .review-dialog__accordion-header-label-text {
            margin-left: 10px;

            @include size-2-bold;
          }
        }

        .review-dialog__accordion-header-controls {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          left: 18px;
          top: 30px;

          .review-dialog__accordion-header-controls-edit {
            font-size: 18px;
            margin-right: 5px;
            color: $primary-color;
            cursor: pointer;
            user-select: none;
          }
          .review-dialog__accordion-header-controls-expand {
            font-size: 22px;
            color: $primary-color;
            transition: transform 200ms;
            cursor: pointer;
            user-select: none;

            &.open {
              transform: rotate(180deg);
            }
          }
        }
      }

      .review-dialog__accordion-content {
        width: 100%;
        display: none;

        &.open {
          display: block;
        }
        .review-dialog__accordion-content-bottom,
        .review-dialog__accordion-content-top {
          display: flex;
          justify-content: space-between;
          padding: 0 51px;
        }

        .review-dialog__accordion-divider {
          border-top: 0.5px solid $blueish-grey;
          margin: 0 15px 30px;
        }
        .review-dialog__accordion-content-right {
          margin-left: 10px;
        }
        .review-dialog__accordion-content-bottom {
          display: table;
          margin: 20px 0 0;
        }
        .review-dialog__accordion-content-bottom-row {
          display: table-row;
          height: 40px;

          .review-dialog__accordion-content-bottom-cell {
            display: table-cell;
            vertical-align: top;

            &:nth-child(1) {
              width: 25%;
              color: $review-grey;
            }
            &:nth-child(2) {
              width: 75%;
              color: $grey-text;
              white-space: pre-wrap;
            }

            @include size-2;
          }
        }
        .review-dialog__accordion-content-right,
        .review-dialog__accordion-content-left {
          display: table;
          width: 50%;

          .review-dialog__accordion-content-row {
            display: table-row;
            height: 40px;

            .review-dialog__accordion-content-key,
            .review-dialog__accordion-content-value {
              display: table-cell;
              color: $grey-text;
              vertical-align: top;
              width: 50%;
              white-space: pre-wrap;

              @include size-2;
            }
            .review-dialog__accordion-content-key {
              color: $review-grey;
            }
          }
        }
      }
    }
  }
}

.dark-mode {
  .review-dialog {
    .review-dialog__buttons {
      .review-dialog__remove-report-btn {
        color: $primary-color;

        .material-icons {
          color: $primary-color;
        }
      }
      .review-dialog__done-icon {
        background-color: $black;
        color: $primary-color;
      }
      .review-dialog__cancel-icon,
      .review-dialog__approve-deny-btn-deny {
        color: $primary-color;
      }
      .review-dialog__cancel-icon {
        border: solid 1px $primary-color;
      }
    }
  }
}
