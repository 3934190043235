@import "../../../theme/font";
@import "../../../theme/text";
@import "../../../theme/colorVars";

// This file is loaded into every required SASS module.
// Do not include anything in this file that will be actually rendered in CSS, because it will be added to every imported SASS file.
// Add page-level styling to /src/index.scss

// Theme Colors
$primary-color: $veritone-blue;
$secondary-color: $veritone-blue-faded;

// Text
$text-primary: $black;

// Fonts
$font-stack-primary: roboto, sans-serif; // Set globally

// Z-index levels
$dialog-z: 200;
$drawer-clickoff-z: 149;
$drawer-z: 150;
$nav-clickoff-z: 199;
$nav-z: 200;
$snack-z: 9999999999999;

// Shared layout dimensions
$header-height: 55px;
$drawer-width: 321px;
$drawer-width-minimal: 80px;
$min-content-box-width: 500px;
$default-combo-box-search-width: 300px;
$people-form-height: 280px;
$default-left-cell-width: 220px;

// Box Shadows
$shadow-level-0: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
$shadow-level-2: 0 3px 4px 0 rgba(0, 0, 0, 0.24);
$shadow-level-3: 0 3px 8px 0 rgba(0, 0, 0, 0.03);
$shadow-level-3-5: 0 3px 8px 0 rgba(0, 0, 0, 0.3);
$shadow-level-4: 0 4px 24px 0 rgba(0, 0, 0, 0.1);

// Transitions
$drawer-transition: 300ms;

// App vars
$person-tab-width: 147;
$snackHeight: 96;
$snackMargin: 8;

// Export to JS
:export {
  /* stylelint-disable */

  primaryColor: $primary-color;
  secondaryColor: $secondary-color;
  inputBorder: $input-border;
  grey4: $grey-4;
  blueDisabled: $blue-disabled;
  personTabWidth: $person-tab-width;
  snackHeight: $snackHeight;
  snackMargin: $snackMargin;
  headerHeight: $header-height;
  popperBackground: $popper-background;
  fontColor: $font-color;

  /* stylelint-enable */
}

.add-org {
  position: relative;

  .add-org__2024-flags {
    color: $primary-color;
    margin-top: 20px;

    .add-org__2024-flags-heading {
      @include size-2-bold;

      text-transform: uppercase;
      margin-bottom: 8px;
      color: $primary-color;
      margin-top: 20px;
    }
    .add-org__2024-flags-switch {
      margin: 10px 0;
    }
  }
  .add-org__upload-spinner {
    position: absolute;
    justify-content: center;
    align-items: center;
    display: none;
    z-index: #{$dialog-z + 80};
    height: calc(100% + 60px);
    width: calc(100% + 60px);
    left: -30px;
    top: -30px;
    backdrop-filter: blur(7px);
    color: #000;

    &.uploading {
      display: flex;
    }
  }
  .add-org__ori {
    float: right;
  }
  .add-org__subdomain,
  .add-org__ori {
    display: inline-block;
    margin-top: 30px;
    margin-right: 30px;
    width: calc(50% - 30px);
  }
  .add-org__dropdown-state,
  .add-org__dropdown-auth-providers {
    margin-top: 30px;
  }
  .add-org__auth-settings-input {
    margin-top: 30px;
    width: 100%;
  }

  .add-org__image-upload {
    margin-top: 30px;

    .add-org__image-upload-heading {
      @include size-2-bold;

      text-transform: uppercase;
      margin-bottom: 8px;
      color: $primary-color;
    }

    .add-org__image-preview-container {
      display: inline-block;
      position: relative;
      width: 288px;
      height: 80px;
      background-color: $secondary-color;
      border: none;

      &.has-image {
        border: solid 1px $grey-9;

        img,
        .add-org__image-preview-delete,
        .add-org__image-preview {
          display: initial;
        }
      }

      img,
      .add-org__image-preview-delete,
      .add-org__image-preview {
        display: none;
      }

      .add-org__image-preview-delete {
        right: 5px;
        top: 5px;
        position: absolute;
        background-color: $white;
        height: 18px;
        width: 18px;
        border-radius: 50%;
        z-index: #{$dialog-z + 50};
        cursor: pointer;
        user-select: none;

        &:active {
          opacity: 0.5;
        }

        .material-icons {
          margin: -2px;
          color: $number-red;
        }
      }
      .add-org__image-preview {
        position: absolute;
        z-index: 1;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        object-fit: contain;
      }
      .add-org__image-loading-spinner {
        position: absolute;
        z-index: 2;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .add-org__browse-container {
      height: 80px;
      float: right;

      .add-org__browse-button {
        width: 120px;
        height: 36px;
        border-radius: 0;
        line-height: 36px;
        text-transform: uppercase;
        text-align: center;
        cursor: pointer;
        position: relative;

        input {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          cursor: pointer;
        }
      }

      .add-org__browse-types-container {
        margin-top: 5px;
        color: $blue-disabled;
        text-align: right;
        width: 105px;
        margin-left: 15px;

        .add-org__browse-types-label {
          display: inline-block;

          @include size-1-bold;
        }

        .add-org__browse-types {
          display: inline-block;

          @include size-1;
        }
      }
    }
  }
}
