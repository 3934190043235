@import "../../../theme/font";
@import "../../../theme/text";
@import "../../../theme/colorVars";

// This file is loaded into every required SASS module.
// Do not include anything in this file that will be actually rendered in CSS, because it will be added to every imported SASS file.
// Add page-level styling to /src/index.scss

// Theme Colors
$primary-color: $veritone-blue;
$secondary-color: $veritone-blue-faded;

// Text
$text-primary: $black;

// Fonts
$font-stack-primary: roboto, sans-serif; // Set globally

// Z-index levels
$dialog-z: 200;
$drawer-clickoff-z: 149;
$drawer-z: 150;
$nav-clickoff-z: 199;
$nav-z: 200;
$snack-z: 9999999999999;

// Shared layout dimensions
$header-height: 55px;
$drawer-width: 321px;
$drawer-width-minimal: 80px;
$min-content-box-width: 500px;
$default-combo-box-search-width: 300px;
$people-form-height: 280px;
$default-left-cell-width: 220px;

// Box Shadows
$shadow-level-0: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
$shadow-level-2: 0 3px 4px 0 rgba(0, 0, 0, 0.24);
$shadow-level-3: 0 3px 8px 0 rgba(0, 0, 0, 0.03);
$shadow-level-3-5: 0 3px 8px 0 rgba(0, 0, 0, 0.3);
$shadow-level-4: 0 4px 24px 0 rgba(0, 0, 0, 0.1);

// Transitions
$drawer-transition: 300ms;

// App vars
$person-tab-width: 147;
$snackHeight: 96;
$snackMargin: 8;

// Export to JS
:export {
  /* stylelint-disable */

  primaryColor: $primary-color;
  secondaryColor: $secondary-color;
  inputBorder: $input-border;
  grey4: $grey-4;
  blueDisabled: $blue-disabled;
  personTabWidth: $person-tab-width;
  snackHeight: $snackHeight;
  snackMargin: $snackMargin;
  headerHeight: $header-height;
  popperBackground: $popper-background;
  fontColor: $font-color;

  /* stylelint-enable */
}

.ripa-people-form {
  position: relative;
  height: $people-form-height;

  .ripa-people-form__title {
    padding: 30px 30px 0;
    width: 100%;
    text-align: center;

    @include size-7-bold;
  }
  .ripa-people-form__people-counter {
    display: inline-flex;
    position: absolute;
    margin-top: 34px;
    left: 50%;
    border: solid 1px $input-border;
    border-radius: 3px;
    transform: translateX(-50%);
    user-select: none;

    .ripa-people-form__people-counter-plus,
    .ripa-people-form__people-counter-minus {
      background-color: $input-border;
      color: $white;
      width: 40px;
      line-height: 54px;
      text-align: center;
      font-weight: 900;

      &.enabled {
        background-color: $primary-color;
        cursor: pointer;

        &:active {
          background-color: $secondary-color;
          color: $primary-color;
        }
      }
    }
    .ripa-people-form__people-counter-number {
      background-color: $grey-4;
      width: 70px;
      height: 54px;
      line-height: 54px;
      text-align: center;

      @include size-7-bold;
    }
  }
}

.dark-mode {
  .ripa-people-form {

    .ripa-people-form__people-counter {

      .ripa-people-form__people-counter-plus,
      .ripa-people-form__people-counter-minus {

        &.enabled {
          color: $black;

          &:active {
            background-color: $secondary-color;
            color: $primary-color;
          }
        }
      }
    }
  }
}
