@import "../../../theme/font";
@import "../../../theme/text";
@import "../../../theme/colorVars";

// This file is loaded into every required SASS module.
// Do not include anything in this file that will be actually rendered in CSS, because it will be added to every imported SASS file.
// Add page-level styling to /src/index.scss

// Theme Colors
$primary-color: $veritone-blue;
$secondary-color: $veritone-blue-faded;

// Text
$text-primary: $black;

// Fonts
$font-stack-primary: roboto, sans-serif; // Set globally

// Z-index levels
$dialog-z: 200;
$drawer-clickoff-z: 149;
$drawer-z: 150;
$nav-clickoff-z: 199;
$nav-z: 200;
$snack-z: 9999999999999;

// Shared layout dimensions
$header-height: 55px;
$drawer-width: 321px;
$drawer-width-minimal: 80px;
$min-content-box-width: 500px;
$default-combo-box-search-width: 300px;
$people-form-height: 280px;
$default-left-cell-width: 220px;

// Box Shadows
$shadow-level-0: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
$shadow-level-2: 0 3px 4px 0 rgba(0, 0, 0, 0.24);
$shadow-level-3: 0 3px 8px 0 rgba(0, 0, 0, 0.03);
$shadow-level-3-5: 0 3px 8px 0 rgba(0, 0, 0, 0.3);
$shadow-level-4: 0 4px 24px 0 rgba(0, 0, 0, 0.1);

// Transitions
$drawer-transition: 300ms;

// App vars
$person-tab-width: 147;
$snackHeight: 96;
$snackMargin: 8;

// Export to JS
:export {
  /* stylelint-disable */

  primaryColor: $primary-color;
  secondaryColor: $secondary-color;
  inputBorder: $input-border;
  grey4: $grey-4;
  blueDisabled: $blue-disabled;
  personTabWidth: $person-tab-width;
  snackHeight: $snackHeight;
  snackMargin: $snackMargin;
  headerHeight: $header-height;
  popperBackground: $popper-background;
  fontColor: $font-color;

  /* stylelint-enable */
}

.review-row {
  display: block;
  border-bottom: solid 1px $table-border;

  &.testcase2024 {
    background-color: $number-orange !important;

    .review-row__cell-status,
    .pii-text-field__pii {
      color: $black !important;
    }

    .review-row__table-cell-actions-comments-dialog {
      border: solid 2px $black !important;
      color: $black !important;
    }
  }

  &.checked {
    background-color: $veritone-blue-faded-6;
  }

  .review-row__row-details {
    margin-top: -10px;
    margin-bottom: 10px;
    transition: height 300ms;
    overflow: hidden;

    .review-row__row-details-line {
      opacity: 0.5;
      background-color: $primary-color;
      width: 3px;
      height: 131px;
      margin-left: 70px;
      margin-right: 27px;
      transition: height 300ms;
      float: left;
    }

    .review-row__row-details-search-label,
    .review-row__row-details-stop-label {
      color: $primary-color;
      margin: 7px 0;

      @include size-1-bold;
    }

    .review-row__row-details-search-label {
      float: right;
      width: calc(100% - 100px);
      height: 14px;
    }

    .review-row__row-details-search-stop {
      float: right;
      width: calc(100% - 100px);
      height: 140px;
    }

    .review-row__row-details-stop {
      height: 123px;
    }

    .review-row__row-details-search {
      height: 103px;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .review-row__row-details-number {
      position: absolute;
      transform: translate(-77px, 35px);
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background-color: $primary-color;
      color: $white;
      text-align: center;
      line-height: 20px;

      @include size-1;

      &.all {
        font-size: 10px;
      }
    }

    .review-row__row-details-search,
    .review-row__row-details-stop {
      position: relative;
      float: right;
      width: calc(100% - 100px);

      .review-row__row-details-search-field,
      .review-row__row-details-stop-field {
        position: absolute;
        width: 100%;
        padding-right: 20px;
        white-space: pre-wrap;

        .pii-text-field {
          height: 103px;
        }

        .review-row__row-details-search-field-input,
        .review-row__row-details-stop-field-input {
          width: 100%;

          div {
            padding: 9.9px 15px 36px;
          }

          textarea {
            @include size-1;

            color: $black;
          }

          ::placeholder {
            @include size-2;

            font-style: italic;
            color: $input-border;
          }
        }

        .review-row__row-details-search-field-input {
          &.disabled {
            textarea {
              color: $blue-disabled;
            }
          }
        }

        .review-row__row-details-search-remaining,
        .review-row__row-details-stop-remaining {
          display: inline;
          position: absolute;
          bottom: 0;
          right: 20px;
          padding: 11.1px 10px;
          color: $primary-color;

          @include size-1;
        }
      }
    }
  }

  .review-row__row {
    position: relative;
    display: flex;
    justify-content: space-between;

    .review-row__cell {
      display: flex;
      justify-content: center;
      flex-direction: column;

      .review-row__cell-select {
        display: flex;
        justify-content: center;
        align-items: center;

        .review-row__cell-select-checkbox {
          display: flex;
          margin-left: 20px;
          border: solid 1px $veritone-blue;
          cursor: pointer;
          user-select: none;

          .material-icons {
            width: 20px;
            line-height: 20px;
            font-size: 18px;
            font-weight: 900;
            text-align: center;
            color: transparent;
          }

          &.checked {
            .material-icons {
              color: $primary-color;
            }
          }

          &.disabled {
            background: #f8f8f8;
            border: 1px solid #bbc8d4;
            pointer-events: none;
          }
        }

        .review-row__cell-select-expand {
          fill: $expand-disabled;
          margin-left: 20px;
          cursor: pointer;
          user-select: none;

          &.expanded {
            fill: $expand-enabled;
          }
        }
      }

      .review-row__cell-officer,
      .review-row__cell-location,
      .review-row__cell-assignment,
      .review-row__cell-district,
      .review-row__cell-beat,
      .review-row__cell-time,
      .review-row__cell-status {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 23px 16px 23px 0;
        line-height: 24px;
        text-transform: capitalize;

        @include size-1;

        &.pending {
          font-style: italic;
        }
      }

      .review-row__cell-location {
        width: 100%;

        .review-school {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          text-align: left;
          font-style: italic;
          font-size: 11px;
        }

        .MuiFormControl-root {
          width: 100%;

          .MuiInputBase-input {
            padding: 4px 0 6px 3px;
          }
        }
      }

      .review-row__cell-status {
        font-weight: 600;

        &.draft {
          color: $veritone-blue-faded-4;
        }

        &.submitted,
        &.approved {
          color: $number-green;
        }

        &.denied {
          color: $number-orange;
        }

        &.rejected,
        &.under_review {
          color: $primary-color;
        }
      }

      .review-row__cell-progress {
        font-weight: 600;

        &.red {
          color: $number-red;
        }

        &.orange {
          color: $number-orange;
        }

        &.green {
          color: $number-green;
        }
      }

      .review-row__cell-actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;

        .review-row__table-cell-actions-comments-dialog {
          font-size: 18px;
          border: solid 2px $number-orange;
          border-radius: 50%;
          color: $number-orange;
          margin-right: 12px;
          cursor: pointer;
          user-select: none;
        }

        .review-row__table-cell-actions-cancel {
          color: $number-red;
          cursor: pointer;
          user-select: none;
          font-size: 28px;

          &.disabled {
            color: $blue-disabled;
            pointer-events: none;
          }
        }

        .review-row__table-cell-actions-approve {
          color: $white;
          background-color: $number-green;
          cursor: pointer;
          border-radius: 50%;
          user-select: none;
          font-weight: 900;
          font-size: 16px;
          padding: 3px;
          margin: 0 15px 0 12px;

          &.disabled {
            background-color: $blue-disabled;
            pointer-events: none;
          }
        }

        .review-row__table-cell-actions-doj-rejection-dialog {
          color: $row-warning;
          background-color: $number-orange;
          cursor: pointer;
          user-select: none;
          border-radius: 50%;
          font-size: 16px;
          padding: 3px;
          font-weight: 500;
          margin: 0 15px 0 0;
        }

        .review-row__table-cell-actions-review {
          width: 78px;
          height: 24px;
          background-color: $primary-color;
          color: $white;
          border-radius: 4px;
          line-height: 24px;
          text-align: center;
          padding: 0 14px;
          margin-right: 20px;
          cursor: pointer;
          user-select: none;

          @include size-1;
        }
      }
    }

    .review-row__cell_select {
      display: flex;
      justify-content: left;
      flex-direction: unset;
    }
  }

  &.warning {
    background-color: $row-warning;

    .review-row__row .review-row__cell .review-row__cell-select {
      border-left: solid 5px $number-orange;

      .review-row__cell-select-checkbox {
        margin-left: 15px;
      }
    }
  }
}

.dark-mode {
  .review-row__row {
    .review-row__cell {
      .review-row__cell-actions {
        .review-row__table-cell-actions-review {
          color: $black;
        }

        .review-row__table-cell-actions-doj-rejection-dialog {
          color: $black;
        }
      }

      .review-row__cell-select {
        .review-row__cell-select-expand {
          fill: $font-color;

          &.expanded {
            fill: $primary-color;
          }
        }
      }
    }
  }
}
