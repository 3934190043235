@import "../../../theme/font";
@import "../../../theme/text";
@import "../../../theme/colorVars";

// This file is loaded into every required SASS module.
// Do not include anything in this file that will be actually rendered in CSS, because it will be added to every imported SASS file.
// Add page-level styling to /src/index.scss

// Theme Colors
$primary-color: $veritone-blue;
$secondary-color: $veritone-blue-faded;

// Text
$text-primary: $black;

// Fonts
$font-stack-primary: roboto, sans-serif; // Set globally

// Z-index levels
$dialog-z: 200;
$drawer-clickoff-z: 149;
$drawer-z: 150;
$nav-clickoff-z: 199;
$nav-z: 200;
$snack-z: 9999999999999;

// Shared layout dimensions
$header-height: 55px;
$drawer-width: 321px;
$drawer-width-minimal: 80px;
$min-content-box-width: 500px;
$default-combo-box-search-width: 300px;
$people-form-height: 280px;
$default-left-cell-width: 220px;

// Box Shadows
$shadow-level-0: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
$shadow-level-2: 0 3px 4px 0 rgba(0, 0, 0, 0.24);
$shadow-level-3: 0 3px 8px 0 rgba(0, 0, 0, 0.03);
$shadow-level-3-5: 0 3px 8px 0 rgba(0, 0, 0, 0.3);
$shadow-level-4: 0 4px 24px 0 rgba(0, 0, 0, 0.1);

// Transitions
$drawer-transition: 300ms;

// App vars
$person-tab-width: 147;
$snackHeight: 96;
$snackMargin: 8;

// Export to JS
:export {
  /* stylelint-disable */

  primaryColor: $primary-color;
  secondaryColor: $secondary-color;
  inputBorder: $input-border;
  grey4: $grey-4;
  blueDisabled: $blue-disabled;
  personTabWidth: $person-tab-width;
  snackHeight: $snackHeight;
  snackMargin: $snackMargin;
  headerHeight: $header-height;
  popperBackground: $popper-background;
  fontColor: $font-color;

  /* stylelint-enable */
}

.dynamic-select-form {
  padding-bottom: 30px;
  overflow: auto;

  &__person {
    position: relative;
    height: 70px;
    background-color: $secondary-color;
    padding: 0 100px 0 30px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    align-items: center;

    &.same-for-all {
      .material-icons {
        color: $bg-disabled;
      }
      .dynamic-select-form__person-label {
        display: none;
      }
      .dynamic-select-form__person-same-for-all {
        display: initial;
      }
      .dynamic-select-form__person-progression {
        display: none;
      }
      .dynamic-select-form__person-progression-all {
        display: initial;
      }
    }

    .material-icons {
      font-size: 35px;
      color: $primary-color;
      margin-top: -3px;
    }

    &-label {
      color: $primary-color;
      margin-left: 5px;

      @include size-3-bold;
    }

    &-same-for-all {
      display: none;
      margin-left: 5px;
      color: $bg-disabled;

      @include size-3-bold;
    }

    &-progression {
      position: absolute;
      right: 0;
      top: 0;
      width: 70px;
      height: 100%;
      color: $white;
      background-color: $veritone-blue-faded-5;
      line-height: 70px;
      text-align: center;

      @include size-1;

      b {
        @include size-3-bold;
      }
    }

    &-progression-all {
      display: none;
      position: absolute;
      right: 0;
      top: 0;
      width: 70px;
      height: 100%;
      color: $white;
      background-color: $bg-disabled;
      line-height: 70px;
      text-align: center;

      @include size-3-bold;
    }
  }
  .dynamic-select-form__title {
    padding: 30px 30px 0;
    width: 100%;
    text-align: center;

    @include size-7-bold;
  }
  .dynamic-select-form__skip-switch {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 33px 0 29px;
    min-width: 370px;
  }
  .dynamic-select-form__skip-switch-switch {
    display: inline-flex;
  }
  .dynamic-select-form__skip-switch-line {
    display: inline-flex;
    width: 90px;
    height: 3px;
    margin: 0 7px;
    background-color: $secondary-color;
  }
  .dynamic-select-form__skip-switch-text {
    display: inline-flex;
    width: 105px;
    margin: 0 7px 0 0;
    text-align: right;
    color: $primary-color;

    @include size-0;

    &.disabled {
      color: $blue-disabled;
      width: 122px;
    }
  }

  .dynamic-select-form__select-boxes {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    margin-top: 30px;
    padding: 0 30px 40px 30px;
    gap: .5rem;
  }

  .dynamic-select-form__select-box {
    display: inline-flex;
    line-height: 16px;
    border-radius: 3px;
    background-color: $veritone-blue-faded;
    border: solid 2px $veritone-blue;
    color: $primary-color;
    cursor: pointer;
    user-select: none;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 1.1rem;

    @include size-2-bold;

    fieldset {
      border-color: transparent;
    }
    &.active {
      background-color: $veritone-blue-faded-3;
      color: $white;
    }
  }
}

.dark-mode {
  .dynamic-select-form {
    .dynamic-select-form__person {
      .dynamic-select-form__person-progression-all {
        color: $black;
      }
      .dynamic-select-form__person-progression {
        color: $black;
      }
    }
    .dynamic-select-form__select-boxes {
      .dynamic-select-form__select-box {
        &.active {
          background-color: $primary-color;
          color: $black;
        }
      }
    }
  }
}
