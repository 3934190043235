@import "../../../theme/font";
@import "../../../theme/text";
@import "../../../theme/colorVars";

// This file is loaded into every required SASS module.
// Do not include anything in this file that will be actually rendered in CSS, because it will be added to every imported SASS file.
// Add page-level styling to /src/index.scss

// Theme Colors
$primary-color: $veritone-blue;
$secondary-color: $veritone-blue-faded;

// Text
$text-primary: $black;

// Fonts
$font-stack-primary: roboto, sans-serif; // Set globally

// Z-index levels
$dialog-z: 200;
$drawer-clickoff-z: 149;
$drawer-z: 150;
$nav-clickoff-z: 199;
$nav-z: 200;
$snack-z: 9999999999999;

// Shared layout dimensions
$header-height: 55px;
$drawer-width: 321px;
$drawer-width-minimal: 80px;
$min-content-box-width: 500px;
$default-combo-box-search-width: 300px;
$people-form-height: 280px;
$default-left-cell-width: 220px;

// Box Shadows
$shadow-level-0: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
$shadow-level-2: 0 3px 4px 0 rgba(0, 0, 0, 0.24);
$shadow-level-3: 0 3px 8px 0 rgba(0, 0, 0, 0.03);
$shadow-level-3-5: 0 3px 8px 0 rgba(0, 0, 0, 0.3);
$shadow-level-4: 0 4px 24px 0 rgba(0, 0, 0, 0.1);

// Transitions
$drawer-transition: 300ms;

// App vars
$person-tab-width: 147;
$snackHeight: 96;
$snackMargin: 8;

// Export to JS
:export {
  /* stylelint-disable */

  primaryColor: $primary-color;
  secondaryColor: $secondary-color;
  inputBorder: $input-border;
  grey4: $grey-4;
  blueDisabled: $blue-disabled;
  personTabWidth: $person-tab-width;
  snackHeight: $snackHeight;
  snackMargin: $snackMargin;
  headerHeight: $header-height;
  popperBackground: $popper-background;
  fontColor: $font-color;

  /* stylelint-enable */
}

.snackbar {
  position: relative;
  width: 400px;
  height: 0;
}

.snackbar__mobile-clickoff {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.snackbar__box {
  position: absolute;
  width: 400px;
  height: #{$snackHeight}px;
  padding: 16px 23px 16px 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: $white;
  opacity: 1;
  transition: top 200ms, opacity 2000ms;
  z-index: $snack-z;

  &.success {
    color: $green-60;
    border-top: solid 3px $green-20;
    border-right: solid 1px $green-30;
    border-bottom: solid 1px $green-30;
    border-left: solid 1px $green-30;
  }
  &.error {
    color: $red-60;
    border-top: solid 3px $red-50;
    border-right: solid 1px $red-50;
    border-bottom: solid 1px $red-50;
    border-left: solid 1px $red-50;
  }
  &.warning {
    color: $yellow-80;
    border-top: solid 3px $yellow-40;
    border-right: solid 1px $yellow-40;
    border-bottom: solid 1px $yellow-40;
    border-left: solid 1px $yellow-40;
  }
  &.info {
    color: $teal-60;
    border-top: solid 3px $teal-50;
    border-right: solid 1px $teal-50;
    border-bottom: solid 1px $teal-50;
    border-left: solid 1px $teal-50;
  }
  &.fading {
    opacity: 0;
  }
  &.dead {
    opacity: 0;
  }
  .snackbar__box-close {
    position: absolute;
    top: 0;
    right: 0;
    margin: 6.5px;
    color: $grey-60;
    cursor: pointer;
  }
  .snackbar__box-icon {
    position: absolute;
    top: 15px;
    left: 17px;
  }
  .snackbar__box-title {
    margin: 0;
    line-height: 22px;
  }
  .snackbar__box-body {
    margin-top: 4px;
    font-size: 15px;
    line-height: 16px;
    color: $black;
    white-space: initial;
  }
}

.xs {
  .snackbar__mobile-clickoff {
    display: block;
  }
}
