@import "../../../theme/font";
@import "../../../theme/text";
@import "../../../theme/colorVars";

// This file is loaded into every required SASS module.
// Do not include anything in this file that will be actually rendered in CSS, because it will be added to every imported SASS file.
// Add page-level styling to /src/index.scss

// Theme Colors
$primary-color: $veritone-blue;
$secondary-color: $veritone-blue-faded;

// Text
$text-primary: $black;

// Fonts
$font-stack-primary: roboto, sans-serif; // Set globally

// Z-index levels
$dialog-z: 200;
$drawer-clickoff-z: 149;
$drawer-z: 150;
$nav-clickoff-z: 199;
$nav-z: 200;
$snack-z: 9999999999999;

// Shared layout dimensions
$header-height: 55px;
$drawer-width: 321px;
$drawer-width-minimal: 80px;
$min-content-box-width: 500px;
$default-combo-box-search-width: 300px;
$people-form-height: 280px;
$default-left-cell-width: 220px;

// Box Shadows
$shadow-level-0: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
$shadow-level-2: 0 3px 4px 0 rgba(0, 0, 0, 0.24);
$shadow-level-3: 0 3px 8px 0 rgba(0, 0, 0, 0.03);
$shadow-level-3-5: 0 3px 8px 0 rgba(0, 0, 0, 0.3);
$shadow-level-4: 0 4px 24px 0 rgba(0, 0, 0, 0.1);

// Transitions
$drawer-transition: 300ms;

// App vars
$person-tab-width: 147;
$snackHeight: 96;
$snackMargin: 8;

// Export to JS
:export {
  /* stylelint-disable */

  primaryColor: $primary-color;
  secondaryColor: $secondary-color;
  inputBorder: $input-border;
  grey4: $grey-4;
  blueDisabled: $blue-disabled;
  personTabWidth: $person-tab-width;
  snackHeight: $snackHeight;
  snackMargin: $snackMargin;
  headerHeight: $header-height;
  popperBackground: $popper-background;
  fontColor: $font-color;

  /* stylelint-enable */
}

.ripa-disability-details-form {
  padding-bottom: 40px;

  .ripa-disability-details-form__title {
    padding: 30px 30px 0;
    width: 100%;
    text-align: center;

    @include size-7-bold;
  }
  .ripa-disability-details-form__labels-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0 30px;
    min-width: 500px;
  }

  .ripa-disability-details-form__label-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ripa-disability-details-form__select-box-content {
    display: flex;
    flex-wrap: wrap;

    .ripa-disability-details-form__select-box {
      &:nth-child(2n - 1) {
        margin: 20px 20px 0 0;
      }
      &:nth-child(5) {
        padding: 0 5px;
      }
      &:nth-child(7) {
        width: 100%;
        margin: 20px 0 0 0;
      }

      &.k12 {
        padding: 0 50px 0 0;
      }

      display: inline-flex;
      align-items: center;
      justify-content: center;
      position: relative;
      text-align: center;
      height: 50px;
      width: calc(50% - 10px);
      margin: 20px 0 0 0;
      padding: 0 10px;
      border-radius: 3px;
      background-color: $veritone-blue-faded;
      border: solid 2px $veritone-blue;
      color: $primary-color;
      cursor: pointer;
      user-select: none;

      @include size-2-bold;

      fieldset {
        border-color: transparent;
      }
      &.active {
        background-color: $veritone-blue-faded-3;
        color: $white;
      }
      .ripa-disability-details-form__select-box-k12 {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 50px;
        line-height: 46px;
        padding-left: 2px;
        background-color: $primary-color;
        color: $white;
        text-align: center;

        @include size-2;
      }
    }
  }

  .ripa-disability-details-form__labels {
    width: 100%;
    max-width: 500px;

    .ripa-disability-details-form__selection {
      margin: 30px 0 0 0;
      padding-bottom: 30px;

      &:not(:last-child) {
        border-bottom: solid 1px $blue-disabled;
      }

      .ripa-disability-details-form__label {
        display: flex;
        width: 100%;
        margin-right: 20px;
        align-items: center;

        .material-icons {
          color: $primary-color;
          font-size: 40px;
          margin-left: -4px;
        }
        .ripa-disability-details-form__label-text {
          color: $primary-color;
          margin: 0 5px 0 10px;

          @include size-2-bold;
        }
      }

      .ripa-disability-details-form__disability-switch-container {
        display: inline-flex;
        align-items: center;
        justify-content: center;

        .ripa-disability-details-form__disability-switch-text {
          margin-right: 15px;

          @include size-1-bold;
        }
      }
    }
  }
}

.xs,
.sm {
  .ripa-disability-details-form {
    .ripa-disability-details-form__use-same {
      min-width: 300px;
    }
  }
}

.xs {
  .ripa-disability-details-form {
    padding-bottom: 50px;
    .ripa-disability-details-form__selection {
      justify-content: initial;

      .ripa-disability-details-form__label {
        display: flex;
        align-items: center;
        margin-left: -6px;
        width: initial;
      }
    }
    .ripa-disability-details-form__label-container {
      display: block;

      .ripa-disability-details-form__label {
        margin-left: 0;
      }
    }
    .ripa-disability-details-form__selection
    .ripa-disability-details-form__disability-switch-container
    .ripa-disability-details-form__disability-switch-text {
      margin: 15px;
      order: 1;

      @include size-1;
    }
    .ripa-disability-details-form__labels-container {
      min-width: 300px;
    }
    .ripa-disability-details-form__select-box-content {
      display: flex;
      flex-wrap: wrap;

      .ripa-disability-details-form__select-box {
        display: flex;
        width: 100%;
        margin: 20px 0 0 0;
      }
    }
  }
}

.dark-mode {
  .ripa-disability-details-form {
    .ripa-disability-details-form__select-box-content {
      .ripa-disability-details-form__select-box {
        &.active {
          background-color: $primary-color;
          color: $black;
        }
      }
    }
  }
}
