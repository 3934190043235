@import "theme/font";
@import "theme/text";
@import "theme/colorVars";

// This file is loaded into every required SASS module.
// Do not include anything in this file that will be actually rendered in CSS, because it will be added to every imported SASS file.
// Add page-level styling to /src/index.scss

// Theme Colors
$primary-color: $veritone-blue;
$secondary-color: $veritone-blue-faded;

// Text
$text-primary: $black;

// Fonts
$font-stack-primary: roboto, sans-serif; // Set globally

// Z-index levels
$dialog-z: 200;
$drawer-clickoff-z: 149;
$drawer-z: 150;
$nav-clickoff-z: 199;
$nav-z: 200;
$snack-z: 9999999999999;

// Shared layout dimensions
$header-height: 55px;
$drawer-width: 321px;
$drawer-width-minimal: 80px;
$min-content-box-width: 500px;
$default-combo-box-search-width: 300px;
$people-form-height: 280px;
$default-left-cell-width: 220px;

// Box Shadows
$shadow-level-0: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
$shadow-level-2: 0 3px 4px 0 rgba(0, 0, 0, 0.24);
$shadow-level-3: 0 3px 8px 0 rgba(0, 0, 0, 0.03);
$shadow-level-3-5: 0 3px 8px 0 rgba(0, 0, 0, 0.3);
$shadow-level-4: 0 4px 24px 0 rgba(0, 0, 0, 0.1);

// Transitions
$drawer-transition: 300ms;

// App vars
$person-tab-width: 147;
$snackHeight: 96;
$snackMargin: 8;

// Export to JS
:export {
  /* stylelint-disable */

  primaryColor: $primary-color;
  secondaryColor: $secondary-color;
  inputBorder: $input-border;
  grey4: $grey-4;
  blueDisabled: $blue-disabled;
  personTabWidth: $person-tab-width;
  snackHeight: $snackHeight;
  snackMargin: $snackMargin;
  headerHeight: $header-height;
  popperBackground: $popper-background;
  fontColor: $font-color;

  /* stylelint-enable */
}

/* Global overrides - be careful */

* {
  font-family: $font-stack-primary;

  :focus {
    outline: dotted 1px $veritone-blue-faded;
  }
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

/* Tag overrides */
body {
  margin: 0;
  overflow-y: hidden;
  background-color: $grey-b;
  color: $font-color;
}

html {
  --webkit-font-smoothing: antialiased;
  --moz-font-smoothing: grayscale;

  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

a {
  cursor: pointer;

  &:hover {
    color: $text-primary;
  }

  &:visited {
    color: $blue-disabled;
  }
}

h1 {
  /* Set separately for mui typography variants */
  font-family: $font-stack-primary;
  font-size: 32px;
  font-weight: 600;
  line-height: 32px;
}

h2 {
  /* Set separately for mui typography variants */
  font-family: $font-stack-primary;
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
}

h3 {
  /* Set separately for mui typography variants */
  font-family: $font-stack-primary;
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
}

h4 {
  /* Set separately for mui typography variants */
  font-family: $font-stack-primary;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
}

h5 {
  /* Set separately for mui typography variants */
  font-family: $font-stack-primary;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
}

/* Remove 'Powered by google' from autocomplete */
.pac-container::after {
  height: 0;
  margin: 0;
  padding: 0;
  background-image: none !important;
}

$code-arrow-size: 10px;
.MuiAutocomplete-option.code {
  position: relative;

  [data-type="M"]::after,
  [data-type="F"]::after,
  [data-type="X"]::after {
    content: "";
    position: absolute;
    left: 0;
    width: 0;
    height: 0;
    border-top: $code-arrow-size solid transparent;
    border-bottom: $code-arrow-size solid transparent;
  }
  [data-type="M"]::after {
    border-left: $code-arrow-size solid yellow;
  }
  [data-type="F"]::after {
    border-left: $code-arrow-size solid red;
  }
}

.dark-mode {
  .MuiSkeleton-root {
    background-color: rgba(238, 238, 238, 0.1);
    transform: scale(1);
  }
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: $primary-color;
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    color: $black;
  }
  .MuiPickersCalendarHeader-dayLabel {
    color: $black;
  }
  .MuiPickersBasePicker-container {
    .MuiTypography-colorInherit,
    > * {
      color: $black;
    }
    .MuiPickersCalendarHeader-iconButton {
      border: solid 1px $primary-color;
      background-color: transparent;
    }
    .MuiIconButton-root {
      &.Mui-disabled {
        border-color: transparent;

        .MuiSvgIcon-root {
          color: grey;
        }
      }
    }
    .MuiPickersCalendarHeader-daysHeader {
      color: $black;
    }
  }
  .MuiPickersModal-dialogRoot {
    .MuiButton-label {
      color: $black;
    }
    .MuiIconButton-root {
      margin: 0 5px;
    }
  }
  .MuiPickersClock-container {
    .MuiPickersClockNumber-clockNumber {
      color: $black;
    }
  }
  .MuiOutlinedInput-root {
    &.Mui-disabled {
      .MuiOutlinedInput-notchedOutline {
        border-color: inherit;
      }
    }
  }
  .MuiListItem-button:hover,
  .MuiAutocomplete-option:hover,
  .MuiAutocomplete-option:active,
  .MuiListItem-root:focus,
  .MuiAutocomplete-option[data-focus="true"] {
    background-color: rgba(0, 0, 0, 0.4);
  }
}

.embeddedMessagingFrame {
  bottom: 30px !important;
}
