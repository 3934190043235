@import "../../theme/font";
@import "../../theme/text";
@import "../../theme/colorVars";

// This file is loaded into every required SASS module.
// Do not include anything in this file that will be actually rendered in CSS, because it will be added to every imported SASS file.
// Add page-level styling to /src/index.scss

// Theme Colors
$primary-color: $veritone-blue;
$secondary-color: $veritone-blue-faded;

// Text
$text-primary: $black;

// Fonts
$font-stack-primary: roboto, sans-serif; // Set globally

// Z-index levels
$dialog-z: 200;
$drawer-clickoff-z: 149;
$drawer-z: 150;
$nav-clickoff-z: 199;
$nav-z: 200;
$snack-z: 9999999999999;

// Shared layout dimensions
$header-height: 55px;
$drawer-width: 321px;
$drawer-width-minimal: 80px;
$min-content-box-width: 500px;
$default-combo-box-search-width: 300px;
$people-form-height: 280px;
$default-left-cell-width: 220px;

// Box Shadows
$shadow-level-0: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
$shadow-level-2: 0 3px 4px 0 rgba(0, 0, 0, 0.24);
$shadow-level-3: 0 3px 8px 0 rgba(0, 0, 0, 0.03);
$shadow-level-3-5: 0 3px 8px 0 rgba(0, 0, 0, 0.3);
$shadow-level-4: 0 4px 24px 0 rgba(0, 0, 0, 0.1);

// Transitions
$drawer-transition: 300ms;

// App vars
$person-tab-width: 147;
$snackHeight: 96;
$snackMargin: 8;

// Export to JS
:export {
  /* stylelint-disable */

  primaryColor: $primary-color;
  secondaryColor: $secondary-color;
  inputBorder: $input-border;
  grey4: $grey-4;
  blueDisabled: $blue-disabled;
  personTabWidth: $person-tab-width;
  snackHeight: $snackHeight;
  snackMargin: $snackMargin;
  headerHeight: $header-height;
  popperBackground: $popper-background;
  fontColor: $font-color;

  /* stylelint-enable */
}

.visualization {
  position: relative;
  height: calc(100% - #{$header-height});
  padding: 38px 40px;
  overflow-y: auto;

  .visualization__header {
    width: 90%;
    margin-left: 80px;
  }

  .visualization__snackbar {
    position: absolute;
    right: 16px;
    bottom: 0;
  }

  .visualization__date-range-warning {
    text-align: center;
    margin: 60px 0 0;
    color: $blue-disabled;
  }

  .visualization__background {
    background-color: $white;
    padding: 20px;
    border-radius: 4px;
    box-shadow: $shadow-level-3;
    border: solid 2px $filter-border;
    position: relative;
    min-width: 775px;

    .csv-reports__table {
      padding-top: 12px;
      max-height: 660px;
      overflow-y: auto;
      overflow-x: hidden;
    }

    .csv-reports-line-break {
      width: 100%;
      margin: 40px auto;
      height: 0;
      border: solid 0.5px #b4b4b4;
    }
  }

  .visualization__launch-contact-analytics-button {
    align-self: flex-start;
    margin-left: auto;
    height: 35px;
    display: flex;
    align-items: center;

    .button__text {
      font-weight: 400;
    }
  }

  .visualization__options {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    .visualization__filters {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;

      &-label {
        color: $veritone-blue;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
      }

      &-date-range,
      &-status {
        border-bottom: solid 2px transparent;
        margin: 15px;

        &.active {
          border-bottom: solid 2px $primary-color;
        }
      }
    }
    .visualization__filter-space {
      width: 20px;
    }
    .visualization__export-report-group {
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 10px 3px 0;

      .visualization__split-button-arrow {
        width: 35px;

        .material-icons {
          line-height: 2;
        }
      }
    }

    .visualization__result-select {
      .Mui-disabled {
        color: $blue-disabled;
        border-color: $input-border;
      }
    }

    .visualization__export-report {
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      width: auto;

      .material-icons {
        margin-right: 8px;
      }

      &.active {
        border-bottom: solid 2px $primary-color;
      }

      .visualization__filters-export-reports-value {
        margin-right: 10px;
        font-size: 40px;
        font-weight: 300;
        color: $primary-color;
        user-select: none;
      }

      .visualization__export-report {
        display: inline-flex;
        width: auto;

        .material-icons {
          margin: 0 5px;
          font-weight: 900;
          font-size: 25px;
          line-height: 52px;
        }

        .button__text {
          font-weight: 400;
        }
      }

      .visualization__filters-export-reports-label {
        margin-top: 2px;
        width: 75px;
        line-height: 18px;
        color: $blue-disabled;
        user-select: none;

        @include size-1-bold;
      }
    }
  }

  .visualization__date-range-picker-form {
    width: 230px;

    .material-icons {
      color: $primary-color;
    }
  }

  &__result-select {
    width: 235px;
  }

  &__header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;

    &-title {
      color: $veritone-blue;
      font-size: 20px;
      font-weight: bold;
    }

    &-reports {
      display: inline-flex;
      font-size: 16px;

      &-value {
        color: $veritone-blue;
        margin-right: 5px;
      }

      &-label {
        color: $blue-disabled;
        text-transform: capitalize;
      }
    }
  }
}

.generate-report-data {
  font-size: 14px;

  &__report-indicator {
    color: $veritone-blue;
    font-weight: bold;
    margin-top: 30px;

    .export-csv__form-control {
      margin-top: 10px;

      .radio-group {
        display: flex;
        flex-direction: row;

        .form-control {
          flex: 1;

          .radio {
            .MuiSvgIcon-root:first-of-type {
              color: $expand-disabled;
            }

            &:hover {
              background-color: $radio-hover;
            }
          }

          .radio-label {
            color: black;
            font-size: 12px;

            &__checked {
              color: $veritone-blue;
              font-size: 12px;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}

// .material-icons {
//   color: $primary-color;
// }

.DateRangePickerForm {
  width: 230px;
}

.review__officer-select {
  width: 270px;
}

.review__reviewer-select {
  width: 270px;
}
