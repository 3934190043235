@import "../../theme/font";
@import "../../theme/text";
@import "../../theme/colorVars";

// This file is loaded into every required SASS module.
// Do not include anything in this file that will be actually rendered in CSS, because it will be added to every imported SASS file.
// Add page-level styling to /src/index.scss

// Theme Colors
$primary-color: $veritone-blue;
$secondary-color: $veritone-blue-faded;

// Text
$text-primary: $black;

// Fonts
$font-stack-primary: roboto, sans-serif; // Set globally

// Z-index levels
$dialog-z: 200;
$drawer-clickoff-z: 149;
$drawer-z: 150;
$nav-clickoff-z: 199;
$nav-z: 200;
$snack-z: 9999999999999;

// Shared layout dimensions
$header-height: 55px;
$drawer-width: 321px;
$drawer-width-minimal: 80px;
$min-content-box-width: 500px;
$default-combo-box-search-width: 300px;
$people-form-height: 280px;
$default-left-cell-width: 220px;

// Box Shadows
$shadow-level-0: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
$shadow-level-2: 0 3px 4px 0 rgba(0, 0, 0, 0.24);
$shadow-level-3: 0 3px 8px 0 rgba(0, 0, 0, 0.03);
$shadow-level-3-5: 0 3px 8px 0 rgba(0, 0, 0, 0.3);
$shadow-level-4: 0 4px 24px 0 rgba(0, 0, 0, 0.1);

// Transitions
$drawer-transition: 300ms;

// App vars
$person-tab-width: 147;
$snackHeight: 96;
$snackMargin: 8;

// Export to JS
:export {
  /* stylelint-disable */

  primaryColor: $primary-color;
  secondaryColor: $secondary-color;
  inputBorder: $input-border;
  grey4: $grey-4;
  blueDisabled: $blue-disabled;
  personTabWidth: $person-tab-width;
  snackHeight: $snackHeight;
  snackMargin: $snackMargin;
  headerHeight: $header-height;
  popperBackground: $popper-background;
  fontColor: $font-color;

  /* stylelint-enable */
}

.review {
  position: relative;
  height: calc(100% - #{$header-height});
  padding: 38px 40px;

  .review__snackbar {
    position: absolute;
    right: 16px;
    bottom: 0;
  }

  .review__filters {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 20px 0 20px;
    border-radius: 4px;
    background-color: $white;
    box-shadow: $shadow-level-3;
    border: solid 2px $filter-border;
    min-width: 895px;
    position: relative;

    .review__filters-under-review,
    .review__filters-review-rejected,
    .review__filters-review-approved,
    .review__filters-denied-reports,
    .review__filters-submitted-doj-reports {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 25px 15px 23px;
      border-bottom: solid 2px transparent;
      cursor: pointer;
      width: 25%;

      &.active {
        border-bottom: solid 2px $primary-color;
      }

      .review__filters-under-review-value,
      .review__filters-review-rejected-value,
      .review__filters-review-approved-value,
      .review__filters-denied-reports-value,
      .review__filters-submitted-doj-reports-value {
        margin-right: 10px;
        font-size: 40px;
        font-weight: 300;
        color: $primary-color;
        user-select: none;
      }

      .review__filters-under-review-label,
      .review__filters-review-rejected-label,
      .review__filters-review-approved-label,
      .review__filters-denied-reports-label,
      .review__filters-submitted-doj-reports-label {
        margin-top: 2px;
        width: 75px;
        line-height: 18px;
        color: $blue-disabled;
        user-select: none;

        @include size-1-bold;
      }
    }

    .review__filters-new-report {
      display: inline-flex;
      width: 232px;
      min-width: 175px;
      margin: 20px 0;
      position: absolute;
      right: 20px;

      .material-icons {
        margin: 0 5px 0 0;
        font-weight: 900;
        font-size: 25px;
        line-height: 52px;
      }

      .button__text {
        font-weight: 400;
      }
    }
  }

  .review__search-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .review__search-officer-select {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 57%;
    }

    .review__search {
      margin: 40px 30px 40px 20px;
      width: 60%;

      input {
        height: 19px;
      }

      .material-icons {
        margin-right: 10px;
        font-size: 20px;
        font-weight: 900;
        color: $blue-icon;
      }
    }

    .review__select {
      color: $veritone-blue;
      width: $default-combo-box-search-width;

      input {
        color: $veritone-blue;
      }
    }

    .review__officer-select {
      width: 260px;
    }

    .review__selected-actions {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 20px;

      .doj {
        display: flex;
        flex-direction: column;
        padding-right: 20px;

        @include size-1;

        &__next-submission {
          color: $blue-disabled;
          text-transform: capitalize;
          text-align: right;
          padding: 3px 0;
        }

        &__date-time {
          color: $black;
          text-align: right;
          padding: 3px 0;
        }
      }

      .review__submit-to-doj {
        &.selected {
          background: $white;
          border: 2px solid $veritone-blue;
          color: $veritone-blue;
        }
      }
    }
  }

  .review__table {
    min-width: 630px;
    height: calc(100% - 240px);
  }
}

.dashboard__doj-errors-dialog-heading {
  text-align: center;
  color: $primary-color;
  text-transform: uppercase;
  margin-bottom: 30px;

  @include size-2-bold;
}

.dashboard__doj-errors-dialog-content {
  @include size-2-bold;
}

.dashboard__doj-errors-dialog {
  .confirm-dialog__yes {
    padding: 0;
  }
}
