@import "../../../theme/font";
@import "../../../theme/text";
@import "../../../theme/colorVars";

// This file is loaded into every required SASS module.
// Do not include anything in this file that will be actually rendered in CSS, because it will be added to every imported SASS file.
// Add page-level styling to /src/index.scss

// Theme Colors
$primary-color: $veritone-blue;
$secondary-color: $veritone-blue-faded;

// Text
$text-primary: $black;

// Fonts
$font-stack-primary: roboto, sans-serif; // Set globally

// Z-index levels
$dialog-z: 200;
$drawer-clickoff-z: 149;
$drawer-z: 150;
$nav-clickoff-z: 199;
$nav-z: 200;
$snack-z: 9999999999999;

// Shared layout dimensions
$header-height: 55px;
$drawer-width: 321px;
$drawer-width-minimal: 80px;
$min-content-box-width: 500px;
$default-combo-box-search-width: 300px;
$people-form-height: 280px;
$default-left-cell-width: 220px;

// Box Shadows
$shadow-level-0: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
$shadow-level-2: 0 3px 4px 0 rgba(0, 0, 0, 0.24);
$shadow-level-3: 0 3px 8px 0 rgba(0, 0, 0, 0.03);
$shadow-level-3-5: 0 3px 8px 0 rgba(0, 0, 0, 0.3);
$shadow-level-4: 0 4px 24px 0 rgba(0, 0, 0, 0.1);

// Transitions
$drawer-transition: 300ms;

// App vars
$person-tab-width: 147;
$snackHeight: 96;
$snackMargin: 8;

// Export to JS
:export {
  /* stylelint-disable */

  primaryColor: $primary-color;
  secondaryColor: $secondary-color;
  inputBorder: $input-border;
  grey4: $grey-4;
  blueDisabled: $blue-disabled;
  personTabWidth: $person-tab-width;
  snackHeight: $snackHeight;
  snackMargin: $snackMargin;
  headerHeight: $header-height;
  popperBackground: $popper-background;
  fontColor: $font-color;

  /* stylelint-enable */
}

.reset-password {
  display: flex;
  justify-content: center;
  height: 100%;
  position: relative;
  background-color: $grey-b;

  .reset-password__bg {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    height: 442px;
    width: 100%;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
}
.reset-password__dialog {
  margin-top: 110px;
  z-index: 1;
  width: 448px;
  height: 691px;
  padding: 40px;
  border-radius: 8px;
  box-shadow: $shadow-level-4;
  background-color: $white;
}
.reset-password__title {
  margin: 0 auto;
  text-align: center;

  @include size-11-bold;
}
.reset-password__description {
  margin-top: 20px;
  text-align: left;

  @include size-1-5-thin;
}
.reset-password__new-password-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px 0 15px;

  .reset-password__new-password-label-text {
    color: $dark-grey;

    @include size-3-bold;
  }
  .reset-password__new-password-label-required {
    @include size-1-bold;

    font-style: italic;
    color: $blue-border;
  }
}
.reset-password__new-password-field {
  width: 100%;
}
.reset-password__retype-new-password-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px 0 15px;

  .reset-password__retype-new-password-label-text {
    color: $dark-grey;

    @include size-3-bold;
  }
  .reset-password__retype-new-password-label-required {
    @include size-1-bold;

    font-style: italic;
    color: $blue-border;
  }
}
.reset-password__retype-new-password-field {
  width: 100%;
}
.reset-password__submit {
  margin-top: 35px;
  width: 100%;
  background-color: $primary-color;
  line-height: 50px;
  text-align: center;
  color: $white;
  cursor: pointer;
  padding: 0;
  border: none;
  border-radius: 4px;

  &:active {
    background-color: $secondary-color;
  }

  @include size-3;
}
.reset-password-confirm-error {
  display: flex;
  flex-direction: row;
  margin: 7.5px 0 -16px;

  .material-icons {
    font-size: 13px;
    color: $number-red;
  }
  .reset-password-confirm-error-text {
    margin-left: 5px;
    width: 225px;
    font-size: 11px;
    color: $number-red;
  }
}
.no-error {
  display: none;
}

.reset-password__divider {
  width: 368px;
  height: 0;
  margin: 42px auto;
  border: solid 1px rgba(0, 61, 126, 0.3);
}

.reset-password__password-validation-text {
  @include size-2-bold;

  margin-bottom: 10px;
}
.reset-password__password-validation-checks {
  .reset-password__password-validation-check {
    width: 50%;
    min-width: 135px;
    display: inline-flex;
    align-items: center;
    &:nth-child(1),
    &:nth-child(2) {
      margin: 0 0 5px 0;
    }

    .reset-password__box {
      &.checked {
        .material-icons {
          color: $number-green;
        }
      }

      color: $number-red;

      .material-icons {
        color: $reviewer-role;
        font-size: 17px;
        line-height: 25px;
        margin-right: 8px;
        font-weight: 900;
      }
    }
    .reset-password__password-validation-check-label {
      font-size: 13px;
      font-weight: bold;
      color: $primary-color;
    }
  }
}

.reset-password__snackbar {
  position: absolute;
  right: 30px;
  bottom: 30px;
}

.reset-password__bs {
  position: absolute;
  z-index: 0;
  bottom: 0;
  width: 100%;
  height: 125px;

  .reset-password__bs-text {
    text-align: center;
    color: $text-terms;
    margin-bottom: 34px;

    @include size-1-bold;
  }
  .reset-password__bs-powered-by {
    text-align: center;
    color: $text-terms;
    margin-bottom: 8px;

    @include size-0-bold;
  }
  .reset-password__bs-vtn {
    text-align: center;
    margin-bottom: 45px;

    svg {
      width: 110px;
    }
  }
}

.xs {
  .reset-password {
    display: block;
    background-color: $grey-b;

    .reset-password__bg {
      display: none;
    }
    .reset-password__dialog {
      margin-top: 0;
      width: 100%;
      height: 100%;
      padding: 20px 40px 0;
    }
    .reset-password__snackbar {
      width: 100%;
      bottom: 0;
      right: 0;

      .snackbar__box {
        width: 100%;
      }
    }
    .reset-password__divider {
      width: 100%;
    }
    .reset-password__bs {
      height: auto;
    }
    .reset-password__bs-text {
      margin-bottom: 12px;

      @include size-0;
    }
    .reset-password__bs-powered-by {
      display: none;
    }
    .reset-password__bs-vtn {
      margin-bottom: 25px;
    }
  }
}
