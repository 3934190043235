@import "../../../theme/font";
@import "../../../theme/text";
@import "../../../theme/colorVars";

// This file is loaded into every required SASS module.
// Do not include anything in this file that will be actually rendered in CSS, because it will be added to every imported SASS file.
// Add page-level styling to /src/index.scss

// Theme Colors
$primary-color: $veritone-blue;
$secondary-color: $veritone-blue-faded;

// Text
$text-primary: $black;

// Fonts
$font-stack-primary: roboto, sans-serif; // Set globally

// Z-index levels
$dialog-z: 200;
$drawer-clickoff-z: 149;
$drawer-z: 150;
$nav-clickoff-z: 199;
$nav-z: 200;
$snack-z: 9999999999999;

// Shared layout dimensions
$header-height: 55px;
$drawer-width: 321px;
$drawer-width-minimal: 80px;
$min-content-box-width: 500px;
$default-combo-box-search-width: 300px;
$people-form-height: 280px;
$default-left-cell-width: 220px;

// Box Shadows
$shadow-level-0: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
$shadow-level-2: 0 3px 4px 0 rgba(0, 0, 0, 0.24);
$shadow-level-3: 0 3px 8px 0 rgba(0, 0, 0, 0.03);
$shadow-level-3-5: 0 3px 8px 0 rgba(0, 0, 0, 0.3);
$shadow-level-4: 0 4px 24px 0 rgba(0, 0, 0, 0.1);

// Transitions
$drawer-transition: 300ms;

// App vars
$person-tab-width: 147;
$snackHeight: 96;
$snackMargin: 8;

// Export to JS
:export {
  /* stylelint-disable */

  primaryColor: $primary-color;
  secondaryColor: $secondary-color;
  inputBorder: $input-border;
  grey4: $grey-4;
  blueDisabled: $blue-disabled;
  personTabWidth: $person-tab-width;
  snackHeight: $snackHeight;
  snackMargin: $snackMargin;
  headerHeight: $header-height;
  popperBackground: $popper-background;
  fontColor: $font-color;

  /* stylelint-enable */
}

.review-table-select-column {
  display: flex;
  justify-content: center;
  align-items: center;

  .review-table-select-column__cell-select-checkbox {
    display: flex;
    margin-left: 20px;
    border: solid 1px $veritone-blue;
    background-color: $veritone-blue-faded-2;
    cursor: pointer;
    user-select: none;

    .material-icons {
      width: 20px;
      line-height: 20px;
      font-size: 18px;
      font-weight: 900;
      text-align: center;
      color: transparent;
    }

    &.checked {
      .material-icons {
        color: $primary-color;
      }
    }

    &.disabled {
      background: #f8f8f8;
      border: 1px solid #bbc8d4;
      pointer-events: none;
    }
  }
  .review-table-select-column__cell-select-expand {
    margin-left: 20px;
    fill: $primary-color;
    cursor: pointer;
    user-select: none;

    &.expanded {
      fill: $expand-all-enabled;
    }
  }
}
