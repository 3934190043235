@import "../../theme/font";
@import "../../theme/text";
@import "../../theme/colorVars";

// This file is loaded into every required SASS module.
// Do not include anything in this file that will be actually rendered in CSS, because it will be added to every imported SASS file.
// Add page-level styling to /src/index.scss

// Theme Colors
$primary-color: $veritone-blue;
$secondary-color: $veritone-blue-faded;

// Text
$text-primary: $black;

// Fonts
$font-stack-primary: roboto, sans-serif; // Set globally

// Z-index levels
$dialog-z: 200;
$drawer-clickoff-z: 149;
$drawer-z: 150;
$nav-clickoff-z: 199;
$nav-z: 200;
$snack-z: 9999999999999;

// Shared layout dimensions
$header-height: 55px;
$drawer-width: 321px;
$drawer-width-minimal: 80px;
$min-content-box-width: 500px;
$default-combo-box-search-width: 300px;
$people-form-height: 280px;
$default-left-cell-width: 220px;

// Box Shadows
$shadow-level-0: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
$shadow-level-2: 0 3px 4px 0 rgba(0, 0, 0, 0.24);
$shadow-level-3: 0 3px 8px 0 rgba(0, 0, 0, 0.03);
$shadow-level-3-5: 0 3px 8px 0 rgba(0, 0, 0, 0.3);
$shadow-level-4: 0 4px 24px 0 rgba(0, 0, 0, 0.1);

// Transitions
$drawer-transition: 300ms;

// App vars
$person-tab-width: 147;
$snackHeight: 96;
$snackMargin: 8;

// Export to JS
:export {
  /* stylelint-disable */

  primaryColor: $primary-color;
  secondaryColor: $secondary-color;
  inputBorder: $input-border;
  grey4: $grey-4;
  blueDisabled: $blue-disabled;
  personTabWidth: $person-tab-width;
  snackHeight: $snackHeight;
  snackMargin: $snackMargin;
  headerHeight: $header-height;
  popperBackground: $popper-background;
  fontColor: $font-color;

  /* stylelint-enable */
}

.login {
  display: flex;
  justify-content: center;
  height: 100%;
  position: relative;
  background-color: $grey-b;

  .login__header {
    display: none;
  }

  .login__bg {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    height: 442px;
    width: 100%;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  .login__dialog {
    margin-top: 110px;
    z-index: 1;
    width: 448px;
    height: 551px;
    padding: 40px;
    border-radius: 8px;
    box-shadow: $shadow-level-4;
    background-color: $white;

    .login__title_logo_container {
      display: flex;
      justify-content: space-between;
    }
  }
  .login__line {
    width: 0;
    height: 30px;
    margin: 30px 0 50px;
    border: solid 1px $blue-border;
  }
  .login__logo {
    height: 75px;
    width: 150px;
    // position: absolute;

    img {
      object-fit: contain;
      height: 100%;
      width: 100%;
      box-shadow: none;
    }
  }
  .login__title {
    margin-top: 26px;
    text-align: left;
    color: $primary-color;
    padding-bottom: 30px;

    @include size-10-bold;
  }
  .login__login-error {
    display: none;
    color: $error;
    text-align: center;

    @include size-0;

    &.has-error {
      display: block;
    }
  }
  .login__username-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;

    .login__username-label-text {
      @include size-3-bold;
    }
    .login__username-label-required {
      @include size-1-bold;

      font-style: italic;
      // font-weight: 500;
      color: $blue-border;
    }
  }
  .login__username-field {
    margin-top: 15px;
    width: 100%;

    .MuiInputBase-input.Mui-disabled {
      opacity: 0.2;
    }
    input {
      font-size: 1rem;
    }
  }
  .login__pw-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;

    .login__pw-label-text {
      @include size-3-bold;
    }
    .login__pw-label-required {
      @include size-1-bold;

      font-style: italic;
      color: $blue-border;
    }
  }
  .login__pw-field {
    margin-top: 15px;
    width: 100%;

    .MuiInputBase-input.Mui-disabled {
      opacity: 0.2;
    }
    input {
      font-size: 1rem;
    }
  }
  .login__forgot-pw {
    margin: 20px auto 0;
    width: 160px;
    text-align: left;
    color: $primary-color;
    cursor: pointer;

    @include size-2;

    font-style: italic;
  }
  .login__need-login {
    margin: 20px auto 0;
    width: 260px;
    text-align: left;
    color: $number-red;

    @include size-2;

    font-style: italic;
  }
  .login__submit {
    margin-top: 30px;
    width: 100%;
    background-color: $primary-color;
    line-height: 55px;
    text-align: center;
    color: $white;
    cursor: pointer;
    padding: 0;
    border: none;
    border-radius: 4px;

    &:active {
      background-color: $secondary-color;
    }

    &.disabled {
      background: $blue-disabled;
      pointer-events: none;
    }

    @include size-3;
  }
  .login__snackbar {
    position: absolute;
    right: 30px;
    bottom: 30px;
  }
  .login__pass-reset-dialog {
    margin-top: 110px;
    z-index: 1;
    width: 448px;
    height: 460px;
    padding: 40px;
    border-radius: 8px;
    box-shadow: $shadow-level-4;
    background-color: $white;
  }
  .login__pass-reset-back-to-login {
    display: inline-flex;
    text-align: left;
    cursor: pointer;
    color: $primary-color;

    .material-icons {
      font-weight: bold;
      font-size: 28px;
    }
    .login__pass-reset-back-to-login-text {
      line-height: 30px;
      font-size: 16px;
      font-weight: bold;
    }
  }
  .login__pass-reset-title {
    margin: 40px auto 0 auto;
    text-align: center;

    @include size-11-bold;
  }
  .login__pass-reset-description {
    margin-top: 20px;
    font-size: 14px;
    text-align: left;
  }
  .login__pass-reset-username-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;

    .login__pass-reset-username-label-text {
      color: #3e3e3e;

      @include size-3-bold;
    }
    .login__pass-reset-username-label-required {
      @include size-1-bold;

      font-style: italic;
      // font-weight: 500;
      color: $blue-border;
    }
  }
  .login__pass-reset-username-field {
    margin-top: 15px;
    width: 100%;

    input {
      font-size: 1rem;
    }
  }
  .login__pass-reset-submit {
    margin-top: 40px;
    width: 100%;

    @include size-3-bold;

    font-weight: 500;
  }
  .login__more-instructions-dialog {
    margin-top: 110px;
    z-index: 1;
    width: 448px;
    height: 350px;
    padding: 40px;
    border-radius: 8px;
    box-shadow: $shadow-level-4;
    background: $white;
  }
  .login__more-instructions-back-to-login {
    display: inline-flex;
    text-align: left;
    cursor: pointer;

    .material-icons {
      font-weight: bold;
      font-size: 28px;
    }
    .login__more-instructions-back-to-login-text {
      line-height: 30px;

      @include size-3-bold;
    }
  }
  .login__more-instructions-title {
    margin: 40px auto 0 auto;
    text-align: center;

    @include size-11-bold;
  }
  .login__more-instructions-description {
    margin-top: 20px;
    font-size: 14px;
    text-align: left;
  }
  .login__more-instructions-resubmit {
    margin-top: 40px;
    width: 100%;

    @include size-3-bold;

    font-weight: 500;
  }

  .login__bs {
    position: absolute;
    z-index: 0;
    bottom: 0;
    width: 100%;
    height: 125px;

    .login__bs-text {
      text-align: center;
      color: $text-terms;
      margin-bottom: 34px;

      @include size-1-bold;
    }
    .login__bs-powered-by {
      text-align: center;
      color: $text-terms;
      margin-bottom: 8px;

      @include size-0-bold;
    }
    .login__bs-vtn {
      text-align: center;
      margin-bottom: 45px;

      svg {
        width: 110px;
      }
    }
  }
}

.xs {
  .login {
    display: block;
    background-color: $grey-b;
    min-height: 670px;

    .login__header {
      display: block;
      position: relative;
      height: 50px;
      width: 100%;
      background-color: $primary-color;

      .login__header-title {
        text-align: center;
        line-height: 50px;
        color: $white;

        @include size-7-bold;
      }
      .login__header-logo {
        position: absolute;
        padding: 0 19px;
        right: 0;
        top: 0;
        line-height: 50px;
      }
    }

    .login__bg {
      display: none;
    }

    .login__dialog {
      margin-top: 0;
      width: 100%;
      height: 100%;
      padding: 20px 40px 0;

      .login__title_logo_container {
        .login__line {
          display: none;
        }
        .login__logo {
          width: 190px;
        }
      }

      .login__logo {
        display: block;
        position: initial;
        margin: 20px auto 50px;
      }
      .login__title {
        display: none;
      }
    }

    .login__pass-reset-dialog {
      margin-top: 0;
      width: 100%;
      height: 100%;
      padding: 20px 40px 0;
    }

    .login__more-instructions-dialog {
      margin-top: 0;
      width: 100%;
      height: 100%;
      padding: 20px 40px 0;
    }

    .login__bs {
      height: auto;
    }
    .login__bs-text {
      margin-bottom: 12px;

      @include size-0;
    }
    .login__bs-powered-by {
      display: none;
    }
    .login__bs-vtn {
      margin-bottom: 25px;
    }
  }
}

.dark-mode {
  .login {
    background-color: #fff;

    .login__header {
      .login__header-title {
        color: #fff;
      }
    }
    .login__dialog {
      background-color: #fff;
      color: $white;
    }
    .login__title {
      color: #003d7e;
    }
    .login__pw-label-required,
    .login__username-label-required {
      color: #b2c4d8;
    }
    .login__submit {
      background-color: #003d7e;
      color: #fff;
    }
    .login__pass-reset-title {
      color: #000;
    }
    .login__pass-reset-description {
      color: #000;
    }
    input {
      color: #000;
    }
  }
  .login__pass-reset-dialog {
    background-color: #fff;
  }
  .login__more-instructions-dialog {
    background-color: #fff;
  }
  .login__more-instructions-description,
  .login__more-instructions-title {
    color: #000;
  }
  .login__more-instructions-resubmit {
    color: #fff;
    background-color: #003d7e;
  }
}
